import type { LDFlagSet } from 'launchdarkly-js-sdk-common';

import {
  FF_BOOK_ANY_DOCTOR_IF_CURRENT_DOCTOR_INACTIVE,
  FF_C4C_RECORD_VOICE_CALL,
  FF_C4C_VIDEO_CONSULT,
  FF_C4C_VOICE_CALL,
  FF_CALCOM_DOCTOR_SCHEDULE_API,
  FF_CALCOM_EMBED_PARAMS,
  FF_CONCESSION_DISCOUNT_RATE,
  FF_CONSULTATION_BOOKING_PAYMENT_WINDOW,
  FF_CONSULTATION_COMPLETION_PROMPT,
  FF_CONSULTATION_FOLLOW_UP_PRICE,
  FF_CONSULTATION_PRICE_JSON,
  FF_ENABLE_CANNABIS_NAIVE_FIELD,
  FF_ENABLE_DISCOUNT_CODES,
  FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE,
  FF_ENABLE_PMS_ERROR_HANDLING,
  FF_ENABLE_PMS_PATIENT_DETAILS_V3,
  FF_ENABLE_SNOWPLOW,
  FF_HIDE_RESIDENCE_FIELD_IN_ADDRESS_FORM,
  FF_IHI_UNVERIFIED_IHI_MODAL_QUESTIONS,
  FF_IMMUTABLE_CLINIC_NOTES,
  FF_INVENTORY_INSIGHTS_ENFORCE_GREENA_REPEATS,
  FF_NURSE_INSTANT_CONSULTATION,
  FF_PAT_ENABLE_DEVICE_PRESCRIPTION,
  FF_PAT_PRESCRIBING_SUITABLE_DEVICE_MODAL,
  FF_PAT_PRESCRIPTION_DOSAGE_MIN_LENGTH_VALIDATION,
  FF_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION,
  FF_PRESCRIPTION_ASSISTANT_PHASE_2,
  FF_PRESCRIPTION_ASSISTANT_TOOL_USE_ZUSTAND,
  FF_PRESCRIPTION_ASSISTANT_VIEW_SCRIPTS,
  FF_PRESCRIPTION_RECOMMENDED_INFO,
  FF_PRESCRIPTION_RECOMMENDED_PRODUCTS_COUNT,
  FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION,
  FF_VIEW_CONSULTATION_NOTES_HISTORY,
  FF_WA_NOTIFICATION_OF_TREATMENT
} from '@/constants/featureFlags';

const ffDefaults: LDFlagSet = {
  [FF_CONSULTATION_PRICE_JSON]: { displayPrice: '59', chargePrice: '59' },
  [FF_CONSULTATION_FOLLOW_UP_PRICE]: '59',
  [FF_CONCESSION_DISCOUNT_RATE]: 0,
  [FF_ENABLE_DISCOUNT_CODES]: false,
  [FF_ENABLE_SNOWPLOW]: false,
  [FF_PRESCRIPTION_RECOMMENDED_PRODUCTS_COUNT]: 3,
  [FF_PRESCRIPTION_RECOMMENDED_INFO]:
    '{"href": "https://app.getguru.com/card/iByRzd4T/Prescription-assistant-guide","text": "Product recommendations are based on your selections and availability criteria"}',
  [FF_PRESCRIPTION_ASSISTANT_PHASE_2]: false,
  [FF_PRESCRIPTION_ASSISTANT_TOOL_USE_ZUSTAND]: false,
  [FF_PRESCRIPTION_ASSISTANT_VIEW_SCRIPTS]: false,
  [FF_CONSULTATION_BOOKING_PAYMENT_WINDOW]: 600,
  [FF_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION]: false,
  [FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE]: false,
  [FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION]: false,
  [FF_IHI_UNVERIFIED_IHI_MODAL_QUESTIONS]: false,
  [FF_INVENTORY_INSIGHTS_ENFORCE_GREENA_REPEATS]: false,
  [FF_WA_NOTIFICATION_OF_TREATMENT]: false,
  [FF_IMMUTABLE_CLINIC_NOTES]: false,
  [FF_ENABLE_PMS_PATIENT_DETAILS_V3]: false,
  [FF_PAT_PRESCRIBING_SUITABLE_DEVICE_MODAL]: false,
  [FF_VIEW_CONSULTATION_NOTES_HISTORY]: false,
  [FF_CONSULTATION_COMPLETION_PROMPT]: false,
  [FF_ENABLE_PMS_ERROR_HANDLING]: false,
  [FF_BOOK_ANY_DOCTOR_IF_CURRENT_DOCTOR_INACTIVE]: false,
  [FF_PAT_PRESCRIPTION_DOSAGE_MIN_LENGTH_VALIDATION]: false,
  [FF_HIDE_RESIDENCE_FIELD_IN_ADDRESS_FORM]: false,
  [FF_C4C_VOICE_CALL]: false,
  [FF_C4C_RECORD_VOICE_CALL]: false,
  [FF_C4C_VIDEO_CONSULT]: false,
  [FF_CALCOM_DOCTOR_SCHEDULE_API]: false,
  [FF_PAT_ENABLE_DEVICE_PRESCRIPTION]: false,
  [FF_ENABLE_CANNABIS_NAIVE_FIELD]: false,
  [FF_NURSE_INSTANT_CONSULTATION]: false,
  [FF_CALCOM_EMBED_PARAMS]: false
};

export default ffDefaults;
