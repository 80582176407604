import { Box, Typography } from '@mui/material';

import { useGetPrescriptionsWithDiscontinuedMedications } from '@/hooks/rest/useGetPrescriptionsWithDiscontinuedMedications';
import type { CatalogProduct } from '@/hooks/rest/useGetProductList';
import { usePutPrescriptBatchReplace } from '@/hooks/rest/usePutPrescriptBatchReplace';
import type { Prescription } from '@/types';
import type { RowSelectionState } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useHandleRescriptingPermissions } from '../../hooks/useHandleRescriptingPermissions';
import { useRescriptAlternatives } from '../../hooks/useRescriptAlternatives';
import { RescriptingTable } from '../shared/RescriptingTable';
import {
  buildDiscontinuedMedicationTableData,
  buildDiscontinuedMedicationTableHeaders,
  buildDiscontinuedRescriptPutBody
} from './DiscontinuedPanel.utils';
import { DiscontinuedContent } from './components/DiscontinuedContent';

const DiscontinuedPanel = () => {
  const [modifiedScripts, setModifiedScripts] = useState<Map<string, CatalogProduct>>(new Map());
  const [selectedUniqueRowKeys, setSelectedUniqueRowKeys] = useState<string[]>([]);
  const [internalTableRowSelectedState, setInternalTableRowSelectedState] = useState<RowSelectionState>({});

  const {
    data: discontinuedMedicationsResponse,
    loading: prescriptionRequestLoading,
    error: prescriptionRequestError,
    doGet: doGetPrescriptionsWithDiscontinedMedications
  } = useGetPrescriptionsWithDiscontinuedMedications({
    limit: 10000,
    status: 'ACTIVE'
  });

  const {
    data: alternativeProductList,
    loading: productRequestLoading,
    error: productRequestError
  } = useRescriptAlternatives();

  const {
    isLoading: putPrescriptionLoading,
    isError: putPrescriptionError,
    putPrescriptionBatchReplace
  } = usePutPrescriptBatchReplace();

  useHandleRescriptingPermissions({
    isGetPrescriptionError: Boolean(prescriptionRequestError),
    isPutPrescriptionError: Boolean(putPrescriptionError),
    isGetProductsError: Boolean(productRequestError)
  });

  const resetTableState = async () => {
    setModifiedScripts(new Map());
    setSelectedUniqueRowKeys([]);
    setInternalTableRowSelectedState({});
    await doGetPrescriptionsWithDiscontinedMedications();
  };

  const handleApproval = async (
    selectedUniqueRowKeys: string[],
    prescriptionsWithDiscontinuedMedications: Prescription[],
    modifiedScripts: Map<string, CatalogProduct>
  ) => {
    const resultArray = buildDiscontinuedRescriptPutBody(
      selectedUniqueRowKeys,
      prescriptionsWithDiscontinuedMedications,
      modifiedScripts
    );

    await putPrescriptionBatchReplace({ rescriptType: 'GENERATIVE', prescriptions: resultArray });
    await resetTableState();
    return Promise.resolve();
  };

  const loading = prescriptionRequestLoading || productRequestLoading || putPrescriptionLoading;

  const tableColumnHeaders = useMemo(
    () => buildDiscontinuedMedicationTableHeaders(alternativeProductList, setModifiedScripts),
    [alternativeProductList]
  );

  const tableData = useMemo(
    () => buildDiscontinuedMedicationTableData(discontinuedMedicationsResponse, modifiedScripts),
    [discontinuedMedicationsResponse, modifiedScripts]
  );

  return (
    <Box paddingX={2} paddingY={0}>
      <DiscontinuedContent />
      <Box>
        <Typography variant="h3" fontSize="24px" mb="1" fontWeight={600}>
          Discontinued scripts
        </Typography>
        <RescriptingTable
          tableColumnHeaders={tableColumnHeaders}
          tableData={tableData}
          selectedUniqueRowKeys={selectedUniqueRowKeys}
          setSelectedUniqueRowKeys={setSelectedUniqueRowKeys}
          internalTableRowSelectedState={internalTableRowSelectedState}
          setInternalTableRowSelectedState={setInternalTableRowSelectedState}
          handleApproval={async (uniqueRowKeys) =>
            await handleApproval(uniqueRowKeys, discontinuedMedicationsResponse?.prescriptions ?? [], modifiedScripts)
          }
          isLoading={loading}
          rescriptingReason="discontinued"
        />
      </Box>
    </Box>
  );
};

export { DiscontinuedPanel };
