import { FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { useAppStore } from '@/state-management';
import type { AlertProps } from '@mui/material';
import { Alert } from '@mui/material';
import { useRef } from 'react';
import type { MedicationPadItemCancelData, MedicationPadItemType } from '../../PrescriptionPad';
import { PRODUCT_ALERT_MESSAGE_TEMPLATE_REISSUE } from '../../ProductDetailsCard/ProductDetailsCard.constants';
import { PrescriptionItemAlertType } from '../PrescriptionItemEditor.types';
import { useAlertType, useCancelAlert } from '../hooks';

type AlertMessage = {
  severity: AlertProps['severity'];
  ariaLabel: string;
  description: React.ReactNode;
};

export type PrescriptionItemAlertProps = {
  medicationItemId: string;
  productId: number;
};

export const PrescriptionItemAlert = ({ medicationItemId, productId }: PrescriptionItemAlertProps) => {
  const { flags } = useFeatureFlags();
  const ffV4PrescriptionAssistantCancelPrescribedMedication =
    flags[FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION];

  const {
    prescriptionPad: { medicationPadItems },
    prescriptionHistory: { itemsToReissue, itemsToCancel }
  } = useAppStore.use.prescriptionAssistant();

  const reissuingMedication = itemsToReissue.find((item) => item.medicationPadItemId === medicationItemId);
  const currentMedicationPadItem = medicationPadItems.find(
    (existingItem) => existingItem.medicationPadItemId === medicationItemId
  ) ?? {
    medicationPadItemId: medicationItemId
  };

  const { cancelAlertData } = useCancelAlert({
    initialMedication: reissuingMedication,
    currentMedicationPadItem: currentMedicationPadItem?.medicationPadItem,
    itemsToCancel
  });

  const activeAlertType = useAlertType({
    reissueStatus: currentMedicationPadItem?.reissueStatus,
    errorMessage: currentMedicationPadItem?.errorMessage ?? null,
    hasActiveCancelAlert: !!cancelAlertData
  });

  const initialMedicationRef = useRef(reissuingMedication);

  const isOutOfService =
    initialMedicationRef?.current?.reissueStatus?.isOutOfStock ||
    initialMedicationRef?.current?.reissueStatus?.isDiscontinued ||
    !initialMedicationRef?.current?.reissueStatus?.isActive;

  const UNAVAILABLE_MESSAGE =
    'Based on your order preferences, we recommend the following alternative product that closely matches your needs.';

  const getAlertMessage = (
    alertType: PrescriptionItemAlertType | null,
    errorMessage: { title: string; description: string } | null,
    cancelAlertData: MedicationPadItemCancelData | null,
    currentMedicationPadItem: MedicationPadItemType | null
  ): AlertMessage | null => {
    if (!alertType) {
      return null;
    }

    if (!ffV4PrescriptionAssistantCancelPrescribedMedication) {
      if (alertType === PrescriptionItemAlertType.CANCEL) {
        return null;
      }

      if (alertType === PrescriptionItemAlertType.OUT_OF_STOCK) {
        if (productId !== currentMedicationPadItem?.medicationPadItem?.productId) {
          return null;
        }
      }
    }

    const alertConfig: Record<PrescriptionItemAlertType, AlertMessage> = {
      [PrescriptionItemAlertType.ERROR]: {
        severity: 'error',
        ariaLabel: 'Error message',
        description: errorMessage && (
          <>
            <strong>{errorMessage.title} </strong>
            {errorMessage.description}
          </>
        )
      },
      [PrescriptionItemAlertType.CANCEL]: {
        severity: isOutOfService ? 'info' : 'warning',
        ariaLabel: `${isOutOfService ? 'Out of Service' : 'Reissued'} Medication cancelled`,
        description:
          cancelAlertData &&
          (isOutOfService ? (
            <>
              <strong>{currentMedicationPadItem?.medicationPadItem?.productName ?? 'A new product'}</strong> will be
              issued as an alternative to {cancelAlertData.medicationName}
            </>
          ) : (
            <>
              <strong>
                {[cancelAlertData.supplierName, cancelAlertData.medicationName].filter(Boolean).join(' | ')}
              </strong>{' '}
              will be cancelled and updated to the medication selected.
            </>
          ))
      },
      [PrescriptionItemAlertType.DISCONTINUED]: {
        severity: !ffV4PrescriptionAssistantCancelPrescribedMedication ? 'warning' : 'error',
        ariaLabel: 'Discontinued medication',
        description: !ffV4PrescriptionAssistantCancelPrescribedMedication ? (
          <>
            We apologise, but{' '}
            <strong>{initialMedicationRef?.current?.medicationPadItem?.productName ?? 'the product'}</strong> has been
            discontinued. {UNAVAILABLE_MESSAGE}
          </>
        ) : (
          <>
            This product has been <strong>discontinued</strong> and will be cancelled. Please select an alternative to
            proceed.
          </>
        )
      },
      [PrescriptionItemAlertType.OUT_OF_STOCK]: {
        severity: !ffV4PrescriptionAssistantCancelPrescribedMedication ? 'warning' : 'error',
        ariaLabel: 'Out of stock medication',
        description: !ffV4PrescriptionAssistantCancelPrescribedMedication ? (
          <>
            We apologise, but{' '}
            <strong>{initialMedicationRef?.current?.medicationPadItem?.productName ?? 'the product'}</strong> is
            currently out of stock. {UNAVAILABLE_MESSAGE}
          </>
        ) : (
          <>
            This product is currently <strong>out of stock</strong>. Please select an alternative to proceed.
          </>
        )
      },
      [PrescriptionItemAlertType.INACTIVE]: {
        severity: !ffV4PrescriptionAssistantCancelPrescribedMedication ? 'warning' : 'error',
        ariaLabel: 'Inactive medication',
        description: !ffV4PrescriptionAssistantCancelPrescribedMedication ? (
          <>
            We apologise, but{' '}
            <strong>{initialMedicationRef?.current?.medicationPadItem?.productName ?? 'the product'}</strong> is
            unavailable for ordering. {UNAVAILABLE_MESSAGE}
          </>
        ) : (
          <>
            This product is currently <strong>unavailable for ordering</strong>. Please select an alternative to
            proceed.
          </>
        )
      },
      [PrescriptionItemAlertType.REISSUE]: {
        severity: 'info',
        ariaLabel: 'Re-issued medication',
        description: (
          <>
            <strong>
              {currentMedicationPadItem?.medicationPadItem?.supplierName &&
              currentMedicationPadItem?.medicationPadItem?.productName
                ? `${currentMedicationPadItem?.medicationPadItem?.supplierName} | ${currentMedicationPadItem?.medicationPadItem?.productName}`
                : currentMedicationPadItem?.medicationPadItem?.productName || 'The product'}
            </strong>{' '}
            {PRODUCT_ALERT_MESSAGE_TEMPLATE_REISSUE}.
          </>
        )
      }
    };

    return alertConfig[alertType];
  };

  const alertMessage = getAlertMessage(
    activeAlertType,
    currentMedicationPadItem?.errorMessage ?? null,
    cancelAlertData,
    currentMedicationPadItem ?? null
  );

  if (!alertMessage) {
    return null;
  }

  return (
    <Alert aria-label={alertMessage.ariaLabel} severity={alertMessage.severity}>
      {alertMessage.description}
    </Alert>
  );
};
