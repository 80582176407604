import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React, { useState } from 'react';

import { DiscontinuedPanel } from '@/components/Rescripting/components/DiscontinuedPanel/DiscontinuedPanel';
import { OutOfStockPanel } from '@/components/Rescripting/components/OutOfStockPanel/OutOfStockPanel';
import { PageHeader } from '@/shared-ui/PageElements/PageHeader';
import { PageMainContent } from '@/shared-ui/PageElements/PageMainContent';
import { PageTitle } from '@/shared-ui/PageElements/PageTitle';

export default function DoctorScriptReview() {
  const [value, setValue] = useState('1');

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <PageHeader>
        <PageTitle variant="h1" color="textPrimary">
          Doctors Review Dashboard
        </PageTitle>
      </PageHeader>
      <PageMainContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="Script Types">
                <Tab label="DISCONTINUED" value="1" />
                <Tab label="OOS" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ paddingX: 0 }}>
              <DiscontinuedPanel />
            </TabPanel>
            <TabPanel value="2" sx={{ paddingX: 0 }}>
              <OutOfStockPanel />
            </TabPanel>
          </TabContext>
        </Box>
      </PageMainContent>
    </>
  );
}
