import { AmazonConnectCcpProvider } from '@montugroup/clinic-telecom';
import type { FC } from 'react';
import { FF_C4C_RECORD_VOICE_CALL, FF_C4C_VOICE_CALL } from '../../constants/featureFlags';
import settings from '../../data/constants';
import { useFeatureFlags } from '../../hooks/flags/useFeatureFlags';

export const withAmazonConnectCcp = <P extends React.JSX.IntrinsicAttributes>(Component: FC<P>) => {
  const WithAmazonConnectCcp = (props: P) => {
    const { flags } = useFeatureFlags();
    const isVoiceCallEnabled = flags[FF_C4C_VOICE_CALL];
    const isRecordingVoiceCallsEnabled = flags[FF_C4C_RECORD_VOICE_CALL];

    return isVoiceCallEnabled ? (
      <AmazonConnectCcpProvider
        ccpUrl={settings.connect.ccpInstanceUrl}
        loginUrl={settings.connect.samlLoginUrl}
        supportUrl={settings.serviceDeskUrl}
        canRecordCalls={isRecordingVoiceCallsEnabled}
      >
        <Component {...props} />
      </AmazonConnectCcpProvider>
    ) : (
      <Component {...props} />
    );
  };

  return WithAmazonConnectCcp;
};
