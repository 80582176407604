import type { RescriptProduct } from '@/api/prescriptions/putPrescriptionsBatchReplace';
import type { CatalogProduct } from '@/hooks/rest/useGetProductList';
import type { AlternativeProduct } from '@/types/prescriptions.types';

const createNewRescriptProduct = (
  product: CatalogProduct | AlternativeProduct,
  originalRescriptProduct: RescriptProduct,
  isOutOfStockScript = false
) => {
  const defaultIsOutOfStockRepeats = 0;
  const defaultIsOutOfStockQuantity = 2;

  return {
    productId: product.id,
    repeats: isOutOfStockScript ? defaultIsOutOfStockRepeats : originalRescriptProduct?.repeats,
    quantity: isOutOfStockScript ? defaultIsOutOfStockQuantity : originalRescriptProduct?.quantity,
    interval: 'interval' in product ? product.interval : originalRescriptProduct.interval,
    dosage: 'dosage' in product ? product.dosage || '' : originalRescriptProduct.dosage,
    name: product.name,
    isConcession: 'is_concession' in product ? product.is_concession : false,
    otherProduct: '',
    originalProductId: originalRescriptProduct.productId,
    ...(originalRescriptProduct.isPatientRescriptReq && {
      isPatientRescriptReq: originalRescriptProduct.isPatientRescriptReq
    })
  };
};

const makeNewPrescriptionProduct = (
  originalRescriptProduct: RescriptProduct,
  modifiedScriptProduct: CatalogProduct | undefined,
  defaultAlternative: AlternativeProduct | undefined,
  isOutOfStockScript = false
): RescriptProduct => {
  if (modifiedScriptProduct) {
    return createNewRescriptProduct(modifiedScriptProduct, originalRescriptProduct, isOutOfStockScript);
  }
  // Doctor approved default alternative suggestion
  if (!defaultAlternative) {
    throw new Error('Approved script has no default alternative');
  }

  return createNewRescriptProduct(defaultAlternative, originalRescriptProduct, isOutOfStockScript);
};

export { makeNewPrescriptionProduct };
