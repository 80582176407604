import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Card, CardContent, styled } from '@mui/material';
import type { FieldValues } from 'react-hook-form-mui';
import { FormContainer, RadioButtonGroup, useForm } from 'react-hook-form-mui';
import type { FormFields } from './MedicalInformation.schema';
import { schema } from './MedicalInformation.schema';

const flexOptions = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
};

const StyledRadioBox = styled(Box)({
  '& .MuiFormControl-root': {
    ...flexOptions,
    alignItems: 'center'
  }
});

interface MedicalInfoDefaultValues {
  cannabisNaive: boolean;
}
export type MedicalInformationProps = {
  defaultValues?: MedicalInfoDefaultValues;
  onSubmit: (data: FormFields) => void;
};

const MedicalInformation = (props: MedicalInformationProps) => {
  const { defaultValues, onSubmit } = props;
  const formContext = useForm<FormFields>({
    defaultValues: {
      cannabisNaive: defaultValues?.cannabisNaive ? 'Yes' : 'No'
    },
    resolver: zodResolver(schema)
  });

  const handleOnSubmit = (formData: FieldValues) => {
    if (onSubmit) {
      onSubmit(formData.cannabisNaive);
    }
  };
  return (
    <Card>
      <CardContent>
        <FormContainer formContext={formContext} handleSubmit={handleOnSubmit}>
          <StyledRadioBox>
            <RadioButtonGroup
              row
              name="cannabisNaive"
              label="Cannabis Naive"
              options={[
                { id: 'Yes', label: 'Yes' },
                { id: 'No', label: 'No' }
              ]}
            />
          </StyledRadioBox>
        </FormContainer>
      </CardContent>
    </Card>
  );
};
export default MedicalInformation;
