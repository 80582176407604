import { FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { useEffect, useRef } from 'react';
import type { MedicationPadItemType } from '../../PrescriptionPad';
import type { PrescriptionPadItemDetailType } from '../PrescriptionItemEditor.types';

type UseCancelAlertProps = {
  initialMedication?: MedicationPadItemType;
  currentMedicationPadItem?: PrescriptionPadItemDetailType;
  itemsToCancel: Array<{ productId: number }>;
};

export const useCancelAlert = ({ initialMedication, currentMedicationPadItem, itemsToCancel }: UseCancelAlertProps) => {
  const { flags } = useFeatureFlags();
  const ffV4PrescriptionAssistantCancelPrescribedMedication =
    flags[FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION];
  const initialMedicationRef = useRef(initialMedication);

  useEffect(() => {
    if (!initialMedicationRef.current) {
      initialMedicationRef.current = initialMedication;
    }
  }, [initialMedication]);

  const initial = initialMedicationRef.current;
  if (!ffV4PrescriptionAssistantCancelPrescribedMedication || !initial) {
    return { cancelAlertData: null };
  }

  const { medicationPadItem, reissueStatus } = initial;
  if (!medicationPadItem?.productId || medicationPadItem.productId === currentMedicationPadItem?.productId) {
    return { cancelAlertData: null };
  }

  if (
    reissueStatus?.isOutOfStock ||
    reissueStatus?.isDiscontinued ||
    reissueStatus?.isActive === false ||
    itemsToCancel.some((item) => item.productId === medicationPadItem.productId)
  ) {
    if (!medicationPadItem.supplierName || !medicationPadItem.productName) {
      return { cancelAlertData: null };
    }

    return {
      cancelAlertData: {
        productId: medicationPadItem.productId,
        supplierName: medicationPadItem.supplierName,
        medicationName: medicationPadItem.productName
      }
    };
  }

  return { cancelAlertData: null };
};
