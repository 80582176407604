import { Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DateField } from '@mui/x-date-pickers';
import type { ChangeEvent, FormEvent } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';

import AppointmentSummary from '@/components/paymentCard/components/AppointmentSummary';
import { InitialisingContext } from '@/components/paymentCard/components/CreditCardPayment';
import { FF_ENABLE_DISCOUNT_CODES } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import useDiscountCode, { DiscountCodeStatus } from '@/hooks/rest/useDiscountCode';
import { SROnly } from '@/shared-ui/SROnly/SROnly';

import { PaymentBookingConfirmed } from '@/components/paymentCard/components/PaymentBookingConfirmed';
import type { CalendarData } from '@/hooks/rest/types';
import { DateTime } from 'luxon';
import AddPromoCode from './AddPromoCode';
import PaymentFormSkeleton from './PaymentFormSkeleton';

export type Payload = {
  cardHolder?: string;
  expiry?: DateTime;
  secureFieldCode?: string;
  isConcession?: boolean;
  discountCode?: string;
};

type PaymentFormProps = {
  data: CalendarData;
  isPaymentTrackingTriggered: {
    current: boolean;
  };
  onSubmit: (payload: Payload) => void;
  setCreditCardError: (error: string) => void;
  setErrorMessages: (message: string[]) => void;
};

const StyledHostedInput = styled(Box)({
  height: '3.9rem'
});

const Form = styled('form')({
  marginTop: '1rem',
  width: '100%'
});

export const StyledTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: '1.3rem',
  marginBottom: '1rem'
}) as typeof Typography;

const StyledTextField = styled(TextField)({
  marginBottom: '0.5rem'
});

export default function PaymentForm({ data, isPaymentTrackingTriggered, onSubmit }: PaymentFormProps) {
  const { flags } = useFeatureFlags();
  const ffEnableDiscountCodes = flags[FF_ENABLE_DISCOUNT_CODES];

  const discountCodeFromParams = data?.promocode;
  const {
    loading: discountCodeLoading,
    data: discount,
    doGet: getDiscount
  } = useDiscountCode(discountCodeFromParams as string);
  const { initialising } = useContext(InitialisingContext);

  const [cardHolder, setCardHolder] = useState('');
  const [expiry, setExpiry] = useState<DateTime | null>(null);

  const [termsConditionsChecked, setTermsConditionsChecked] = useState(false);
  const [showTCValidationMessage, setShowTCValidationMessage] = useState(false);

  const isDiscountCodeValid = discount?.status === DiscountCodeStatus.valid;
  const discountCodeValue = isDiscountCodeValid ? discount?.discountCode : undefined;

  const appendPromocodetoLocalStorage = useCallback(() => {
    const trackingConsultationSummary = JSON.parse(localStorage.getItem('tracking_consultation_summary') || '{}');
    trackingConsultationSummary.promocode = discountCodeValue;
    localStorage.setItem('tracking_consultation_summary', JSON.stringify(trackingConsultationSummary));
  }, [discountCodeValue]);

  useEffect(() => {
    if (discount?.status === DiscountCodeStatus.valid) {
      appendPromocodetoLocalStorage();
    }
  }, [appendPromocodetoLocalStorage, discount]);

  const handleTermsConditionsChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setTermsConditionsChecked(event.target.checked);
    setShowTCValidationMessage(false);
  };

  function handleOnSubmit(event: FormEvent) {
    event.preventDefault();

    if (cardHolder && expiry) {
      onSubmit({ cardHolder, expiry });
    }
  }

  function handleDiscountApply(discountCode?: string) {
    if (!termsConditionsChecked) {
      setShowTCValidationMessage(true);
    }

    if (discountCode && termsConditionsChecked) {
      getDiscount({
        params: { discountCode }
      });
    }
  }

  return (
    <Form id="payment_form" method="POST" onSubmit={handleOnSubmit}>
      <Box display="grid" gridTemplateColumns={{ md: '1fr 1fr' }} gap={6}>
        <Box sx={{ gridRow: { xs: '1', md: '1/3' } }}>
          {initialising && <PaymentFormSkeleton />}
          <Box display={initialising ? 'none' : 'block'} paddingTop={{ xs: 0, sm: 0 }}>
            <PaymentBookingConfirmed email={data?.email} />
            <fieldset style={{ border: 'none', margin: '0', padding: '0' }}>
              <SROnly as="legend">Payment Form</SROnly>

              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <StyledTypography component="h3">Payment Details</StyledTypography>
                <StyledHostedInput id="cardNumber"></StyledHostedInput>

                <Box display="flex" justifyContent="space-between" gap={8}>
                  <DateField
                    format="MM/yy"
                    variant="filled"
                    required
                    minDate={DateTime.now()}
                    onChange={(val) => val && setExpiry(val)}
                    sx={{ flexBasis: '50%' }}
                  />
                  <StyledHostedInput id="cvv" sx={{ flexBasis: '50%' }}></StyledHostedInput>
                </Box>

                <StyledTextField
                  placeholder="Name on card"
                  required
                  variant="filled"
                  fullWidth
                  onChange={(e) => setCardHolder(e.target.value)}
                />
              </Box>
            </fieldset>
          </Box>
        </Box>

        <Box sx={{ order: { sx: 1, md: 2 } }}>
          <AppointmentSummary data={data} discount={discount} isPaymentTrackingTriggered={isPaymentTrackingTriggered} />
          {ffEnableDiscountCodes && (
            <Box mt={5}>
              <AddPromoCode
                isLoading={discountCodeLoading}
                value={discountCodeFromParams as string}
                handleDiscountApply={handleDiscountApply}
                status={discount?.status}
                handleTermsConditionsChecked={handleTermsConditionsChecked}
                showTCValidationMessage={showTCValidationMessage}
              />
            </Box>
          )}
          <Box sx={{ gridRow: { md: '2' }, mt: 5 }}>
            <Button
              type="submit"
              variant="contained"
              sx={{ width: '100%', mb: 4 }}
              style={{ textTransform: 'none', fontSize: '1rem' }}
            >
              Save payment details
            </Button>
            <Typography align={'center'} mb={10}>
              You will not be charged yet
            </Typography>
          </Box>
        </Box>
      </Box>
    </Form>
  );
}
