/* eslint-disable camelcase */
import settings from '@/data/constants';
import type { CalendarData } from '@/hooks/rest/types';

const emptyData: CalendarData = {
  inviteeUuid: null,
  assignedTo: null,
  startTime: null,
  eventName: null,
  firstName: null,
  lastName: null,
  fullName: null,
  phoneNumber: null,
  email: null,
  utmSource: null,
  utmTerm: null,
  utmMedium: null,
  utmCampaign: null,
  adgroupid: null,
  device: null,
  promocode: null,
  gclid: null,
  isEwayPayment: null
};

interface Doctor {
  doctor_email: string;
  calcom_initial_consult_slug: string;
  calcom_follow_up_consult_slug: string;
}

const LOCAL_STORAGE_KEY = 'calendar';

export function getParamDataFromUrl(): CalendarData {
  try {
    const searchParams = new URLSearchParams(window.location.search);

    return {
      inviteeUuid: searchParams.get('invitee_uuid'),
      assignedTo: searchParams.get('assigned_to'),
      startTime: searchParams.get('event_start_time'),
      // replace event_type_name with event_name after final transition to cal.com
      eventName: searchParams.get('event_type_name') || searchParams.get('event_name'),
      firstName: searchParams.get('invitee_first_name'),
      lastName: searchParams.get('invitee_last_name'),
      fullName: searchParams.get('invitee_full_name'),
      email: searchParams.get('invitee_email'),
      phoneNumber: searchParams.get('invitee_phone_number'),
      utmSource: searchParams.get('utm_source'),
      utmTerm: searchParams.get('utm_term'),
      utmMedium: searchParams.get('utm_medium'),
      utmCampaign: searchParams.get('utm_campaign'),
      adgroupid: searchParams.get('adgroupid'),
      device: searchParams.get('device'),
      gclid: searchParams.get('gclid'),
      promocode: searchParams.get('promocode'),
      isEwayPayment: searchParams.get('eway')
    };
  } catch (err) {
    console.log('Unable to parse calendar url data', err);
  }

  return emptyData;
}

export function getParamDataFromLocalStorage(): CalendarData | null {
  try {
    const data = window.localStorage.getItem(LOCAL_STORAGE_KEY);

    if (data) {
      return JSON.parse(data);
    }
  } catch (err) {
    console.log('Unable to get calendar data', err);
  }

  return null;
}

export function saveParamDataToLocalStorage(data: CalendarData): void {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
}

export function clearParamDataFromLocalStorage(): void {
  window.localStorage.removeItem(LOCAL_STORAGE_KEY);
}

export const getCalcomUsernameFromEmail = (email: string): string => {
  const emailUsername = email.substring(0, email.lastIndexOf('@')).replace(/\.|\+|_/g, '-');
  return emailUsername.toLowerCase();
};

export const generateCalcomRescheduledByUrlParams = (link: string, userId: number): string => {
  if (!userId) {
    return link;
  }

  const payload = {
    source: 'pms',
    userId
  };
  const payloadBase64 = encodeURIComponent(btoa(JSON.stringify(payload)));

  // pass both parametres through, as some calcom pages allow either action to be taken
  return `${link}${link.includes('?') ? '&' : '?'}rescheduledBy=${payloadBase64}&cancelledBy=${payloadBase64}`;
};

export const buildCancellationLink = (calcomConsultationUUID: string): string => {
  return `/booking/${calcomConsultationUUID}?allRemainingBookings=false&cancel=true`;
};

export const buildRescheduleLink = (calcomConsultationUUID: string): string => {
  return `/reschedule/${calcomConsultationUUID}`;
};

export const buildDoctorBookingLink = (doctor: Doctor, consultationSlug: string): string => {
  const doctorUsername = getCalcomUsernameFromEmail(doctor.doctor_email);
  return `${settings.calendar.calOrigin}/${doctorUsername}/${consultationSlug}`;
};
