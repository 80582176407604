import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';

import type { InputField } from '@/types/support-self-service.types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface Props {
  field: InputField;
  value: string;
  handleInputChange: (property: string, value: Date | null) => void;
}

const SupportTaskDatePicker = ({ field, handleInputChange }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    handleInputChange(field.property, date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-gb">
      <DatePicker
        label={field.label}
        value={selectedDate}
        format="dd/MM/yyyy"
        onChange={handleDateChange}
        sx={{ width: '100%' }}
      />
    </LocalizationProvider>
  );
};

export { SupportTaskDatePicker };
