import { CallPatientButton } from '@montugroup/clinic-telecom';
import { Button } from '@montugroup/design-system';
import { Close } from '@mui/icons-material';
import { Box, IconButton, Typography, styled } from '@mui/material';
import { DateTime } from 'luxon';

import { FF_C4C_RECORD_VOICE_CALL, FF_C4C_VOICE_CALL } from '@/constants/featureFlags';
import settings from '@/data/constants';
import { useFeatureFlags } from '@/hooks';
import { useGetConsultationTelecomDetails } from '@/hooks/consult/useGetConsultationTelecomDetails';
import { useSavePatientTelecomAttributes } from '@/hooks/telecom/useSavePatientTelecomAttributes';
import type { ConsultationModel } from '@/types';
import { Logger } from '@/utils/logger';
import { luxonFormatWithOrdinalSuffix } from '@/utils/luxon';
import type { ReactNode } from 'react';

const logger = new Logger('ConsultationDetails');

const Heading = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: theme.spacing(6),
  borderBottom: `1px solid ${theme.palette.primary.light}`
}));

const DetailContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(6),
  flexDirection: 'column',

  [theme.breakpoints.up('xl')]: {
    flexDirection: 'row'
  }
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(4),
  fontSize: '1rem',
  fontWeight: theme.typography.fontWeightBold
}));

const CallContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  maxWidth: '19rem',
  alignSelf: 'flex-start',

  [theme.breakpoints.up('xl')]: {
    alignSelf: 'flex-end'
  }
}));

type DetailItemProps = {
  id: string;
  label: string;
  children: ReactNode;
};

const DetailItem = ({ id, label, children }: DetailItemProps) => (
  <>
    <Typography
      id={id}
      sx={(theme) => ({
        fontWeight: theme.typography.fontWeightBold,
        marginRight: theme.spacing(2),
        whiteSpace: 'nowrap'
      })}
    >
      {label}
    </Typography>
    <Box id={id}>{children}</Box>
  </>
);

export type ConsultationDetailsProps = {
  consultation: ConsultationModel;
  /**
   * interim solution to prop drill to get phone number / patient id to dial out.
   * once PatientDetailsV3 is rolled out, `CallPatientNumber` button may just use the hook directly
   * TODO: B2C-5823
   */
  patientPhoneNumber?: string;
  patientId?: string;
  onClose?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
};

export const ConsultationDetails = ({
  consultation,
  onClose,
  patientId,
  patientPhoneNumber
}: ConsultationDetailsProps) => {
  const getPractitionerName = () => {
    if (consultation?.Doctor?.DoctorUser) {
      return `${consultation.Doctor.DoctorUser?.first_name} ${consultation.Doctor.DoctorUser?.last_name}`;
    }
    if (consultation?.Nurse?.NurseUser) {
      return `${consultation.Nurse?.NurseUser?.first_name} ${consultation.Nurse?.NurseUser?.last_name}`;
    }

    // when there's nothing, return empty string
    return '';
  };
  const { flags } = useFeatureFlags();

  const startDateTime = DateTime.fromISO(consultation.start_time || '');
  const endDateTime = DateTime.fromISO(consultation.end_time || '');
  const durationMins = consultation.ConsultationType.duration_mins;

  const appointmentTime = `${startDateTime.toFormat('MMM')} ${luxonFormatWithOrdinalSuffix(startDateTime.day)} ${startDateTime.toFormat('yyyy, h:mm a').toLowerCase()} - ${endDateTime.toFormat('h:mm a').toLowerCase()} (${durationMins} mins)`;
  const isVoiceCallEnabled = flags[FF_C4C_VOICE_CALL];
  const isRecordingVoiceCallsEnabled = flags[FF_C4C_RECORD_VOICE_CALL];

  const activeConsultStatuses = [settings.consultationStatus.pending, settings.consultationStatus.scheduled];

  // For non consultation call, call patient button is always shown
  const isEligibleForVoiceCall =
    consultation.status_id == undefined || activeConsultStatuses.includes(consultation.status_id);

  const isRecordingAllowed = isRecordingVoiceCallsEnabled && isEligibleForVoiceCall;

  /*
    Display the 'Call Patient' button only when:
    1. Voice call is enabled and voice recording is disabled, OR
    2. Voice call is enabled, voice recording is enabled, and the call is eligible
  */
  const displayCallPatientButton = isVoiceCallEnabled && (!isRecordingVoiceCallsEnabled || isRecordingAllowed);

  const { mutateAsync: savePatientTelecomAttributes } = useSavePatientTelecomAttributes(
    Number(patientId),
    consultation.id
  );

  const handleContact = async (contact: connect.Contact) => {
    try {
      await savePatientTelecomAttributes(contact.contactId);
    } catch (error) {
      logger.error('Failed to save patient telecom attributes', error);
    }
  };

  const { data } = useGetConsultationTelecomDetails(consultation.id, isRecordingVoiceCallsEnabled);
  return (
    <>
      <Heading>
        <Typography
          variant="h2"
          sx={(theme) => ({ color: theme.palette.primary.light, fontSize: '16px', fontWeight: 600 })}
        >
          Consultation Details
        </Typography>
        <IconButton onClick={onClose} size="small">
          <Close />
        </IconButton>
      </Heading>

      <DetailContainer>
        <Box
          sx={(theme) => ({
            marginBottom: displayCallPatientButton ? theme.spacing(6) : 0,
            marginRight: 0,

            [theme.breakpoints.up('xl')]: {
              marginBottom: 0,
              marginRight: displayCallPatientButton ? theme.spacing(4) : 0
            }
          })}
        >
          <SubHeading variant="h4">
            {consultation.doctor_id ? 'Doctor Consultation' : 'Nurse Consultation'} ({consultation.consultation_code})
          </SubHeading>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'max-content 1fr',
              columnGap: (theme) => theme.spacing(4)
            }}
          >
            <DetailItem id="doctor-appointment-time" label="Practitioner">
              {getPractitionerName()}
            </DetailItem>
            <DetailItem id="doctor-appointment-time" label="Appointment Time">
              {appointmentTime}
            </DetailItem>
            {!displayCallPatientButton && (
              <>
                <DetailItem id="doctor-zoom-link" label="Zoom link">
                  <Button
                    variant="text"
                    onClick={() => {
                      if (consultation?.zoom_url) {
                        window.open(consultation.zoom_url);
                      }
                    }}
                  >
                    {consultation.zoom_url || ''}
                  </Button>
                </DetailItem>
                <DetailItem id="doctor-appointment-status" label="Current Status">
                  {consultation.ConsultationStatus?.name || ''}
                </DetailItem>
              </>
            )}
          </Box>
        </Box>

        {displayCallPatientButton && (
          <CallContainer>
            <Box sx={(theme) => ({ display: 'flex', alignItems: 'center', marginBottom: theme.spacing(1) })}>
              <DetailItem id="doctor-appointment-status" label="Consultation Status:">
                {consultation.ConsultationStatus?.name || ''}
              </DetailItem>
            </Box>
            <CallPatientButton
              patientId={patientId}
              patientPhoneNumber={patientPhoneNumber}
              queueARN={data?.body.queueArn}
              onContact={handleContact}
            >
              Start Consultation
            </CallPatientButton>
          </CallContainer>
        )}
      </DetailContainer>
    </>
  );
};
