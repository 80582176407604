import type { PutRescriptPayload } from '@/api/prescriptions/putPrescriptionsBatchReplace';
import { putPrescriptionBatchReplace } from '@/api/prescriptions/putPrescriptionsBatchReplace';
import { SUCCESSFUL_SCRIPT_UPDATE_MESSAGE } from '@/components/Rescripting/Rescripting.constants';
import { QueryKey } from '@/data/query';
import { toast } from '@montugroup/design-system';
import { useMutation } from '@tanstack/react-query';

const usePutPrescriptBatchReplace = () => {
  const queryKey = [QueryKey.PutPrescriptionBatchReplace];

  const { mutateAsync, isError, isPending } = useMutation({
    mutationKey: queryKey,
    mutationFn: async (payload: PutRescriptPayload) => putPrescriptionBatchReplace(payload),
    onSuccess: () => {
      toast.success({ message: SUCCESSFUL_SCRIPT_UPDATE_MESSAGE });
    }
  });

  return {
    putPrescriptionBatchReplace: mutateAsync,
    isError: isError,
    isLoading: isPending
  };
};

export { usePutPrescriptBatchReplace };
