import type { FilteredProduct, RankDecoration } from '@/api';
import type { MedicationPadItemType, ReissueStatusType } from '@/components/PatientPageV2/components/PrescriptionPad';
import type { MedicationType } from '@/components/PatientPageV2/components/ProductDetailsCard/ProductDetailsCard.types';
import { transformFilteredProductToPrescriptionMetadata } from '@/components/PatientPageV2/components/ProductDetailsCard/ProductDetailsCard.utils';
import { DEFAULT_QUESTION_ID, REISSUED_ANSWER_ID } from '@/shared-ui/TierLabel/TierLabel.types';
import { getErrorMessageForMedicationItem } from '@/state-management/utils/getErrorMessageForMedicationItem.utils';
import { RankTier } from '@montugroup/pms-api-contracts';

/**
 * Creates a new reissued medication by merging it with existing prescribed medications,
 * considering out-of-stock or inactive scenarios.
 *
 * @param availableProducts - All available medications for the doctor to choose from.
 * @param reissuedMedicationItem - The reissued medication to be added to the pad.
 * @param validateDosageMinLength - Optional flag for dosage length validation, default is false.
 * @returns A new reissued medication item or undefined if productId is missing or invalid.
 */
export const createReissuedMedication = (
  availableProducts: FilteredProduct[],
  reissuedMedicationItem: MedicationPadItemType,
  validateDosageMinLength: boolean = false,
  ffV4PrescriptionAssistantCancelPrescribedMedication: boolean = false
): MedicationPadItemType | undefined => {
  const { medicationPadItem } = reissuedMedicationItem;
  const productId = medicationPadItem?.productId;

  // Validate and parse the productId to a number
  const numericProductId = productId !== undefined && !isNaN(Number(productId)) ? Number(productId) : undefined;
  if (numericProductId === undefined) {
    return undefined;
  }

  const matchingProduct = availableProducts.find((product) => product.id === numericProductId);

  // Ensure medicationPadItem is defined before calling getUpdatedMedicationPadItem
  const updatedMedicationPadItem =
    matchingProduct && medicationPadItem
      ? getUpdatedMedicationPadItem(medicationPadItem, matchingProduct)
      : medicationPadItem;

  const isOutsideTopRecommendation = matchingProduct ? !isTopRecommendation(matchingProduct) : false;

  const updatedReissueStatus = buildReissueStatus(
    reissuedMedicationItem.reissueStatus,
    medicationPadItem,
    matchingProduct
  );

  const errorMessage = getErrorMessageForMedicationItem(updatedMedicationPadItem, validateDosageMinLength);

  // Build the new MedicationPadItemType object
  return {
    ...reissuedMedicationItem,
    medicationPadItem: updatedMedicationPadItem,
    moreProductsLoaded: ffV4PrescriptionAssistantCancelPrescribedMedication ? false : isOutsideTopRecommendation,
    feedback: isOutsideTopRecommendation
      ? {
          questionAsked: DEFAULT_QUESTION_ID,
          answerSelected: REISSUED_ANSWER_ID
        }
      : undefined,
    reissueStatus: updatedReissueStatus,
    errorMessage
  };
};

const getUpdatedMedicationPadItem = (
  medicationPadItem: MedicationType,
  matchingProduct: FilteredProduct
): MedicationType => ({
  ...medicationPadItem,
  ...transformFilteredProductToPrescriptionMetadata(matchingProduct),
  productName: matchingProduct.productName,
  supplierName: matchingProduct.supplierName
});

const isTopRecommendation = (product: FilteredProduct): boolean => {
  const decoration = product.decoration as RankDecoration;
  return decoration.tier === RankTier.First;
};

const buildReissueStatus = (
  currentReissueStatus: ReissueStatusType | undefined,
  medicationPadItem: MedicationType | undefined,
  matchingProduct?: FilteredProduct
): ReissueStatusType => {
  const fallbackProductId =
    medicationPadItem?.productId !== undefined && !isNaN(Number(medicationPadItem.productId))
      ? Number(medicationPadItem.productId)
      : undefined;

  const productId = matchingProduct?.id ?? fallbackProductId;
  const productName = matchingProduct?.productName || medicationPadItem?.productName || '';
  const supplierName = matchingProduct?.supplierName || medicationPadItem?.supplierName || '';
  const description = matchingProduct?.description || medicationPadItem?.description || '';

  return {
    ...currentReissueStatus,
    productId,
    productName,
    supplierName,
    description
  };
};
