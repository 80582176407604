import type { Dispatch } from 'react';
import { useCallback, useState } from 'react';

import settings from '@/data/constants';
import { useFeatureFlags, useGetPrescribedProducts } from '@/hooks';
import type { DeviceProduct, PrescriptionFormProduct } from '@/hooks/prescription/usePrescriptionForm';
import usePrescriptionForm from '@/hooks/prescription/usePrescriptionForm';
import { usePostPrescription } from '@/hooks/rest/usePostPrescription';
import { usePostProductSurvey } from '@/hooks/rest/usePostProductSurvey';
import { useAppStore } from '@/state-management';
import type { ConsultationModel } from '@/types';
import { Logger } from '@/utils/logger';

import type { PrescribeRequestBodySchema } from '@montugroup/prescription-contracts';
import { FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION } from '../../../constants/featureFlags';
import { usePrescribePrescriptions } from '../../../hooks/prescription/usePrescribePrescriptions';
import { PrescriptionPadStep } from '../components/PrescriptionPad/PrescriptionPad.types';
import {
  transformAndMergeMedicationsAndItemsToReissue,
  transformFeedbackPayload,
  transformPrescriptionsPayload,
  transformV3DeviceProductsToV4DeviceProducts
} from '../components/PrescriptionPad/PrescriptionPad.utils';

const logger = new Logger('useConsultationTabState.ts');
export interface SaveConsultationPayload {
  id: number;
  // TODO - please update this if you know what this should be in your ticket.
  notes: any;
  next_consultation_in_weeks: number;
  status_id: number;
  sub_status_id?: number | null;
  active?: boolean;
}

export interface UseConsultationTabStateProps {
  doctorId: number;
  patientId: number;
  saveConsultation: (data: SaveConsultationPayload, askConfirmation?: boolean) => Promise<void>;
  showConsultation: ConsultationModel | null;
  setShowConsultation: (consultation: ConsultationModel | null) => void;
}

/**
 * The shape of the `data` state of ConsultationTab / Tab
 */
export interface ConsultationPrescriptionDataState {
  products: PrescriptionFormProduct[];
  weeks: number;
  patientId: number;
  homeDelivery?: string;
}

export interface ConsultationTabState {
  /**
   * Consultation data relating to prescriptions
   */
  data: ConsultationPrescriptionDataState;
  setData: Dispatch<React.SetStateAction<ConsultationPrescriptionDataState>>;

  /**
   * Part of `useConsultationTabState`. This function takes in the prescription state data
   * and submits it to the API to create a prescription.
   *
   *
   */
  submitPrescriptions: () => Promise<Response | null>;
  isPostingPrescription: boolean;
}

/**
 * This custom hook extracts business logic from ConsultationTab to separate
 * concerns, and allow `<ConsultationTab>` to **progressively** move towards having
 * better type safety.
 * @param root0
 * @param root0.doctorId
 * @param root0.patientId
 * @param root0.saveConsultation
 * @param root0.showConsultation
 * @param root0.setShowConsultation
 */
export const useConsultationTabState = ({
  doctorId,
  patientId,
  saveConsultation,
  showConsultation,
  setShowConsultation
}: UseConsultationTabStateProps): ConsultationTabState => {
  const {
    tmpHelpers: { initialPrescriptionProductValues }
  } = usePrescriptionForm();

  const [data, setData] = useState<ConsultationPrescriptionDataState>({
    weeks: 8,
    // is patientId really necessary in `setData` since it's already in the props?
    patientId,
    products: [
      {
        ...initialPrescriptionProductValues
      }
    ]
  });

  const { flags } = useFeatureFlags();
  const {
    prescriptionPad: { medicationPadItems, devicePadItems },
    prescriptionHistory: { itemsToReissue }
  } = useAppStore.use.prescriptionAssistant();
  const updatePrescriptionPadStep = useAppStore.use.updatePrescriptionPadStep();
  const resetPrescriptionPad = useAppStore.use.resetPrescriptionPad();
  const productSurveyFeedbackPayload = transformFeedbackPayload(medicationPadItems);
  const medicationProductsPayload = transformPrescriptionsPayload(medicationPadItems);
  const { mutate: postSurvey } = usePostProductSurvey();
  const { postPrescription, isPending: isPostingPrescription } = usePostPrescription();
  const { mutateAsync: prescribePrescriptions } = usePrescribePrescriptions();
  const ffV4PrescriptionAssistantCancelPrescribedMedication =
    flags[FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION];
  const { refetch: refetchPrescribedProducts } = useGetPrescribedProducts({
    patientId: `${patientId}`
  });

  const submitPrescriptions = useCallback(async () => {
    logger.info('submitPrescriptions', {
      consultationId: showConsultation?.id,
      doctorId,
      patientId: data.patientId,
      saveConsultation: !!saveConsultation
    });
    if (!showConsultation || !saveConsultation || !doctorId || !data.patientId) {
      return null;
    }
    try {
      await saveConsultation(
        {
          id: showConsultation.id,
          notes: showConsultation.notes,
          next_consultation_in_weeks: data.weeks,
          status_id: settings.consultationStatus.completed
        },
        false
      );
    } catch (error) {
      logger.error('Failed to save consultation', {
        error,
        doctorId,
        consultationId: showConsultation?.id
      });
      throw error;
    }

    const validProducts: (PrescriptionFormProduct | DeviceProduct)[] = medicationProductsPayload.filter(
      (product) => product.product_id !== ''
    );

    if (devicePadItems) {
      const validDeviceProducts = devicePadItems.filter((item) => !!item);
      validProducts.push(...validDeviceProducts);
    }

    // no products prescribed, skip the post
    if (validProducts.length === 0) {
      setShowConsultation(null);
      return null;
    }

    try {
      let responseBody;

      if (ffV4PrescriptionAssistantCancelPrescribedMedication) {
        const medicationsPrescribeEndpointPayload = transformAndMergeMedicationsAndItemsToReissue(
          medicationPadItems,
          itemsToReissue
        );

        const sendBody = {
          orderDate: new Date().toISOString(),
          products: medicationsPrescribeEndpointPayload,
          devices: transformV3DeviceProductsToV4DeviceProducts(devicePadItems)
        };
        const response = await prescribePrescriptions({
          params: {
            patientId: `${data.patientId}`
          },
          body: sendBody as PrescribeRequestBodySchema
        });

        responseBody = response.body;
      } else {
        const sendBody = {
          data: {
            order_date: new Date(),
            consultation_id: showConsultation.id,
            patient_id: data.patientId,
            doctor_id: showConsultation?.doctor_id,
            is_home_delivery: data.homeDelivery === '1'
          },
          products: [...validProducts]
        };

        const response = await postPrescription(sendBody);

        if (response === null || response?.ok === false) {
          throw new Error(`postPrescription http error: ${response?.status}`);
        }

        responseBody = await response.json();
      }

      setShowConsultation(null);

      if (productSurveyFeedbackPayload?.length) {
        postSurvey({
          doctorId,
          patientId,
          feedback: productSurveyFeedbackPayload
        });
      }
      // Reset form back to Create Script step when successful
      updatePrescriptionPadStep(PrescriptionPadStep.Create);
      resetPrescriptionPad();
      // refetch prescribed products on script creation
      refetchPrescribedProducts();

      // typescript seems unable to infer the type properly :(
      // most likely because we were using fetch in the v3 and in v4 we use useQuery
      // use type casting for now
      return responseBody as unknown as Response;
    } catch (error) {
      logger.error('Failed to create prescription', {
        doctorId,
        patientId,
        error
      });
      throw new Error('Failed to create prescription');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    doctorId,
    patientId,
    showConsultation,
    setShowConsultation,
    medicationPadItems,
    data,
    devicePadItems,
    postPrescription,
    postSurvey,
    saveConsultation
  ]);

  return {
    data,
    setData,
    submitPrescriptions,
    isPostingPrescription
  };
};
