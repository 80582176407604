import { PrescriptionPadStep } from '@/components/PatientPageV2/components/PrescriptionPad/PrescriptionPad.types';

import type { PrescriptionHistoryState, PrescriptionPadState, WAHealthState } from '../types';

const initialPrescriptionPadReviewActions = [
  {
    id: 1,
    label:
      'I confirm that I have accessed all relevant real-time prescribing services in any state that may be relevant to this patient.',
    checked: false
  },
  {
    id: 2,
    label: 'I have documented thorough clinical notes for this consultation.',
    checked: false
  }
];

export const initialPrescriptionHistoryState: PrescriptionHistoryState = {
  itemsToAction: [],
  itemsToReissue: [],
  itemsToCancel: []
};

export const initialPrescriptionPadState: PrescriptionPadState = {
  medicationPadItems: [],
  devicePadItems: [],
  step: PrescriptionPadStep.Create,
  actions: initialPrescriptionPadReviewActions
};

export const initialWAHealthState: WAHealthState = {
  isWaPatient: false
};

export const initialStatePrescriptionAssistantSlice = {
  prescriptionHistory: initialPrescriptionHistoryState,
  prescriptionPad: initialPrescriptionPadState,
  waHealth: initialWAHealthState
};
