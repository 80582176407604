import type { GetPrescriptionsResponse } from '@/api/prescriptions/getPrescriptionsWithOutOfStockMedications';
import type { RescriptPrescriptions } from '@/api/prescriptions/putPrescriptionsBatchReplace';
import { renderHeader } from '@/components/tables/components/Table.utils';
import type { CatalogProduct } from '@/hooks/rest/useGetProductList';
import type { Prescription, ScriptProduct } from '@/types/prescriptions.types';
import dayjs from '@/utils/dayjs';
import { createColumnHelper } from '@tanstack/react-table';
import { BLANK_URL, EMPTY_TABLE_CELL_PLACEHOLDER } from '../../Rescripting.constants';
import {
  buildUniqueRowKey,
  getDefaultAlternativeMedication,
  makeApprovedPrescriptionChangeset,
  makeNewPrescriptionProduct,
  scriptProductToRescriptProduct
} from '../../utils';
import { getPatientRescriptRequestCount } from '../../utils/getPatientRescriptRequestCount';
import { CheckboxCell } from '../shared/tableCells/CheckboxCell';
import { MedicationsOnScriptCell } from '../shared/tableCells/MedicationsOnScriptCell/MedicationsOnScriptCell';
import { PatientCodeLinkCell } from '../shared/tableCells/PatientCodeLinkCell/PatientCodeLinkCell';
import { PatientRescriptRequestCountHeaderCell } from '../shared/tableCells/PatientRescriptRequestCountHeaderCell/PatientRescriptRequestCountHeaderCell';
import { PriorityChip } from '../shared/tableCells/PriorityChip/PriorityChip';
import { ProductSelectCell } from '../shared/tableCells/ProductSelectCell';
import type { OutOfStockTableRowData } from './OutOfStockPanel.types';
import { OutOfStockTableColumnHeaders } from './OutOfStockPanel.types';

const isOutOfStockMedication = (product: ScriptProduct): boolean => product.is_out_of_stock;

const buildOutOfStockMedicationTableHeaders = (
  alternativeProductList: CatalogProduct[],
  onAlternativeMedicationSelect: React.Dispatch<React.SetStateAction<Map<string, CatalogProduct>>>
) => {
  const columnHelper = createColumnHelper<OutOfStockTableRowData>();

  return [
    columnHelper.display({
      id: 'checkBoxCell',
      cell: ({ row }) => {
        const isRowSelected = row.getIsSelected();
        const selectedAlternativeProductId = row.original.selectedAlternativeProductId;
        return (
          <CheckboxCell
            isRowSelected={isRowSelected}
            selectedAlternativeProductId={selectedAlternativeProductId}
            alternativeProductList={alternativeProductList}
          />
        );
      },
      meta: {
        sx: {
          width: 25
        }
      }
    }),
    columnHelper.accessor('ageOfRescriptRequest', {
      id: 'ageOfRescriptRequest',
      header: ({ table }) => {
        const patientRescriptRequestCount = getPatientRescriptRequestCount(table.getCoreRowModel());

        return (
          <PatientRescriptRequestCountHeaderCell
            headerLabel={OutOfStockTableColumnHeaders.Age_Of_Rescript_Request}
            count={patientRescriptRequestCount}
          />
        );
      },
      cell: (cell) => <PriorityChip value={cell.getValue()} />,
      meta: {
        sx: {
          width: 100
        }
      }
    }),
    columnHelper.accessor('patientName', {
      header: () => renderHeader(OutOfStockTableColumnHeaders.Patient_Name),
      cell: (cell) => {
        return cell.getValue() ? cell.getValue() : '-';
      },
      meta: {
        sx: {
          width: 120
        }
      }
    }),
    columnHelper.accessor('patientCode', {
      header: () => renderHeader(OutOfStockTableColumnHeaders.Patient_Code),
      cell: ({ cell, row }) => {
        const patientCode = cell.getValue();
        const patientId = row.original.patientId;
        return <PatientCodeLinkCell patientCode={patientCode} patientId={patientId} />;
      },
      meta: {
        sx: {
          width: 80
        }
      }
    }),
    columnHelper.accessor('outOfStockMedicationName', {
      header: () => renderHeader(OutOfStockTableColumnHeaders.Out_Of_Stock_Medication),
      cell: (cell) => {
        return cell.getValue() ? cell.getValue() : '-';
      },
      meta: {
        sx: {
          width: 160
        }
      }
    }),
    columnHelper.accessor('allMedicationsOnScript', {
      header: () => renderHeader(OutOfStockTableColumnHeaders.Script_Medications),
      cell: ({ cell, row }) => {
        const rowId = row.id;
        const medications = cell.getValue().map((medication) => ({
          name: medication.name,
          toFlag: medication.isOutOfStock
        }));

        return <MedicationsOnScriptCell medications={medications} rowId={rowId} />;
      },
      meta: {
        sx: {
          width: 160
        }
      }
    }),
    columnHelper.accessor('selectedAlternativeProductId', {
      header: () => renderHeader(OutOfStockTableColumnHeaders.Alternative_Medication_List),
      cell: ({ cell, row }) => (
        <ProductSelectCell
          value={cell.getValue() ? Number(cell.getValue()) : ''}
          options={alternativeProductList ?? []}
          onSelect={(selectedProduct) => {
            if (selectedProduct) {
              onAlternativeMedicationSelect((prevMap) =>
                new Map(prevMap).set(row.original.uniqueRowKey, selectedProduct)
              );
            }
          }}
        />
      ),
      meta: {
        sx: {
          width: 160
        }
      }
    }),
    columnHelper.accessor('doctorName', {
      header: () => renderHeader(OutOfStockTableColumnHeaders.Doctor_Name),
      cell: (cell) => {
        return cell.getValue() ? cell.getValue() : '-';
      },
      meta: {
        sx: {
          width: 160
        }
      }
    })
  ];
};

/**
 * Builds out an array of objects that represent the data for each table
 * row. This is done by first looping over the prescriptions and storing
 * an array that houses the out of stock medications that are apart of
 * that prescription. That array is then mapped over to construct the
 * specific data required within a table row.
 *
 * @param getPrescriptionsResponse an object that contains a field
 * for the prescriptions that include one or more out of stock medications
 * @param modifiedScripts a map that contains a key value pairing of the
 * uniqueRowKey to the id of the alternative medication selected
 * @returns an array of table row data to be consumed by a table component
 */
const buildOutOfStockTableData = (
  getPrescriptionsResponse: GetPrescriptionsResponse | undefined,
  modifiedScripts: Map<string, CatalogProduct>
): OutOfStockTableRowData[] => {
  const outOfStockTableRowData: OutOfStockTableRowData[] = [];

  getPrescriptionsResponse?.prescriptions.forEach((prescription: Prescription) => {
    const outOfStockProducts = prescription.Products.filter((product) => isOutOfStockMedication(product));

    const outOfStockMedicationTableRows = outOfStockProducts.map((product) => {
      const { id } = prescription;
      const {
        PatientUser = {
          first_name: EMPTY_TABLE_CELL_PLACEHOLDER,
          last_name: EMPTY_TABLE_CELL_PLACEHOLDER
        },
        patient_code = null
      } = prescription.Patient ?? {};
      const patientId = prescription.patient_id;
      const { first_name, last_name } = prescription.Doctor.DoctorUser;

      const uniqueRowKey = buildUniqueRowKey(id, product.id);
      const modifiedAlternative = modifiedScripts.get(uniqueRowKey);
      const defaultAlternative = product?.alternativeProducts[0];
      const selectedAlternativeId =
        (modifiedAlternative && modifiedAlternative.id) || (defaultAlternative && defaultAlternative.id) || 0;
      const ageOfRescriptRequest = dayjs(product.patient_rescript_request_date).isValid()
        ? dayjs(product.patient_rescript_request_date).fromNow()
        : '';

      return {
        uniqueRowKey,
        patientName: `${PatientUser.first_name} ${PatientUser.last_name}`,
        patientCode: patient_code || EMPTY_TABLE_CELL_PLACEHOLDER,
        patientId: patientId?.toString() || BLANK_URL,
        outOfStockMedicationName: product.name,
        allMedicationsOnScript: prescription.Products.map((product) => ({
          name: product.name,
          isOutOfStock: isOutOfStockMedication(product)
        })),
        selectedAlternativeProductId: selectedAlternativeId,
        doctorName: `${first_name || ''} ${last_name || ''}`,
        ageOfRescriptRequest
      };
    });

    outOfStockTableRowData.push(...outOfStockMedicationTableRows);
  });

  return outOfStockTableRowData;
};

/**
 * Loops over the approved script changesets and the products within
 * them to construct an array of prescriptions to rescript
 *
 * @param selectedUniqueRowKeys an array of selected unique row keys
 * @param prescriptionsWithOOSMedications an array of prescriptions that
 * have out of stock medications
 * @param modifiedScripts a map that holds prescriptionId to medication
 * data
 * @returns an array of rescript prescriptions
 */
const buildOutOfStockRescriptPutBody = (
  selectedUniqueRowKeys: string[],
  prescriptionsWithOOSMedications: Prescription[],
  modifiedScripts: Map<string, CatalogProduct>
) => {
  const originalScriptPerId = new Map(prescriptionsWithOOSMedications.map((script) => [script.id, script]));
  const approvedScriptChangeset = makeApprovedPrescriptionChangeset(selectedUniqueRowKeys, modifiedScripts);
  const rescriptPrescriptions: RescriptPrescriptions[] = [];

  approvedScriptChangeset.forEach((approvedProductChangeset, scriptId) => {
    const originalScript = originalScriptPerId.get(scriptId);
    const selectedOutOfStockScriptProducts =
      originalScript?.Products.filter((product) => Array.from(approvedProductChangeset.keys()).includes(product.id)) ||
      [];
    const newScriptProducts = selectedOutOfStockScriptProducts.map(scriptProductToRescriptProduct);

    approvedProductChangeset.forEach((modifiedScriptProduct, productId) => {
      const currentIndex = newScriptProducts.findIndex((rescriptProduct) => rescriptProduct.productId === productId);
      const currentRescriptProduct = newScriptProducts[currentIndex];
      const currentScriptProduct = selectedOutOfStockScriptProducts.find(
        (scriptProduct) => scriptProduct.id === productId
      );
      const defaultAlternativeProduct = getDefaultAlternativeMedication(currentScriptProduct);
      newScriptProducts[currentIndex] = makeNewPrescriptionProduct(
        currentRescriptProduct,
        modifiedScriptProduct,
        defaultAlternativeProduct,
        true
      );
    });
    rescriptPrescriptions.push({
      originalPrescriptionId: scriptId,
      newPrescription: {
        orderDate: new Date().toISOString(),
        patientId: originalScript?.patient_id,
        // Use doctor id from original script.
        doctorId: originalScript?.doctor_id,
        products: newScriptProducts
      }
    });
  });

  return rescriptPrescriptions;
};

export { buildOutOfStockMedicationTableHeaders, buildOutOfStockRescriptPutBody, buildOutOfStockTableData };
