import settings from '@/data/constants';
import axios from 'axios';

export type RescriptProduct = {
  productId: number;
  repeats: number;
  quantity: number;
  interval: number;
  dosage: string;
  name: string;
  isConcession: boolean;
  otherProduct: string;
  originalProductId?: number;
  isPatientRescriptReq?: boolean;
};

export type RescriptPrescriptions = {
  originalPrescriptionId: number;
  newPrescription: {
    orderDate: string;
    patientId: number | undefined;
    doctorId: number | undefined;
    products: RescriptProduct[];
  };
};

export type PutRescriptPayload = {
  rescriptType: 'GENERATIVE' | 'OUT_OF_STOCK';
  prescriptions: RescriptPrescriptions[];
};

const putPrescriptionBatchReplace = async (payload: PutRescriptPayload) => {
  const response = await axios.put(`${settings.url}/prescription/batch-replace`, payload);
  return response.data;
};

export { putPrescriptionBatchReplace };
