import type {
  CancelMedicationItemType,
  MedicationPadItemType
} from '@/components/PatientPageV2/components/PrescriptionPad';
import { getProductDisplayName } from '@/components/PatientPageV2/components/PrescriptionPad';
import { MedicationPrescriptionStatus } from '@montugroup/prescription-contracts';
import { getErrorMessageForMedicationItem } from './getErrorMessageForMedicationItem.utils';

/**
 * Updates provided param `medicationPadItems` list with `updatedMedicationPadItem`
 * @param medicationPadItems - A list of medication pad items
 * @param updatedMedicationPadItem - Medication pad item to be updated within the list
 * @param ffPatPrescriptionDosageMinLengthValidation - flag reference for internal logic
 * @returns A new copy of the `medicationPadItems` list updated with `updatedMedicationPadItem` in `updatedItems`.
 * If the product id has changed, a reference is returned for the `previousProductId`
 */
export const getUpdateMedicationPadItems = (
  medicationPadItems: MedicationPadItemType[],
  updatedMedicationPadItem: MedicationPadItemType,
  ffPatPrescriptionDosageMinLengthValidation: boolean
) => {
  let previousProductId: number = -1;

  const updatedItems = medicationPadItems.map((item) => {
    if (item.medicationPadItemId === updatedMedicationPadItem.medicationPadItemId) {
      previousProductId = item.medicationPadItem?.productId ?? previousProductId;
      const errorMessage = getErrorMessageForMedicationItem(
        updatedMedicationPadItem.medicationPadItem,
        ffPatPrescriptionDosageMinLengthValidation
      );
      return {
        ...updatedMedicationPadItem,
        errorMessage
      };
    }
    return item;
  });

  return {
    updatedItems,
    previousProductId
  };
};

type ReissueOrCancelReturn =
  | {
      type: 'NO_ACTION';
    }
  | {
      type: 'MOVE_BACK_TO_REISSUE';
      updatedCanceledItems: CancelMedicationItemType[];
      updatedReissuedItems: MedicationPadItemType[];
      updatedMedicationPadItem: MedicationPadItemType;
    }
  | {
      type: 'UPDATE_REISSUE';
      updatedItemsToReissue: MedicationPadItemType[];
    }
  | {
      type: 'MOVE_TO_CANCEL';
      itemToCancel: {
        productId: number;
        displayName: string;
      };
    };

/**
 * Based on params provided, it determines cases where items need to be reissued or updated item needs to be cancelled.
 * @param updatedMedicationPadItem - The medication pad item which will be changing product
 * @param itemsInReissue - list of medication pad items that are listed for reissue
 * @param itemsInCanceled - list of medication pad items that are listed for cancelation
 * @param previousProductId - product Id of previous item selection
 * @param updatedProductId - product Id for new item selection
 * @param ffV4PrescriptionAssistantCancelPrescribedMedication - flag reference for internal logic
 * @returns ReissueOrCancelReturn -  An object containing a property `type` with 4 possible values: 'NO_ACTION', 'MOVE_BACK_TO_REISSUE', 'UPDATE_REISSUE', OR 'MOVE_TO_CANCEL'
 * In case of an action, the object will either contain which items to reissue or which item to cancel
 */
export const determineReissueOrCancelWhenChangingProducts = (
  updatedMedicationPadItem: MedicationPadItemType,
  itemsInReissue: MedicationPadItemType[],
  itemsInCanceled: CancelMedicationItemType[],
  previousProductId: number,
  ffV4PrescriptionAssistantCancelPrescribedMedication: boolean
): ReissueOrCancelReturn => {
  const { medicationPadItem } = updatedMedicationPadItem;
  const updatedProductId = medicationPadItem?.productId;

  if (updatedProductId !== previousProductId) {
    const medicationToReissue = itemsInCanceled.find((item) => item.productId === updatedProductId);

    if (medicationToReissue) {
      const reissuedMedication: MedicationPadItemType = {
        ...updatedMedicationPadItem,
        reissueStatus: {
          isReissued: true,
          productId: medicationPadItem?.productId,
          productName: medicationPadItem?.productName
        }
      };

      return {
        type: 'MOVE_BACK_TO_REISSUE',
        updatedCanceledItems: itemsInCanceled.filter((item) => item.productId !== updatedProductId),
        updatedReissuedItems: [...itemsInReissue, reissuedMedication],
        updatedMedicationPadItem: reissuedMedication
      };
    }

    const medicationToCancel = itemsInReissue.find(
      (item) => item.medicationPadItem?.productId === previousProductId && !item.reissueStatus?.isOutOfStock
    );
    if (medicationToCancel) {
      const isInactivePrescription =
        medicationToCancel.prescriptionStatus &&
        [MedicationPrescriptionStatus.Cancelled, MedicationPrescriptionStatus.Expired].includes(
          medicationToCancel.prescriptionStatus
        );

      if (ffV4PrescriptionAssistantCancelPrescribedMedication && isInactivePrescription) {
        const updatedItemsToReissue = itemsInReissue.filter(
          (item) => item.medicationPadItem?.productId !== previousProductId
        );
        return {
          type: 'UPDATE_REISSUE',
          updatedItemsToReissue
        };
      }

      return {
        type: 'MOVE_TO_CANCEL',
        itemToCancel: {
          productId: Number(medicationToCancel.medicationPadItem?.productId),
          displayName: getProductDisplayName(
            medicationToCancel.medicationPadItem?.productName,
            medicationToCancel.medicationPadItem?.description,
            medicationToCancel.medicationPadItem?.supplierName ?? ''
          )
        }
      };
    }
  }

  return { type: 'NO_ACTION' }; // Default case
};
