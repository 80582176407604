import { z } from 'zod';

export type RecommendationInfo = {
  text: string;
  href: string;
};

export const PrescriptionPadItemDetail = z.object({
  productId: z.number(),
  productName: z.string(),
  supplierName: z.string().optional(),
  quantity: z.number().min(0).optional(),
  repeats: z.number().min(0).optional(),
  interval: z.number().min(0).optional(),
  dosage: z.string().optional(),
  vaporizer: z.boolean().optional()
});

export type PrescriptionPadItemDetailType = z.infer<typeof PrescriptionPadItemDetail>;

export enum PrescriptionItemAlertType {
  CANCEL = 'cancel',
  ERROR = 'error',
  OUT_OF_STOCK = 'isOutOfStock',
  DISCONTINUED = 'discontinued',
  INACTIVE = 'inactive',
  REISSUE = 'reissue'
}
