import { getConsultationTelecomDetailsClient } from '@/api/consultations/getConsultationTelecomDetailsClient';
import { QueryKey } from '@/data/query';

export const useGetConsultationTelecomDetails = (consultationId: number, enabled = false) => {
  const queryClient = getConsultationTelecomDetailsClient();
  const queryKey = [QueryKey.GetConsultationTelecomDetails, consultationId];
  const { data, isError, isLoading, refetch } = queryClient.getConsultationTelecomDetails.useQuery(
    queryKey,
    { params: { consultationId } },
    {
      enabled,
      queryKey,
      refetchOnMount: 'always'
    }
  );

  return { data, isError, isLoading, refetch };
};
