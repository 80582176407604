import { normalizeHeaders, requestHeaders } from '../../api';
import { pmsClient } from '../../api/pmsClient';

export const useSavePatientTelecomAttributes = (patientId: number, consultationId?: number) => {
  const queryClient = pmsClient();
  const { isPending, error, data, mutateAsync } = queryClient.savePatientTelecomAttributesContract.useMutation({
    mutationKey: ['savePatientTelecomAttributes']
  });

  const headers = normalizeHeaders(requestHeaders());

  return {
    isPending,
    error,
    data,
    mutateAsync: (contactId: string) =>
      mutateAsync({
        headers: {
          ...headers,
          authorization: headers['authorization']
        },
        params: {
          contactId
        },
        body: {
          data: {
            patientId,
            consultationId
          }
        }
      })
  };
};
