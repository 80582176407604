import { FF_ENABLE_PMS_PATIENT_DETAILS_V3, FF_IMMUTABLE_CLINIC_NOTES } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { LUXON_FORMAT_DAY_MONTH_YEAR, LUXON_FORMAT_HOUR_MINUTE_AM_PM } from '@/utils/luxon';
import type { PaginationModel } from '@montugroup/design-system';
import { Button, PaginationVariant, Table } from '@montugroup/design-system';
import { Block as CancelIcon } from '@mui/icons-material';
import { Box, Link, Stack, TextField, Tooltip, Typography, debounce, styled } from '@mui/material';
import type { CellContext, ColumnDef } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import type { ChangeEvent } from 'react';
import { useState } from 'react';

// TODO: Identify what the actual type of this is from PMS service, and move to MGS.
export type PatientNoteData = {
  CreatedBy: {
    email: string;
    first_name: string;
    last_name: string;
  };
  id: number;
  active?: boolean;
  created_by: number;
  created_date: string; // ISODate string
  modified_by: number;
  modified_date: string; // ISODate string
  heading: string;
  note?: string;
  patient_id: number;
  strike_reason?: string;
};

export type PatientNotesProps = {
  notes: PatientNoteData[];
  isAddNoteDisabled?: boolean;
  onViewNote: (selectedNote: PatientNoteData) => void;
  onAddNewNote: () => void;
  handleNotesPaginationChange?: ({ page, pageSize }: PaginationModel) => void;
  responseNotesCursors?: any;
};

const StyledSearchField = styled(TextField)({
  minWidth: '250px',
  marginBottom: 10
});

export const PatientNotes = ({
  notes,
  onViewNote,
  onAddNewNote,
  isAddNoteDisabled,
  handleNotesPaginationChange,
  responseNotesCursors
}: PatientNotesProps) => {
  const { flags } = useFeatureFlags();
  const ffImmutableNotesActive = flags[FF_IMMUTABLE_CLINIC_NOTES];
  const [searchFilterText, setSearchFilterText] = useState('');
  const ffIsPatientDetailsPageV3Enabled = flags[FF_ENABLE_PMS_PATIENT_DETAILS_V3];

  const columns: ColumnDef<PatientNoteData, any>[] = [
    {
      id: 'status',
      header: () => (
        <Tooltip title="Status">
          <Box>Status</Box>
        </Tooltip>
      ),
      cell: ({ row }) => (
        <Box>{ffImmutableNotesActive && row.original?.strike_reason ? <CancelIcon color="disabled" /> : '-'}</Box>
      ),
      enableSorting: false,
      meta: {
        sx: {
          width: '50px'
        }
      }
    },
    {
      accessorFn: ({ created_date }) => new Date(created_date),
      enableSorting: true,
      id: 'createdDate',
      meta: {
        sx: {
          width: '100px'
        }
      },
      header: () => (
        <Tooltip title="Created Date">
          <Box>Created</Box>
        </Tooltip>
      ),
      cell: ({ row, getValue }: CellContext<PatientNoteData, Date>) => (
        <Link
          {...(ffImmutableNotesActive &&
            Boolean(row.original?.strike_reason) && {
              color: 'action.disabled'
            })}
          sx={{
            cursor: 'pointer'
          }}
          data-testid={`viewNote-${row.original.id}`}
          onClick={() => onViewNote(row.original)}
        >
          <Typography variant="body2" component="span">
            {DateTime.fromJSDate(getValue()).toFormat(LUXON_FORMAT_DAY_MONTH_YEAR)}
          </Typography>
          <Typography variant="body2" component="span">
            {DateTime.fromJSDate(getValue()).toFormat(LUXON_FORMAT_HOUR_MINUTE_AM_PM)}
          </Typography>
        </Link>
      )
    },
    {
      accessorKey: 'heading',
      id: 'noteHeader',
      enableSorting: true,
      header: () => (
        <Tooltip title="Note Header">
          <Box>Note Header</Box>
        </Tooltip>
      )
    },
    {
      id: 'createdBy',
      enableSorting: true,
      accessorFn: ({ CreatedBy }) => `${CreatedBy.first_name} ${CreatedBy.last_name}`,
      header: () => (
        <Tooltip title="Created By">
          <Box>Created By</Box>
        </Tooltip>
      )
    }
  ];

  const handleSearchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFilterText(event.target.value);
  };

  return (
    <Stack>
      <Stack
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        paddingBottom={3}
      >
        <StyledSearchField label="Search" variant="outlined" onChange={debounce(handleSearchOnChange, 1000)} />
        <Button data-testid="addNote" onClick={() => onAddNewNote?.()} color="secondary" disabled={isAddNoteDisabled}>
          Add Note
        </Button>
      </Stack>
      <Box
        component={Table}
        columns={columns}
        data={notes}
        hasRowBackgroundColor={false}
        showPagination={true}
        pageSize={5}
        pageSizeOptions={[5, 10, 25, 50]}
        globalFilter={searchFilterText}
        sx={{
          width: '100%'
        }}
        applyRowStyles={(row: { original: PatientNoteData }) => {
          return ffImmutableNotesActive && (row.original as PatientNoteData).strike_reason
            ? {
                td: {
                  color: 'action.disabled'
                }
              }
            : {};
        }}
        {...(ffIsPatientDetailsPageV3Enabled && {
          onPaginationModelChange: handleNotesPaginationChange,
          paginationVariant: PaginationVariant.UNKNOWN_PAGE_SIZE,
          hasPrevPage: Boolean(responseNotesCursors?.prev),
          hasNextPage: Boolean(responseNotesCursors?.next)
        })}
      />
    </Stack>
  );
};
