import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/styles';

const SkeletonHeading = styled(Skeleton)({
  height: '2rem',
  width: 150,
  marginBottom: '0.5rem'
});

const SkeletonTextField = styled(Skeleton)({
  height: '3.5rem',
  marginBottom: '0.5rem'
});

export default function PaymentFormSkeleton() {
  return (
    <>
      <SkeletonHeading />
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box display="flex" flexDirection="column" justifyContent="space-between" mb={8}>
          <SkeletonTextField variant="rectangular" />
          <SkeletonTextField variant="rectangular" />
        </Box>
        <SkeletonHeading />
        <SkeletonTextField variant="rectangular" />

        <Box display="flex" justifyContent="space-between">
          <SkeletonTextField variant="rectangular" sx={{ flexBasis: '45%' }} />
          <SkeletonTextField variant="rectangular" sx={{ flexBasis: '45%' }} />
        </Box>

        <SkeletonTextField variant="rectangular" />
      </Box>
    </>
  );
}
