import type { Category, DropdownOption, InputField, SupportTasksProps } from '@/types/support-self-service.types';
import { DateTime } from 'luxon';

export const buildIdFromEndpoint = (endpoint: SupportTasksProps['endpoint']) => {
  // Remove leading and trailing slashes
  let sanitized = endpoint.replace(/^\/+|\/+$/g, '');

  // Replace invalid characters with hyphens
  sanitized = sanitized.replace(/[^a-zA-Z0-9\-_:.]/g, '-');

  // Ensure the ID starts with a letter
  if (!sanitized.match(/^[a-zA-Z]/)) {
    sanitized = `id-${sanitized}`;
  }

  return sanitized;
};

type TaskFormValue = string | string[] | number | boolean;

export const buildFormDataRequestBody = (requestBody: Record<string, TaskFormValue>, file?: File | null) => {
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    formData.append(key, value.toString());
  });

  if (file) {
    formData.append('file', file);
  }

  return formData;
};

export const buildJsonRequestBody = (
  inputs: Record<string, string>,
  inputTextBoxes?: InputField[],
  dropdowns?: DropdownOption[]
) =>
  Object.keys(inputs).reduce(
    (acc, key) => {
      const field = inputTextBoxes?.find((el) => el.property === key) ?? dropdowns?.find((el) => el.property === key);
      let value: TaskFormValue = inputs[key];

      switch (field?.type) {
        case 'file': {
          return acc;
        }
        case 'string': {
          value = value.trim();
          break;
        }
        case 'number': {
          value = parseInt(value, 10);
          break;
        }
        case 'boolean': {
          value = JSON.parse(value);
          break;
        }
        case 'array': {
          value = value.split(',').map((val) => val.trim());
          break;
        }
        case 'date': {
          value = DateTime.fromISO(value).toFormat('dd-MM-yyyy');
          break;
        }
        default: {
          break;
        }
      }

      acc[key] = value;
      return acc;
    },
    {} as Record<string, TaskFormValue>
  );

export const groupTasksByCategory = (supportTasks: SupportTasksProps[]) =>
  supportTasks.reduce(
    (groups, task) => {
      if (!groups[task.category]) {
        groups[task.category] = [];
      }

      groups[task.category].push(task);
      return groups;
    },
    {} as Record<Category, SupportTasksProps[]>
  );
