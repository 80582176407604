import theme from '@/theme';
import { CheckCircle, Delete, UploadFile } from '@mui/icons-material';
import UploadIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Card,
  Divider,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  alpha
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import type { FieldError } from 'react-hook-form';

const ZoneContainer = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: `${theme.shape.borderRadius}px`,
  padding: theme.spacing(6, 24),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:hover': {
    background: alpha(theme.palette.secondary.main, 0.1),
    border: `1px dashed ${theme.palette.secondary.main}`
  }
}));

const Icon = styled(UploadIcon)(({ theme }) => ({
  color: theme.palette.secondary.main
}));

const MainTextContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const ClickMeText = styled(Link)({
  cursor: 'pointer'
});

const FileTypeTextContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1)
}));

export type ConcessionCardFile = File & {
  identifier?: string;
};

export type ConcessionCardUploadProps = {
  loading?: boolean;
  files?: ConcessionCardFile[];
  onDropAccepted: (files: File[]) => Promise<void> | void;
  onDelete?: (file: ConcessionCardFile) => void;
  uploadError: FieldError | undefined;
};

export const ConcessionCardUpload = ({
  loading,
  files,
  onDropAccepted,
  onDelete,
  uploadError
}: ConcessionCardUploadProps) => {
  const [showRejections, setShowRejections] = useState(false);
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) {
        return;
      }
      await onDropAccepted(acceptedFiles);
    },
    [onDropAccepted]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    disabled: loading,
    onDrop,
    maxFiles: 1,
    maxSize: 26214400, // 25MB
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
      'image/heic': ['.heic']
    }
  });
  const convertBytes = (bytes: number) => {
    if (bytes < 1024) {
      return bytes + ' B';
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    }
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  };

  const renderPreviews = () => {
    return (
      <Box mb={1}>
        <Card variant="outlined">
          <List dense>
            {files &&
              files.map((file, index, arr) => (
                <Fragment key={file.name}>
                  <ListItem alignItems="center">
                    <ListItemAvatar sx={{ minWidth: theme.spacing(9), marginTop: 0 }}>
                      <UploadFile color="secondary" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={file.name}
                      secondary={
                        file.identifier
                          ? 'Uploaded'
                          : loading
                            ? 'Uploading'
                            : `${convertBytes(file.size)} . Ready to upload`
                      }
                    />

                    <ListItemAvatar
                      sx={{
                        marginLeft: theme.spacing(6),
                        display: 'flex',
                        gap: theme.spacing(2),
                        justifyContent: 'end'
                      }}
                    >
                      <Delete color="action" sx={{ cursor: 'pointer' }} onClick={() => onDelete && onDelete(file)} />
                      {file.identifier && <CheckCircle color="success" />}
                    </ListItemAvatar>
                  </ListItem>
                  {index + 1 !== arr.length && <Divider component="li" />}
                </Fragment>
              ))}
          </List>
        </Card>
      </Box>
    );
  };

  const renderRejections = () => {
    return (
      <Box mb={1}>
        <Card variant="outlined">
          <List dense>
            {fileRejections.map(({ file, errors }) => {
              return (
                <Fragment key={file.name}>
                  <ListItem alignItems="center">
                    <ListItemAvatar sx={{ minWidth: theme.spacing(9), marginTop: 0 }}>
                      <UploadFile color="error" />
                    </ListItemAvatar>
                    <ListItemText
                      primary="Upload failed."
                      secondary={
                        errors
                          .map((e) => (e.code === 'file-too-large' ? 'File too large' : 'Unsupported file'))
                          .join(', ') + ' . Failed'
                      }
                      sx={{
                        color: theme.palette.error.main,
                        '& .MuiListItemText-secondary': {
                          color: theme.palette.error.main
                        }
                      }}
                    />
                    <ListItemAvatar
                      sx={{
                        marginLeft: theme.spacing(6),
                        display: 'flex',
                        gap: theme.spacing(2),
                        justifyContent: 'end'
                      }}
                    >
                      <Delete color="action" sx={{ cursor: 'pointer' }} onClick={() => setShowRejections(false)} />
                    </ListItemAvatar>
                  </ListItem>
                </Fragment>
              );
            })}
          </List>
        </Card>
      </Box>
    );
  };

  useEffect(() => {
    if (fileRejections.length > 0) {
      setShowRejections(true);
    }
  }, [fileRejections]);

  return (
    <Box>
      {showRejections ? (
        renderRejections()
      ) : files && files.length > 0 ? (
        renderPreviews()
      ) : (
        <ZoneContainer
          component="div"
          {...getRootProps()}
          sx={{
            border: uploadError?.message
              ? `1px solid ${theme.palette.error.main}`
              : `1px dashed ${theme.palette.divider}`
          }}
        >
          <input {...getInputProps()} />
          <Icon
            sx={{
              color: uploadError ? theme.palette.error.main : theme.palette.secondary.main
            }}
          />
          <MainTextContainer>
            {loading ? (
              <Typography variant="body1">Uploading...</Typography>
            ) : isDragActive ? (
              <Typography variant="body1">Drop the files here...</Typography>
            ) : (
              <Typography variant="body1">
                <ClickMeText underline="always" color="secondary" fontWeight={500}>
                  Click to upload
                </ClickMeText>{' '}
                or drag and drop
              </Typography>
            )}
          </MainTextContainer>
          <FileTypeTextContainer>
            {uploadError && uploadError?.message ? (
              <Typography variant="body2" color="error">
                {uploadError?.message}
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary">
                PNG, JPG, PDF, or HEIC (max 25MB)
              </Typography>
            )}
          </FileTypeTextContainer>
        </ZoneContainer>
      )}
    </Box>
  );
};

export default ConcessionCardUpload;
