import { patientConsultationsClient } from '@/api/patients/patientConsultationsClient';
import { QueryKey } from '@/data/query';

interface PatientConsultationsPageParams {
  size: number;
  after?: string;
  before?: string;
}
const queryClient = patientConsultationsClient();

export const useGetPatientConsultations = (
  pageParams: PatientConsultationsPageParams,
  patientId: number | undefined
) => {
  const queryResult = queryClient.findPatientConsultations.useQuery(
    [QueryKey.GetPatientConsultations, pageParams, patientId],
    {
      params: { id: patientId as number },
      query: { page: pageParams }
    },
    {
      queryKey: [QueryKey.GetPatientConsultations, pageParams, patientId],
      enabled: Boolean(patientId)
    }
  );
  return {
    data: queryResult.data?.body ?? null,
    isError: queryResult.isError,
    isLoading: queryResult.isLoading
  };
};
