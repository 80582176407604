import type { AlertColor } from '@mui/material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  Link,
  Paper,
  Typography,
  useTheme
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';

import settings from '@/data/constants';
import type { SupportTasksProps } from '@/types/support-self-service.types';
import { buildFormDataRequestBody, buildIdFromEndpoint, buildJsonRequestBody } from '@/utils/supportTasks';

import { viteAppBaseUrl } from '@/constants/env';
import { getToken } from '@/data/service/authService';
import { SupportTaskDatePicker } from './components/SupportTaskDatePicker';
import SupportTaskDropdown from './components/SupportTaskDropdown';
import SupportTaskTextField from './components/SupportTaskTextField';
import SupportTaskUploadCsvButton from './components/SupportTaskUploadCsvButton';

interface SupportTaskVisualProps {
  highlight?: boolean;
}

const SupportTask = ({
  title,
  description,
  inputTextFields,
  dropdowns,
  uploadCSV,
  endpoint,
  requestType,
  highlight,
  wikiPage
}: SupportTasksProps & SupportTaskVisualProps) => {
  const [inputs, setInputs] = useState(() => {
    const initialState: Record<string, string> = {};
    inputTextFields?.forEach((box) => {
      initialState[box.property] = '';
    });
    dropdowns?.forEach((dropdown) => {
      initialState[dropdown.property] = dropdown.options[0]?.value || '';
    });
    return initialState;
  });
  const [file, setFile] = useState<File | null>(null);
  const [alert, setAlert] = useState<{
    show: boolean;
    severity: AlertColor | undefined;
    msg: string;
  }>({
    show: false,
    severity: 'success',
    msg: ''
  });
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();

  const handleInputChange = (property: string, value: string | Date | null) => {
    const formatValue = value instanceof Date ? value.toISOString() : value || '';
    setInputs((prev) => ({ ...prev, [property]: formatValue }));
    setAlert({ ...alert, show: false });
  };

  const handleSubmit = async () => {
    const requiredInputs = inputTextFields?.filter((field) => !field.optional).map((field) => field.property) ?? [];
    if (requiredInputs.some((property) => !inputs[property])) {
      setAlert({ ...alert, show: true, severity: 'error', msg: 'Please fill all the required fields.' });
      return;
    }

    setLoading(true);

    try {
      const isXapiEndpoint = endpoint.includes('/api/');
      const url = isXapiEndpoint ? endpoint : `${settings.url}${endpoint}`;
      const requestData = buildJsonRequestBody(inputs, inputTextFields, dropdowns);
      const requestBody = isXapiEndpoint ? { data: requestData } : requestData;
      const data = file ? buildFormDataRequestBody(requestData, file) : requestBody;
      const contentTypeHeader = file ? 'multipart/form-data' : 'application/json';

      const res = await axios({
        method: requestType,
        baseURL: viteAppBaseUrl(),
        url,
        data,
        headers: { 'Content-Type': contentTypeHeader, Authorization: `Bearer ${getToken()}` }
      });
      // temporarily showing the raw response until we have better support
      setAlert({ show: true, severity: 'success', msg: JSON.stringify(res.data, null, 2) });
    } catch (error) {
      // bad type, not even sure it works in all cases
      // mostly just interested in fixing this during our own testing phase on prod before we release to the wider montu org
      const e = error as unknown as { message: string };
      setAlert({ show: true, severity: 'error', msg: e?.message || 'Unknown error' });
    } finally {
      setLoading(false);
    }
  };

  const id = buildIdFromEndpoint(endpoint);

  return (
    <Paper
      key={id}
      id={id}
      sx={{
        p: 4,
        transition: 'box-shadow 0.3s ease-in-out',
        boxShadow: highlight ? theme.shadows[8] : theme.shadows[2]
      }}
      elevation={highlight ? 8 : 2}
    >
      <Typography variant="h5" component="h2" gutterBottom>
        {title}
      </Typography>

      {description && (
        <Typography variant="body1" gutterBottom>
          {description}
        </Typography>
      )}

      {wikiPage && (
        <Typography variant="body1" gutterBottom>
          <Link component="a" color="secondary" underline="hover" href={wikiPage} target="_blank">
            {`${title} Documentation`}
          </Link>
        </Typography>
      )}

      {alert.show && (
        <Fade in={alert.show} timeout={{ enter: 1000, exit: 1000 }}>
          <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, show: false })} sx={{ mt: 4 }}>
            <AlertTitle>{alert.severity?.toUpperCase()}</AlertTitle>
            <Box
              sx={{
                whiteSpace: 'pre-wrap'
              }}
            >
              {alert.msg}
            </Box>
          </Alert>
        </Fade>
      )}

      <Grid container spacing={4} sx={{ mt: 2 }}>
        {inputTextFields?.map((field) => (
          <Grid item xs={12} key={field.property}>
            {field.type === 'date' ? (
              <SupportTaskDatePicker
                field={field}
                value={inputs[field.property]}
                handleInputChange={handleInputChange}
              />
            ) : (
              <SupportTaskTextField
                field={field}
                value={inputs[field.property]}
                handleInputChange={handleInputChange}
              />
            )}
          </Grid>
        ))}

        {dropdowns?.map((dropdown) => (
          <Grid item xs={12} key={dropdown.property}>
            <SupportTaskDropdown
              dropdown={dropdown}
              value={inputs[dropdown.property]}
              handleInputChange={handleInputChange}
            />
          </Grid>
        ))}

        {uploadCSV && (
          <Grid item xs={12}>
            <SupportTaskUploadCsvButton file={file} setFile={setFile} />
          </Grid>
        )}
      </Grid>

      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={handleSubmit}
        disabled={loading}
        sx={{ my: 4 }}
      >
        {loading ? <CircularProgress size={24} /> : 'Submit'}
      </Button>
    </Paper>
  );
};

export default SupportTask;
