import { Box } from '@mui/material';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { ScrollProvider } from '@/context/scrollContext';
import type { UserRole } from '@/types';

import { getUser, isAuthenticated } from '@/data/service/authService';
import { useFeatureFlags, useIdentifyUserForLaunchDarkly } from '@/hooks';
import type { RouteType } from '@/routes';
import { routes } from '@/routes';

import PatientDetailsV3 from '@/pages/Patient';

import { FF_ENABLE_PMS_PATIENT_DETAILS_V3 } from '@/constants/featureFlags';
import AuthenticatedSnowplow from '../providers/authenticated-snowplow';
import { CreatePrescriptionTutorialModal } from './PatientPageV2/components/CreatePrescriptionTutorialModal/CreatePrescriptionTutorialModal';
import { useShowInventoryInsightsTutorial } from './PatientPageV2/hooks/useShowInventoryInsightsTutorial';
import NavbarV2 from './header/NavBarV2';

type AppRouteType = {
  isPrivate: boolean;
  restrictAccess: UserRole[];
};

type AppRoutesProps = {
  appRoutes?: RouteType[];
};

const AppRoute = ({ isPrivate, restrictAccess }: AppRouteType) => {
  const isAuthorized = isAuthenticated();
  const user = getUser();
  const shouldRestrictAccess = isAuthorized && restrictAccess.includes(user.role_id);

  if (shouldRestrictAccess) {
    return <Navigate to="/" />;
  }

  return isPrivate && !isAuthorized ? <Navigate to="/" /> : <Outlet />;
};

export const AppRoutesWithoutRouter = ({ appRoutes = routes }: AppRoutesProps) => {
  const { flags } = useFeatureFlags();
  const isPatientDetailsPageV3Enabled = flags[FF_ENABLE_PMS_PATIENT_DETAILS_V3];

  useIdentifyUserForLaunchDarkly();

  const renderRouteComponent = (Component: React.FunctionComponent) => (
    <ScrollProvider>
      <Component />
    </ScrollProvider>
  );

  return (
    <Routes>
      {appRoutes.map(({ path, component, navBar, isPrivate, restrictAccess = [] }) => {
        // TODO: remove this when the new patient details page is implemented
        const page = path === '/patients/:id' && isPatientDetailsPageV3Enabled ? PatientDetailsV3 : component;
        return (
          <Route key={path} path="/" element={<AppRoute isPrivate={isPrivate} restrictAccess={restrictAccess} />}>
            <Route
              path={path}
              element={
                <Box>
                  {navBar ? (
                    <Box display={'flex'}>
                      <NavbarV2 />
                      {renderRouteComponent(page)}
                    </Box>
                  ) : (
                    renderRouteComponent(page)
                  )}
                </Box>
              }
            />
          </Route>
        );
      })}
    </Routes>
  );
};

const AppRoutes = () => {
  const { openTutorialModal, handleWatchLaterAndDismissModal, handleWatchNow } = useShowInventoryInsightsTutorial();

  return (
    <BrowserRouter>
      <AuthenticatedSnowplow>
        <AppRoutesWithoutRouter />
        <CreatePrescriptionTutorialModal
          open={openTutorialModal}
          handleClose={handleWatchLaterAndDismissModal}
          handleClickPrimaryButton={handleWatchNow}
          handleClickSecondaryButton={handleWatchLaterAndDismissModal}
        />
      </AuthenticatedSnowplow>
    </BrowserRouter>
  );
};

export default AppRoutes;
