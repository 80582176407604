import { getCalApi } from '@calcom/embed-react';
import { Button } from '@montugroup/design-system';
import { Link } from '@mui/material';
import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';

import { FF_CALCOM_EMBED_PARAMS } from '@/constants/featureFlags';
import settings from '@/data/constants';
import { getAuthData } from '@/data/service/authService';
import { useFeatureFlags } from '@/hooks';
import { generateCalcomRescheduledByUrlParams } from '@/utils/calendar';

interface PrefilledConfig {
  name?: string;
  email?: string;
  phoneNumber?: string;
}

export type CalendarPopupButtonProps = PropsWithChildren<{
  link: string;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  'data-testid'?: string;
  formData?: PrefilledConfig;
  isButton?: boolean;
  onOpen?: () => void;
  disabled?: boolean;
  onBookingSuccessful?: () => void;
  onRescheduleBookingSuccessful?: () => void;
  onBookingCancelSuccessful?: () => void;
}>;

export default function CalendarPopupButton({
  children,
  link,
  formData,
  isButton,
  onOpen,
  disabled,
  onBookingSuccessful,
  onRescheduleBookingSuccessful,
  onBookingCancelSuccessful,
  ...rest
}: CalendarPopupButtonProps) {
  const { user } = getAuthData();
  const { flags } = useFeatureFlags();
  const ffUseCalcomRescheduledByParams = flags[FF_CALCOM_EMBED_PARAMS];
  const userAssignedToUrlParamsLink = generateCalcomRescheduledByUrlParams(link, user?.id);

  useEffect(() => {
    (async function initCalendar() {
      const cal = await getCalApi(settings.calendar.embedJsUrl);
      cal('ui', { hideEventTypeDetails: false, layout: 'month_view' });

      if (typeof onOpen === 'function') {
        cal('on', { action: 'linkReady', callback: onOpen });
      }
      if (typeof onBookingSuccessful === 'function') {
        cal('on', { action: 'bookingSuccessful', callback: onBookingSuccessful });
      }
      if (typeof onRescheduleBookingSuccessful === 'function') {
        cal('on', { action: 'rescheduleBookingSuccessful', callback: onRescheduleBookingSuccessful });
      }
      if (typeof onBookingCancelSuccessful === 'function') {
        cal('on', { action: 'bookingCancelled', callback: onBookingCancelSuccessful });
      }
    })();
  }, [onOpen, onBookingSuccessful, onRescheduleBookingSuccessful, onBookingCancelSuccessful]);

  const dataTestId = rest?.['data-testid'] ?? 'calendar-popup-trigger';
  const commonProps = {
    'data-cal-config': JSON.stringify(formData ?? {}),
    'data-cal-link': ffUseCalcomRescheduledByParams ? userAssignedToUrlParamsLink : link,
    'data-cal-origin': settings.calendar.calOrigin,
    'data-testid': dataTestId
  };

  const PopupButton = (
    <Button variant="text" disabled={disabled} color={'secondary'} {...commonProps} {...rest}>
      {children}
    </Button>
  );

  const PopupLink = (
    <Link
      underline="none"
      color={disabled ? 'action.disabled' : 'secondary'}
      {...(disabled ? { 'data-testid': dataTestId } : { ...commonProps })}
    >
      {children}
    </Link>
  );

  return isButton ? PopupButton : PopupLink;
}
