import { jwtDecode } from 'jwt-decode';
import { DateTime } from 'luxon';

import { e2eTestsSkipToken } from '@/constants/env';
import type { AuthTokenType, User } from '@/types';
import { UserRole } from '@/types';

const LOCAL_USER = localStorage.getItem('user') ?? null;
const user = LOCAL_USER ? JSON.parse(LOCAL_USER) : null;

export const isAuthenticated = () => {
  const access = localStorage.getItem('token') ?? null;
  const decoded: AuthTokenType | null = access ? jwtDecode(access) : null;
  // add this GHA variable for the e2e tests to skip token check
  const overrideJWTToken = e2eTestsSkipToken() === 'true';

  // TODO need to find better way to update the token expiry
  if (overrideJWTToken) {
    return !!access;
  }

  return decoded && decoded.exp ? DateTime.fromMillis(decoded.exp * 1000) > DateTime.now() : false;
};

export const getUser = () => (isAuthenticated() && user ? user : null);

export const getToken = () => (isAuthenticated() ? localStorage.getItem('token') : null);

export const isAdmin = () =>
  isAuthenticated()
    ? [UserRole.SuperAdmin, UserRole.Admin, UserRole.PtxSupportPartner].includes(getUser().role_id)
    : false;

export const isSuperAdmin = () => (isAuthenticated() ? getUser().role_id === UserRole.SuperAdmin : false);

export const isNurse = () => (isAuthenticated() ? getUser().role_id === UserRole.Nurse : false);

export const isDoctor = () => (isAuthenticated() ? getUser().role_id === UserRole.Doctor : false);

export const isAdminOnly = () => (isAuthenticated() ? getUser().role_id === UserRole.Admin : false);

export const isManager = () => (isAuthenticated() ? getUser().role_id === UserRole.Manager : false);

export const isPtxSupportPartner = () => (isAuthenticated() ? getUser().role_id === UserRole.PtxSupportPartner : false);

export const getAuthData = () => {
  if (isAuthenticated()) {
    return {
      user,
      isAdmin: isAdmin(),
      token: localStorage.getItem('token'),
      isNurse: isNurse(),
      isDoc: isDoctor(),
      isSuperAdmin: isSuperAdmin(),
      isAdminOnly: isAdminOnly(),
      isManager: isManager(),
      isPtxSupportPartner: isPtxSupportPartner()
    };
  }
  return { user: null, isAdmin: false, token: null, isNurse: false, isDoc: false };
};

export const login = (payload: { user: User; token: string }) => {
  localStorage.setItem('user', JSON.stringify(payload.user));
  localStorage.setItem('token', payload.token);
  return payload.user;
};

export const logout = () => {
  window.location.href = '/?expired';
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};
