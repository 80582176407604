import { PaginationVariant, Table } from '@montugroup/design-system';
import { Box, styled } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';

import { useAccessControl, useFeatureFlags } from '@/hooks';
import { ConsultationTableColumnHeaders, UserRole } from '@/types';

import { FF_ENABLE_PMS_PATIENT_DETAILS_V3 } from '@/constants/featureFlags';
import type { ConsultationWithPaymentStatus } from './hooks/useConsultationTabOverviewTable';

// TODO: Remove this once the column header minWidth is removed from the <Table> in upcoming refactor
const StyledBox = styled(Box)({
  'thead > tr > th': {
    minWidth: 'inherit'
  }
});

type ConsultationTabOverviewTableProps = {
  consultations: ConsultationWithPaymentStatus[];
  isLoading: boolean;
  searchFilterText: string;
  responseCursors: any;
  handlePaginationChange: () => void;
};

/**
 * ConsultationTabOverviewTable component
 *
 * @remarks
 * Renders a table of consultations in the Consultation Tab on the Patient page
 *
 * @param consultations - An array of consultations
 * @param isLoading - isLoading state from the Patient profile
 * @param searchFilterText - The search filter text
 *
 * @returns JSXElement
 *
 */

export const ConsultationTabOverviewTable = ({
  consultations,
  isLoading,
  searchFilterText,
  handlePaginationChange,
  responseCursors
}: ConsultationTabOverviewTableProps) => {
  const columnHelper = createColumnHelper();
  const { restrictAccess } = useAccessControl();

  const isLoadingConsultations = isLoading || !consultations.length;

  const columns: any = [
    columnHelper.accessor('view_link', {
      header: ConsultationTableColumnHeaders.ViewLink,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('id', {
      header: ConsultationTableColumnHeaders.ConsultID,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('appointment_time', {
      header: ConsultationTableColumnHeaders.AppointmentTime,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('type', {
      header: ConsultationTableColumnHeaders.ConsultationType,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('practitioner', {
      header: ConsultationTableColumnHeaders.Practitioner,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('payment_status', {
      header: ConsultationTableColumnHeaders.PaymentStatus,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('consultation_status', {
      header: ConsultationTableColumnHeaders.ConsultationStatus,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('payment_type', {
      header: ConsultationTableColumnHeaders.PaymentType,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('reschedule_link', {
      header: ConsultationTableColumnHeaders.RescheduleLink,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    }),
    columnHelper.accessor('cancel_link', {
      header: ConsultationTableColumnHeaders.CancelLink,
      cell: (cell) => {
        return cell.getValue() || '-';
      }
    })
  ];

  const rescheduleConsultationAccessId = restrictAccess([UserRole.Doctor], 'Reschedule');
  const cancelConsultationAccessId = restrictAccess([UserRole.Doctor], 'Cancel');
  const editConsultationAccessId = restrictAccess([UserRole.Admin], 'View');
  const { flags } = useFeatureFlags();
  const ffIsPatientDetailsPageV3Enabled = flags[FF_ENABLE_PMS_PATIENT_DETAILS_V3];

  return (
    <StyledBox>
      <Table
        data={consultations}
        columns={columns}
        isLoading={isLoadingConsultations}
        skeletonCount={2}
        pageSize={ffIsPatientDetailsPageV3Enabled ? 10 : 5}
        pageSizeOptions={ffIsPatientDetailsPageV3Enabled ? undefined : [5, 10, 20, 50]}
        globalFilter={searchFilterText}
        noDataFoundMessage="No consultations found"
        showPagination
        onPaginationModelChange={handlePaginationChange}
        {...(ffIsPatientDetailsPageV3Enabled && {
          paginationVariant: PaginationVariant.UNKNOWN_PAGE_SIZE,
          hasPrevPage: Boolean(responseCursors?.prev),
          hasNextPage: Boolean(responseCursors?.next)
        })}
        columnVisibility={{
          view_link: !!editConsultationAccessId,
          reschedule_link: !!rescheduleConsultationAccessId,
          cancel_link: !!cancelConsultationAccessId
        }}
      />
    </StyledBox>
  );
};
