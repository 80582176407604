import { useMedicationRequiresSuitableDevice } from '@/state-management/hooks/useMedicationRequiresSuitableDevice';
import { datadogRum } from '@datadog/browser-rum';
import { Button, ConfirmDialog, toast } from '@montugroup/design-system';
import { Alert, AlertTitle, CircularProgress, debounce, styled, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';

import {
  PrescriptionPadStep,
  PrescriptionPadWrapper,
  transformPrescriptionsPayload
} from '@/components/PatientPageV2/components/PrescriptionPad';
import { WaNotificationBanner } from '@/components/PatientPageV2/components/WaNotificationBanner/WaNotificationBanner';
import { ConsultationTabOverviewTable } from '@/components/PatientPageV2/ConsultationTabOverviewTable';
import { useConsultationTabOverviewTable } from '@/components/PatientPageV2/hooks/useConsultationTabOverviewTable';
import {
  FF_BOOK_ANY_DOCTOR_IF_CURRENT_DOCTOR_INACTIVE,
  FF_CONSULTATION_CHARGES_UPLIFT,
  FF_CONSULTATION_COMPLETION_PROMPT,
  FF_NURSE_INSTANT_CONSULTATION,
  FF_PAT_PRESCRIBING_SUITABLE_DEVICE_MODAL,
  FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION,
  FF_WA_NOTIFICATION_OF_TREATMENT
} from '@/constants/featureFlags';
import settings, { ineligibleStatuses } from '@/data/constants';
import { useFeatureFlags } from '@/hooks';
import { useGetConsultationStatus } from '@/hooks/rest/useGetConsultationStatus';
import FullScreenLoader from '@/shared-ui/FullScreenLoader/FullScreenLoader';
import { SectionBody } from '@/shared-ui/PageElements/SectionBody';
import { SectionHeader } from '@/shared-ui/PageElements/SectionHeader';

import { listSorter } from '@/assets/js/util';
import { getAuthData, getUser } from '@/data/service/authService';
import { makePOST } from '@/data/service/dataService';
import { useAppStore } from '@/state-management';
import useChargeConsult from '../../hooks/consult/useChargeConsult';
import useConsult from '../../hooks/consult/useConsult';
import usePrescriptionForm from '../../hooks/prescription/usePrescriptionForm';
import CheckBoxField, { CheckBoxSuffixField } from '../fields/CheckBoxField';
import DisplayField from '../fields/DisplayField';
import InputField from '../fields/InputField';
import SelectField from '../fields/SelectField';

import { useCreateInstantConsultation } from '@/hooks/consult/useCreateInstantConsultation';
import { useNotificationOfTreatmentPdf } from '@/hooks/document/useNotificationOfTreatmentPdf';
import { useSubmitCancelPrescribedMedications } from '@/hooks/prescribedProducts/useSubmitCancelPrescribedMedications';
import type { UsePostPaymentsChargeForConsultResponse } from '@/hooks/rest/usePostPaymentsChargeForConsult';
import type { ConsultationModel } from '@/types';
import { Logger } from '@montu-web/utilities';
import type { AxiosError } from 'axios';
import { useModal } from 'mui-modal-provider';
import { BookConsultationMenu } from './components/BookConsultationMenu/BookConsultationMenu';
import CancelPrescribedMedicationModal from './components/CancelPrescribedMedicationModal/CancelPrescribedMedicationModal';
import { ConsultationConfirmationButton } from './components/ConsultationConfirmationButton/ConsultationConfirmationButton';
import { ConsultationDetails } from './components/ConsultationTabSections/ConsultationDetails';
import { PatientNotes } from './components/ConsultationTabSections/PatientNotes';
import SingleConsultationNoteHistory from './components/ConsultationTabSections/SingleConsultationNotes/components/SingleConsultationNoteHistory';
import ViewPatientNote from './components/ConsultationTabSections/ViewPatientNote';
import NotificationOfTreatmentModal from './components/NotificationOfTreatmentModal/NotificationOfTreatmentModal';
import { NotificationOfTreatmentPDFViewer } from './components/NotificationOfTreatmentPDFViewer/NotificationOfTreatmentPDFViewer';
import { useConsultationTabState } from './hooks/useConsultationTabState';

const logger = new Logger('PMS:ConsultationTab');

const StyledSearchTextField = styled(TextField)({
  minWidth: '250px',
  marginBottom: 10
});

const StyledSelectField = styled(SelectField)({
  '.MuiSelect-select': {
    minWidth: '250px'
  }
});

export function getPaymentTypeText(consultation: ConsultationModel) {
  const isVoidedEwayPayment = !consultation?.xero_consultation_code && consultation?.payment_status === 'VOIDED';

  if (consultation?.payment_id || isVoidedEwayPayment) {
    return 'Credit Card (upfront)';
  }

  if (consultation?.paymentStatus?.preAuthTransactionExists) {
    return 'Credit Card';
  }

  return 'Invoice';
}

type ConsultationTabProps = {
  data?: any;
  dischargeProgressStatus?: any;
  fields?: any;
  doctor?: any;
  patientId?: any;
  onSave?: any;
  saveConsultation?: any;
  setShowConsultation?: any;
  setConsultationSubStatus: any;
  refresh?: any;
  patient?: any;
  onCreditCardHasBeenCharged?: any;
  onInvoiceHasBeenCreated?: any;
  doctorsList?: any;
  handleChange?: any;
  responseCursors?: any;
  handlePaginationChange?: any;
  handleDocNotes?: any;
  isLoadingConsultations?: any;
  showConsultation?: any;
  consultationSubStatus?: any;
  responseNotesCursors?: any;
  handleNotesPaginationChange?: any;
  refetchNotes?: any;
};

const ConsultationTab = (props: ConsultationTabProps) => {
  const { showModal } = useModal();
  const { flags } = useFeatureFlags();
  const ldClient = useLDClient();
  const isPatPrescribingSuitableDeviceModalEnabled = flags[FF_PAT_PRESCRIBING_SUITABLE_DEVICE_MODAL];
  const ffConfirmationCheckListEnabled = flags[FF_CONSULTATION_COMPLETION_PROMPT];
  const ffV4PrescriptionAssistantCancelPrescribedMedication =
    flags[FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION];
  const ffConsultationChargesUplift = flags[FF_CONSULTATION_CHARGES_UPLIFT];
  const isWaNotificationOfTreatment = flags[FF_WA_NOTIFICATION_OF_TREATMENT];
  const ffBookAnyDoctorIfDoctorIsDisabled = flags[FF_BOOK_ANY_DOCTOR_IF_CURRENT_DOCTOR_INACTIVE];
  const ffNurseInstantConsultationCreationEnabled = flags[FF_NURSE_INSTANT_CONSULTATION];
  const {
    tmpHelpers: { initialPrescriptionProductValues }
  } = usePrescriptionForm();
  const { showConsultation, consultationSubStatus, setConsultationSubStatus } = props;
  const consultationData =
    props.data && props.data.consultations ? listSorter(props.data.consultations, 'start_time') : [];

  const patientNoteData =
    props.data && props.data.patientNotes ? listSorter(props.data.patientNotes, 'created_date') : [];

  const initialCompletedNurseConsults = consultationData.filter(
    (consultation: { doctor_id: any; consult_type: string; status_id: number }) => {
      const isNotDoctorConsult = !consultation.doctor_id;
      const isInitialConsultation = consultation.consult_type === 'Initial';
      const consultationIsCompleted = consultation.status_id === settings.consultationStatus.completed;

      return isNotDoctorConsult && isInitialConsultation && consultationIsCompleted;
    }
  );

  const scheduledDoctorConsults = consultationData.filter((consultation: { doctor_id: any; status_id: number }) => {
    const isDoctorConsult = consultation.doctor_id;
    const isScheduled = consultation.status_id === settings.consultationStatus.scheduled;
    return isDoctorConsult && isScheduled;
  });

  const initialDoctorCompletedConsults = consultationData.filter(
    (consultation: { doctor_id: any; status_id: number }) => {
      const isDoctorConsult = consultation.doctor_id;
      const isCompleted = consultation.status_id === settings.consultationStatus.completed;
      return isDoctorConsult && isCompleted;
    }
  );

  const consultsWithDoctor = consultationData.filter((consultation: { doctor_id: any }) => consultation.doctor_id);

  const [loading, setLoader] = React.useState(false);

  const { isAdminOnly, isManager, isAdmin, isDoc } = getAuthData();
  const [selectedPatientNote, setSelectedPatientNote] = React.useState(null);
  const patientActive = props?.dischargeProgressStatus ? false : props.fields?.isActive;

  const { data, setData, submitPrescriptions, isPostingPrescription } = useConsultationTabState({
    doctorId: props?.doctor?.id,
    patientId: Number(props.patientId),
    saveConsultation: props.saveConsultation,
    showConsultation,
    setShowConsultation: props.setShowConsultation
  });
  const { pdfString, isLoading: isPdfLoading, error: pdfError, fetchPdf } = useNotificationOfTreatmentPdf();
  const { submitCancelPrescribedMedications } = useSubmitCancelPrescribedMedications();

  const { consultationStatuses } = useGetConsultationStatus(showConsultation);

  const user = getUser();
  const {
    prescriptionPad: { step: createScriptStep, medicationPadItems, actions: reviewAction },
    prescriptionHistory: { itemsToAction, itemsToCancel, itemsToReissue },
    waHealth: { isWaPatient }
  } = useAppStore.use.prescriptionAssistant();

  const updatePrescriptionPadStep = useAppStore.use.updatePrescriptionPadStep();
  const resetPrescriptionPad = useAppStore.use.resetPrescriptionPad();
  const resetPrescriptionHistory = useAppStore.use.resetPrescriptionHistory();
  const isValidPrescriptionPad = useAppStore.use.isValidPrescriptionPad()();
  const allowAnyDocBooking = ffBookAnyDoctorIfDoctorIsDisabled && props.doctor?.DoctorUser?.active === false;
  const validPrescriptionMedications = medicationPadItems.filter((item) => !!item.medicationPadItem);
  const enableBookAnyDoctorButton =
    !isDoc && initialCompletedNurseConsults.length && (!consultsWithDoctor.length || allowAnyDocBooking);
  const enableBookSpecificDoctorButton =
    !isDoc && initialCompletedNurseConsults.length && !scheduledDoctorConsults.length;
  const initialDoctorConsultCompleted =
    !isDoc && initialCompletedNurseConsults.length && initialDoctorCompletedConsults.length;
  const hasCompletedActions = !ffV4PrescriptionAssistantCancelPrescribedMedication ? true : itemsToAction.length === 0;

  const currentConsultPaymentDetails = showConsultation?.paymentStatus || {};
  const [loadingPaymentAction, setLoadingPaymentAction] = useState(false);

  const { paymentMethod: consultPaymentMethod, paymentState: consultPaymentState } = useConsult({
    paymentStatus: showConsultation?.payment_status,
    paymentDetails: currentConsultPaymentDetails,
    paidDate: showConsultation?.paid_date ?? '',
    paymentQueued: showConsultation?.payment_queued,
    invoiceCode: showConsultation?.xero_consultation_code
  });
  const isPaymentInProgressOrCompleted = consultPaymentState !== 'NOT_PAID';

  const {
    consultDiscountRate,
    handleChangeConsultDiscountRate,
    formattedConcessionConsulationPrice,
    doChargeConsult,
    doChargeConcession,
    discountInputProps,
    loadingChargeConsult,
    loadingChargeConcession,
    canUserChargeConsult,
    consultAndClinicianType,
    isComplimentary,
    setIsComplimentary
  } = useChargeConsult({
    consultationId: showConsultation?.id,
    consultationType: showConsultation?.consult_type,
    doctorId: showConsultation?.doctor_id,
    patientEmail: props.fields?.email,
    consultationPaymentMethod: consultPaymentMethod
  });

  const { consultationsOverviewTableData } = useConsultationTabOverviewTable({
    consultations: consultationData,
    patientEmail: props.fields?.email,
    setShowConsultation: props.setShowConsultation,
    setConsultationSubStatus: props.setConsultationSubStatus,
    handleRefetchPatientData: props.refresh
  });

  const [searchFilterText, setSearchFilterText] = useState('');

  const padIncludesMedsThatRequireSuitableDevice = useMedicationRequiresSuitableDevice();
  const [showSuitableDeviceModal, setShowSuitableDeviceModal] = useState(false);

  const handleSearchOnChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSearchFilterText(event.target.value);
  };

  const getConsultLoggingInfo = (extraProperties?: Record<string, string | number | boolean>) => ({
    isDoc,
    canShowChargeButton,
    patientId: data.patientId,
    consultationId: showConsultation?.id,
    consultationType: showConsultation?.consult_type,
    consultationCode: showConsultation?.consultation_code,
    statusId: showConsultation?.status_id,
    doctorId: showConsultation?.doctor_id,
    paymentQueued: showConsultation.payment_queued,
    paymentStatus: showConsultation.payment_status,
    xeroConsultationCode: showConsultation.xero_consultation_code,
    calcomConsultationUuid: showConsultation.calcom_consultation_uuid,
    isConcessionPatient: Boolean(props.patient?.hasValidConcessionCard),
    extraProperties
  });

  function getPaymentButtonText() {
    if (consultPaymentState === 'PAID') {
      return 'Paid - Resend Receipt';
    }
    if (consultPaymentState === 'IN_PROGRESS' && consultPaymentMethod === 'CREDIT_CARD') {
      return 'Payment requested';
    }
    if (consultPaymentState === 'IN_PROGRESS' && consultPaymentMethod === 'INVOICE') {
      return isDoc ? 'Resend Request' : 'Resend Invoice';
    }
    if (consultAndClinicianType === 'Follow-Up Doctor') {
      return 'Charge for Dr Follow up appt';
    }
    if (consultAndClinicianType === 'Initial - Nurse') {
      return ffConsultationChargesUplift ? 'Payment Request' : 'Charge for Nurse Initial appt';
    }
    return 'N/A';
  }

  const processChargeCreditCardResult = (result: { error?: any }) => {
    logger.info('processChargeCreditCardResult', getConsultLoggingInfo());
    const { error } = result;
    const isSuccess = !error;
    if (isSuccess) {
      toast.success('Credit card charge request added to queue');
      props.onCreditCardHasBeenCharged?.(showConsultation.id);
    } else {
      toast.error('Something went wrong charging credit card');
      logger.error('processChargeCreditCardResult', getConsultLoggingInfo());
    }
  };

  function processChargeInvoiceResult(result: { data: any; error: any }) {
    const { data: chargeData, error } = result;
    const isSuccess = !error;
    logger.info(
      'processChargeInvoiceResult',
      getConsultLoggingInfo({ isSuccess, invoiceCode: (chargeData || {}).xero_consultation_code })
    );

    if (isSuccess) {
      toast.success('User has been invoiced');
      const invoiceCode = (chargeData || {}).xero_consultation_code;

      if (invoiceCode) {
        props.onInvoiceHasBeenCreated?.(showConsultation.id, invoiceCode);
      }
    } else {
      logger.error(
        'processChargeInvoiceResult:: Could not generate invoice',
        getConsultLoggingInfo({ isSuccess, invoiceCode: (chargeData || {}).xero_consultation_code })
      );
      toast.error('Could not generate invoice');
    }
  }

  function processChargeResult(
    result:
      | { data: UsePostPaymentsChargeForConsultResponse | null; error: string | Error | AxiosError<unknown> | null }
      | { error: Error }
  ) {
    logger.info('processChargeResult', getConsultLoggingInfo({ consultAndClinicianType, consultPaymentMethod }));
    ldClient?.track('consultation-charge', {
      consultAndClinicianType
    });
    datadogRum.addAction('consultation-charge', {
      consultAndClinicianType
    });
    if (consultPaymentMethod === 'CREDIT_CARD') {
      processChargeCreditCardResult(result);
      return;
    }
    //@ts-expect-error: TS2345 - need to fix this type error
    processChargeInvoiceResult(result);
  }

  const loadingAnyPayment = loadingPaymentAction || loadingChargeConsult || loadingChargeConcession;

  const isNonEwayLegacyConsultation = !showConsultation?.payment_id;
  const isVoidedEwayConsultation =
    !showConsultation?.xero_consultation_code && showConsultation?.payment_status === 'VOIDED';

  // Disable the button when credit card payment is in progress since that is an automated payment system, however
  // enable for invoice in progress method to enable clinicians to resend invoices.
  const isPaymentButtonDisabled =
    loadingAnyPayment || (consultPaymentMethod === 'CREDIT_CARD' && consultPaymentState === 'IN_PROGRESS');

  // Always provide clinicians the option to charge at the concession rate, not just when the patient is flagged as
  // a concession holder in the system. This allows us to support scenarios whereby the clinician is still waiting for
  // the patient to email their card details to them. We don't want to disrupt the clinicians workflow over a singular
  // consultation discount. This will change in phase 2 of the concession feature.
  const statusIsNotPaid = consultPaymentState === 'NOT_PAID';
  const canShowChargeConcessionBtn = isNonEwayLegacyConsultation && statusIsNotPaid && !isVoidedEwayConsultation;
  const canShowDiscountRateInput = isNonEwayLegacyConsultation && statusIsNotPaid && !isVoidedEwayConsultation;

  const canShowChargeButton = canUserChargeConsult;

  const isSubmitButtonDisabled =
    !patientActive || isDoc || (consultPaymentMethod === 'INVOICE' && consultPaymentState === 'NOT_PAID');

  const handleConcessionPaymentClick = async () => {
    const result = await doChargeConcession();
    processChargeResult(result);
  };

  // TODO: Migrate into `useChargeConsult` hook
  const triggerInvoice = async () => {
    const sendBody = {
      consultation_code: showConsultation.consultation_code
    };
    //@ts-expect-error: TS2345 - need to fix this type error
    const response = await makePOST('consultation/mail/resendinvoice', sendBody, 'resendinvoice-ConsultationTab');
    //@ts-expect-error: TS2339 - need to fix this type error
    if (response?.data?.success) {
      toast.success('Invoice Email to Patient sent successfully');
    } else {
      toast.error('Email failed to send');
    }
    //@ts-expect-error: TS2339 - need to fix this type error
    logger.info('triggerInvoice', getConsultLoggingInfo({ wasSuccess: response?.data?.success }));
  };

  const paymentsButtonAction = async () => {
    logger.info(
      'paymentsButtonAction',
      getConsultLoggingInfo({
        consultPaymentState
      })
    );
    setLoadingPaymentAction(true);
    if (consultPaymentState === 'NOT_PAID') {
      const result = await doChargeConsult();
      processChargeResult(result);
    } else {
      // Assumes that this function will either resend an invoice or resend a receipt for credit cards
      await triggerInvoice();
    }
    setLoadingPaymentAction(false);
  };

  const makeSaveScriptAndConsultationRequest = async () => {
    logger.info('makeSaveScriptAndConsultationRequest', getConsultLoggingInfo());
    try {
      const response = await submitPrescriptions();

      // For follow up doctor consultation we automatically charge on consultation completion
      if (isDoc && canShowChargeButton) {
        try {
          await paymentsButtonAction();
        } catch (error) {
          // At this stage we don't want to prevent script creation or show toast on payment error so we just log error
          logger.error('Error collecting automatic payment for follow-up doc consultation', error);
        }
      }

      setLoader(false);
      if (response) {
        resetPrescriptionHistory();
        resetPrescriptionPad();

        logger.info('makeSaveScriptAndConsultationRequest:: Prescription Created', getConsultLoggingInfo());
        toast.success('Prescription Created');
      }
    } catch {
      setLoader(false);
      logger.error(
        'makeSaveScriptAndConsultationRequest:: Failed to Create! Please try again later.',
        getConsultLoggingInfo()
      );
      toast.error('Failed to Create! Please try again later.');
    }
  };

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isNotificationOfTreatmentModalOpen, setIsNotificationOfTreatmentModalOpen] = useState(false);
  const [confirmCancelPrescription, setConfirmCancelPrescription] = useState(false);

  const hasItemsToCancel = itemsToCancel.length > 0;
  const hasItemsToReissue = itemsToReissue.length > 0;
  const hasItemsToAction = itemsToAction.length === 0;
  const shouldDisplayForWaPatient = isWaNotificationOfTreatment && isWaPatient;
  const medicationProductsPayload = transformPrescriptionsPayload(medicationPadItems);

  const isConsultationValid = (showConsultation: { id: any }, patientActive: any) => {
    return showConsultation && showConsultation.id && patientActive;
  };

  const handleNoProductsOrConfirmStep = async (
    medicationPadItems: string | any[],
    createScriptStep: PrescriptionPadStep
  ) => {
    if (medicationPadItems.length === 0 || createScriptStep === PrescriptionPadStep.Confirm) {
      setLoader(true);
      await makeSaveScriptAndConsultationRequest();
    }
  };

  const handleCreateStep = (createScriptStep: PrescriptionPadStep) => {
    if (createScriptStep === PrescriptionPadStep.Create) {
      updatePrescriptionPadStep(PrescriptionPadStep.Confirm);
    } else {
      updatePrescriptionPadStep(PrescriptionPadStep.Create);
    }
  };

  const onReviewScript = async () => {
    if (!isConsultationValid(showConsultation, patientActive)) {
      return;
    }

    if (hasItemsToAction && hasItemsToCancel && !isCancelModalOpen) {
      setIsCancelModalOpen(true);
      return;
    }

    await handleNoProductsOrConfirmStep(medicationPadItems, createScriptStep);
    handleCreateStep(createScriptStep);
  };

  const handleConfirmCancelPrescription = () => {
    setIsCancelModalOpen(false);
    if (!ffV4PrescriptionAssistantCancelPrescribedMedication) {
      return;
    }
    const hasNoValidMedication = !medicationPadItems[0]?.medicationPadItem?.productName;
    // When the doctor is ONLY cancelling medications
    if (hasNoValidMedication) {
      setConfirmCancelPrescription(true);
      resetPrescriptionPad();
      resetPrescriptionHistory();
      updatePrescriptionPadStep(PrescriptionPadStep.Create);
      return;
    }

    // When the doctor is both cancelling & prescribing medications
    updatePrescriptionPadStep(PrescriptionPadStep.Confirm);
  };

  useEffect(() => {
    if (confirmCancelPrescription && hasItemsToReissue && hasItemsToAction) {
      setConfirmCancelPrescription(false);
      handleCreateStep(createScriptStep);
    }
  }, [confirmCancelPrescription]);

  // @TODO:[B2C-4637] This onCreate function will need to be deleted on V4 release and we will be using
  // the ⛔️ onReviewScript ⛔️ function instead
  const onCreate = async () => {
    if (!showConsultation || !showConsultation.id || !patientActive) {
      return;
    }
    // Preview script details before the user can submit

    // if there are no products being prescribed, or if the step is at "confirm script", then invoking onCreate will save the prescription directly.
    if (medicationPadItems.length === 0 || createScriptStep === PrescriptionPadStep.Confirm) {
      setLoader(true);
      return makeSaveScriptAndConsultationRequest();
    }

    if (createScriptStep === PrescriptionPadStep.Create) {
      return updatePrescriptionPadStep(PrescriptionPadStep.Confirm);
    }
  };

  const onScriptSubmit = () => {
    const canShowSuitableDeviceModal =
      isPatPrescribingSuitableDeviceModalEnabled &&
      padIncludesMedsThatRequireSuitableDevice &&
      createScriptStep === PrescriptionPadStep.Create;

    if (canShowSuitableDeviceModal) {
      setShowSuitableDeviceModal(true);
      return;
    }

    if (shouldDisplayForWaPatient && createScriptStep === PrescriptionPadStep.Confirm) {
      try {
        fetchPdf({
          patientId: props.patientId,
          medicationProductsPayload
        });
        setIsNotificationOfTreatmentModalOpen(true);
      } catch (error) {
        // @TODO: Unhappy path flow https://montugroup.atlassian.net/browse/B2C-5074
        logger.error('Error fetching PDF:', error);
      }
      return;
    }

    ffV4PrescriptionAssistantCancelPrescribedMedication ? onReviewScript() : onCreate();
  };

  const handleCancelScript = () => {
    resetPrescriptionPad();
    resetPrescriptionHistory();
    updatePrescriptionPadStep(PrescriptionPadStep.Create);
    setData({
      ...data,
      weeks: 8,
      patientId: Number(props.patientId),
      products: [
        {
          ...initialPrescriptionProductValues
        }
      ]
    });
  };

  const NurseOnSave = () => {
    if (!patientActive) {
      return;
    }
    props.saveConsultation({
      id: showConsultation.id,
      notes: showConsultation.notes,
      status_id: showConsultation.status_id,
      sub_status_id:
        consultationSubStatus?.subStatusId && showConsultation.status_id === settings.consultationStatus.ineligible
          ? consultationSubStatus?.subStatusId
          : null
    });
  };

  const handleSendLateMail = async () => {
    const response = await makePOST(
      `consultation/mail/running-late?patient_id=${data.patientId}`,
      //@ts-expect-error: TS2345 - need to fix this type error
      {},
      'runningLate-ConsultationTab'
    );
    if (response) {
      toast.success('Running late email sent successfully');
    } else {
      toast.error('Failed sending running late email');
    }
  };

  const {
    mutateAsync: createSpotConsultation,
    isSuccess: createInstantConsultationSuccess,
    isError: createInstantConsultationError
  } = useCreateInstantConsultation();

  const [createdConsultationId, setCreatedConsultationId] = useState<number | null>(null);

  useEffect(() => {
    if (createInstantConsultationSuccess && createdConsultationId) {
      const createdConsultation = consultationData.find((con: ConsultationModel) => con.id === createdConsultationId);
      if (createdConsultation) {
        toast.success('Spot consultation created successfully');
        props.setShowConsultation(createdConsultation);
        setCreatedConsultationId(null);
      }
    } else if (createInstantConsultationError) {
      toast.error('Failed to create spot consultation');
    }
  }, [createInstantConsultationError, consultationData]);

  const handleSpotConsultationCreation = async () => {
    const res = await createSpotConsultation({ patient_id: data.patientId });

    if (res) {
      setCreatedConsultationId(res.data.attributes.id);
    }

    // On creation of the spot consult save any values added into the patient details form, this also triggers refresh
    props.onSave(true, true);
  };

  const handleOpenRunningLate = async () => {
    const modal = showModal(ConfirmDialog, {
      title: 'Email patient',
      children: 'Send a running late email to the patient?',
      actions: (
        <>
          <Button
            onClick={() => {
              modal.hide();
              handleSendLateMail();
            }}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              modal.hide();
            }}
          >
            No
          </Button>
        </>
      )
    });
  };

  const handleOpenSpotConsultation = async () => {
    const modal = showModal(ConfirmDialog, {
      title: 'Arrange Spot Consultation',
      children: 'This will create a consultation for this patient, right now, are you sure?',
      actions: (
        <>
          <Button
            onClick={() => {
              modal.hide();
              handleSpotConsultationCreation();
            }}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              modal.hide();
            }}
          >
            No
          </Button>
        </>
      )
    });
  };

  const handleNoShow = () => {
    props.saveConsultation(
      {
        id: showConsultation.id,
        notes: showConsultation.notes,
        active: false,
        status_id: settings.consultationStatus.noShow
      },
      false
    );
    props.refresh();
    props.setShowConsultation(null);
  };

  const setNoShow = async () => {
    if (!patientActive) {
      return;
    }

    const modal = showModal(ConfirmDialog, {
      title: 'No-Show',
      children: 'Are you sure to set this consultation as No-Show?',
      actions: (
        <>
          <Button
            onClick={() => {
              modal.hide();
              handleNoShow();
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              modal.hide();
            }}
          >
            No
          </Button>
        </>
      )
    });
  };

  const fieldsToPrefillBookingForm = {
    name: `${props.fields.FirstName} ${props.fields.LastName}`,
    email: props.fields.email,
    phoneNumber: `${props.fields.phone || settings.localisation.phoneCountryCodes.australia}`
  };

  /**
   * Used to either create a new patient note or to view / edit an existing note
   * @param selectedNote
   */
  const handleAddOrViewPatientNote = (
    selectedNote:
      | {
          id: number;
          note: string;
          heading: string;
          CreatedBy: { first_name?: string; last_name?: string };
          created_by: number;
          strike_reason?: string;
        }
      | undefined
  ) => {
    // if no selected note passed, then start a new note
    // blank canvas, easiest option for initial refactor
    if (!selectedNote) {
      setSelectedPatientNote({
        //@ts-expect-error: TS2353 - need to fix this type error
        id: null,
        heading: '',
        notes: '',
        createdByName: '',
        createdById: null,
        redFlag: props.fields?.redFlag ?? false,
        redFlagComment: props.fields?.redFlagComment ?? '',
        hasStrike: false,
        strikeReason: null
      });
      return;
    }

    setSelectedPatientNote({
      //@ts-expect-error: TS2353 - need to fix this type error
      notes: selectedNote.note,
      heading: selectedNote.heading,
      id: selectedNote.id,
      createdByName: `${selectedNote.CreatedBy?.first_name} ${selectedNote.CreatedBy?.last_name}`,
      createdById: selectedNote.created_by,
      redFlag: props.fields?.redFlag ?? false,
      redFlagComment: props.fields?.redFlagComment ?? '',
      hasStrike: Boolean(selectedNote?.strike_reason),
      strikeReason: selectedNote.strike_reason ?? null
    });
  };

  const statusDropdown = (
    <div style={{ display: 'flex', flexDirection: 'column', width: 'auto', marginRight: '15px', minWidth: '400px' }}>
      <SelectField
        id="consultation-status"
        label="Consultation Status"
        options={consultationStatuses}
        labelPadding={isDoc ? '5px 5px 5px 0px' : '5px 5px 5px 15px'}
        disabled={!patientActive || isDoc}
        value={showConsultation?.status_id || ''}
        onChange={(e: { target: { value: any } }) =>
          props.setShowConsultation({ ...showConsultation, status_id: e.target.value })
        }
        varient="outlined"
        customGrid={isDoc ? [5, 7] : [10, 15]}
      />
      {showConsultation?.status_id === settings.consultationStatus.ineligible && (
        <SelectField
          id="consultation-sub-status"
          label="Ineligibility Reason"
          options={ineligibleStatuses}
          labelPadding={isDoc ? '5px 5px 5px 0px' : '5px 5px 5px 15px'}
          disabled={!patientActive || isDoc}
          value={consultationSubStatus?.subStatusId || ''}
          onChange={(e: { target: { value: number } }) =>
            setConsultationSubStatus({ name: ineligibleStatuses[e.target.value - 1].name, subStatusId: e.target.value })
          }
          varient="outlined"
          customGrid={isDoc ? [5, 7] : [10, 15]}
        />
      )}
    </div>
  );

  const handleClose = () => {
    props.setShowConsultation(null);
    updatePrescriptionPadStep(PrescriptionPadStep.Create);
  };

  const disabledDueToUnactionedConfirmCheckList =
    ffConfirmationCheckListEnabled &&
    createScriptStep === PrescriptionPadStep.Confirm &&
    reviewAction.some((action) => !action.checked);

  const shouldDisableCreateScriptBtn = () => {
    const shouldDisable =
      // check if the create script request is loading
      loading ||
      // check if the patient is active
      !patientActive ||
      // check if the prescription pad is valid
      // check if the valid prescriptions are more than 0
      (!isValidPrescriptionPad && !!validPrescriptionMedications.length);

    return shouldDisable || disabledDueToUnactionedConfirmCheckList;
  };

  const overviewTabHeaderSection = (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', lg: 'row' }}
      justifyContent={'space-between'}
      alignItems={'baseline'}
    >
      <StyledSearchTextField onChange={debounce(handleSearchOnChange, 1000)} label="Search" variant="outlined" />
      <Box component="div" display="flex" ml={1} sx={{ justifyContent: 'end', gap: 1 }} alignItems={'center'}>
        {!isAdminOnly && (
          <Button
            variant="outlined"
            color="secondary"
            disabled={loading || !patientActive}
            onClick={handleOpenRunningLate}
          >
            Running late
          </Button>
        )}
        {ffNurseInstantConsultationCreationEnabled && (
          <Button
            variant="outlined"
            color="secondary"
            disabled={loading || !patientActive}
            onClick={handleOpenSpotConsultation}
          >
            Arrange spot consult
          </Button>
        )}
        {Boolean(consultationData.length) && (
          <BookConsultationMenu
            fieldsToPrefillBookingForm={fieldsToPrefillBookingForm}
            onBookingSuccessful={props.refresh}
            doctorsList={props.doctorsList}
            doctor={props.doctor}
            enableBookAnyDoctorButton={enableBookAnyDoctorButton}
            enableBookSpecificDoctorButton={enableBookSpecificDoctorButton}
            initialDoctorConsultCompleted={initialDoctorConsultCompleted}
            initialCompletedNurseConsults={initialCompletedNurseConsults}
          />
        )}
      </Box>
    </Box>
  );

  const renderFollowUpConsultationSelect = () => {
    if (!hasCompletedActions || !(createScriptStep === PrescriptionPadStep.Create)) {
      return null;
    }

    return (
      <Stack width="80%">
        <SelectField
          label="Follow-up Consultation"
          id="prescribed-product-weeks"
          options={[...Array(20).keys()].map((itm) => {
            return { name: `${itm + 1} weeks`, value: itm + 1 };
          })}
          value={data.weeks}
          onChange={(e: { target: { value: any } }) => setData({ ...data, weeks: e.target.value })}
          customGrid={[4, 3]}
          margin={0}
          varient="outlined"
          labelPadding="7px 7px 7px 10px"
          disabled={!patientActive}
        />
      </Stack>
    );
  };

  const renderConsultationConfirmationButton = () => {
    if (!hasCompletedActions || isManager) {
      return null;
    }
    return (
      <ConsultationConfirmationButton
        onClick={onScriptSubmit}
        //@ts-expect-error: TS2322 - need to fix this type error
        style={{ marginLeft: '20px' }}
        disabled={shouldDisableCreateScriptBtn()}
        isLoading={loading}
        prescriptionPadStep={validPrescriptionMedications.length === 0 ? null : createScriptStep}
        isConfirmationCheckListEnabled={ffConfirmationCheckListEnabled}
        disabledDueToUnactionedConfirmCheckList
      />
    );
  };

  const renderNoShowOrCancelButton = () => {
    if (!hasCompletedActions) {
      return null;
    }
    if (createScriptStep === PrescriptionPadStep.Create) {
      return (
        <Button
          variant="outlined"
          color="secondary"
          disabled={!patientActive}
          onClick={setNoShow}
          sx={{ borderRadius: 1, textTransform: 'none' }}
        >
          No Show
        </Button>
      );
    }

    return (
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleCancelScript}
        sx={{ borderRadius: 1, textTransform: 'none' }}
      >
        Cancel
      </Button>
    );
  };

  const resendInvoiceButton = () => {
    if (isVoidedEwayConsultation) {
      return null;
    }

    return (
      <Button
        variant="outlined"
        color="secondary"
        onClick={paymentsButtonAction}
        disabled={isPaymentButtonDisabled}
        sx={{ borderRadius: 1, textTransform: 'none' }}
      >
        {loadingPaymentAction ? <CircularProgress size={16} color="inherit" /> : 'Resend Invoice'}
      </Button>
    );
  };

  const renderPaymentInfoText = () => {
    switch (consultPaymentState) {
      case 'IN_PROGRESS':
        return (
          <Alert severity={'info'}>
            <AlertTitle>The patient&apos;s consultation payment is pending.</AlertTitle>
            Please click the &quot;Resend Invoice&quot; button to generate and send a new copy of the payment invoice to
            the patient.
          </Alert>
        );
      case 'PAID':
        return (
          <Alert severity={'success'}>
            <AlertTitle>The patient&apos;s consultation payment has been successfully processed.</AlertTitle>
            If needed, click the &quot;Resend Invoice&quot; button to generate and send a new copy of the payment
            receipt to the patient.
          </Alert>
        );
      case 'NOT_PAID':
      default:
        return (
          <>
            <Typography variant="body2" fontWeight={500} display={'inline'}>
              All patients will be automatically charged for the consultation when the &quot;Complete Consultation&quot;
              button is selected.{' '}
            </Typography>
            <Typography variant="body2" display={'inline'}>
              If the consultation is complimentary select &quot;Complimentary Consultation&quot;. Please note that
              concession and other eligible discounts will be applied automatically
            </Typography>
          </>
        );
    }
  };

  const renderCompleteConsultationSection = () => {
    if (showConsultation.doctor_id && (showConsultation.Doctor.DoctorUser.id === user.id || isAdmin || isManager)) {
      if (isDoc && canShowChargeButton) {
        return (
          <>
            <SectionHeader headerText="Payment Details" />
            <Box mt={4} display={'flex'} flexDirection={'column'} gap={4}>
              <Box>{renderPaymentInfoText()}</Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Box width="200px">
                  {isPaymentInProgressOrCompleted ? (
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                      <Typography variant="body2" fontWeight={600} display={'inline'}>
                        Payment Type:
                      </Typography>
                      <Typography variant="body2" display={'inline'}>
                        {getPaymentTypeText(showConsultation)}
                      </Typography>
                    </Box>
                  ) : (
                    <CheckBoxField
                      id="complimentary-consultation"
                      label="Complimentary consultation"
                      placeholder="-"
                      labelPadding="11px 7px 7px 0px"
                      labelFontWeight={900}
                      checked={isComplimentary}
                      onChange={() => {
                        logger.info('setIsComplimentary', getConsultLoggingInfo({ isComplimentary: !isComplimentary }));
                        setIsComplimentary(!isComplimentary);
                      }}
                      customGrid={[11, 1]}
                      margin={0}
                      disabled={isPaymentInProgressOrCompleted}
                    />
                  )}
                </Box>

                <Box sx={{ display: 'flex' }}>
                  {isPaymentInProgressOrCompleted && <Box mr={2}>{resendInvoiceButton()}</Box>}
                  {!isPaymentInProgressOrCompleted && <Box mr={2}>{renderNoShowOrCancelButton()}</Box>}
                  {renderConsultationConfirmationButton()}
                </Box>
              </Box>
            </Box>
          </>
        );
      }

      return (
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '14px' }}>
          {renderConsultationConfirmationButton()}
          <Box mr={2}>{renderNoShowOrCancelButton()}</Box>
        </Box>
      );
    }

    if (ffConsultationChargesUplift) {
      return (
        <Box component="section" mt={4}>
          <SectionHeader headerText="Consultation Status" />
          <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
            {statusDropdown}
            <Button
              color="secondary"
              disabled={isSubmitButtonDisabled}
              onClick={NurseOnSave}
              sx={{ borderRadius: 1, textTransform: 'none' }}
            >
              <Box width="100px">Submit</Box>
            </Button>
          </Box>
        </Box>
      );
    }

    return (
      <Box mt={2} display="flex" flexDirection="row-reverse" alignItems="flex-start">
        <Button variant="contained" color="secondary" disabled={!patientActive || isDoc} onClick={NurseOnSave}>
          <Box width="100px">Submit</Box>
        </Button>
        {statusDropdown}
      </Box>
    );
  };

  const isUserAuthorisedForConsultation =
    showConsultation?.doctor_id && (showConsultation?.Doctor?.DoctorUser?.id === user?.id || isAdmin);

  const renderPaymentDetails = () => {
    if (!canShowChargeButton || isDoc) {
      return null;
    }

    if (ffConsultationChargesUplift) {
      return (
        <Box component="section" mt={4}>
          <SectionHeader headerText="Payment Details" />
          <SectionBody>
            {statusIsNotPaid && (
              <Alert severity={'warning'} sx={{ marginBottom: 4 }}>
                <AlertTitle>Action Required</AlertTitle>
                The patient needs to be charged for the appointment before submit. Thank you for your attention to this
                matter.
              </Alert>
            )}
            <Box flexDirection={'row'} display={'flex'} justifyContent={'space-between'}>
              <Box width={'50%'}>
                <SelectField
                  id="discount-type"
                  label="Discount Type"
                  // TODO: The following options are mocks, source the discount-type status options from BE - https://montugroup.atlassian.net/browse/B2CS-1381
                  options={[
                    { name: 'Complimentary Consultation', value: 1 },
                    { name: 'Concession Card', value: 2 },
                    { name: 'Other', value: 3 }
                  ]}
                  // TODO: We need to save discount-type to BE and set the value below
                  // value={''}
                  // onChange={() => {}}
                  labelPadding={'5px 0px 0px 0px'}
                  varient="outlined"
                  customGrid={[4, 8]}
                />
              </Box>
              {/* TODO: Only show the following discount-percentage input if the above discount-type input has a selection */}
              <Box width={'25%'}>
                <InputField
                  type="number"
                  id="discount-percentage"
                  label="Discount"
                  // TODO: We need to save discount-percentage to BE and set the value below
                  // value={0}
                  // onChange={() => {}}
                  // TODO: Set and disable input at specific values based on discount-type value, unless discount-type value is set to "Other" - https://montugroup.atlassian.net/browse/B2CS-1430
                  // disabled={false}
                  varient="outlined"
                  customGrid={[6, 6]}
                />
              </Box>
              <Button color="secondary" size="large" onClick={paymentsButtonAction} disabled={isPaymentButtonDisabled}>
                {getPaymentButtonText()}
              </Button>
            </Box>
          </SectionBody>
        </Box>
      );
    }

    return (
      <Box component="section">
        <SectionHeader headerText="Payment Details" />

        <SectionBody>
          <DisplayField
            id="consultation-payment-details"
            label="Payment Type"
            fieldText={getPaymentTypeText(showConsultation)}
            margin={0}
            labelPadding="0px"
            fieldPadding="0px"
            labelFontWeight={500}
            customGrid={[3, 9]}
          />
          <Box mt={2} display="flex" flexDirection="column">
            <Box display="flex">
              {canShowDiscountRateInput && (
                <Box mr={2}>
                  <Tooltip title="Discount rate applied on the consult. A value of 10 represents 10% off.">
                    <TextField
                      label="Discount %"
                      /* Inline style for now until ui cleanup - needs more width for long label */
                      style={{ minWidth: '100px' }}
                      type="number"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        inputProps: discountInputProps
                      }}
                      value={consultDiscountRate}
                      onChange={handleChangeConsultDiscountRate}
                      disabled={loadingAnyPayment}
                    />
                  </Tooltip>
                </Box>
              )}
              {!isVoidedEwayConsultation && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={paymentsButtonAction}
                  disabled={isPaymentButtonDisabled}
                >
                  {loadingPaymentAction ? <CircularProgress size={16} color="inherit" /> : getPaymentButtonText()}
                </Button>
              )}
            </Box>
            {canShowChargeConcessionBtn && (
              <Box>
                <Typography display="block" variant="overline">
                  OR
                </Typography>
                <Tooltip title={formattedConcessionConsulationPrice}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConcessionPaymentClick}
                    disabled={loadingAnyPayment}
                  >
                    {loadingChargeConcession ? <CircularProgress size={16} color="inherit" /> : 'Charge Concession Fee'}
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Box>
        </SectionBody>
      </Box>
    );
  };

  return (
    <Box>
      {ffV4PrescriptionAssistantCancelPrescribedMedication && (
        <CancelPrescribedMedicationModal
          isOpen={isCancelModalOpen}
          onClose={() => setIsCancelModalOpen(false)}
          onConfirm={() => submitCancelPrescribedMedications(props.patientId, handleConfirmCancelPrescription)}
        />
      )}
      {isUserAuthorisedForConsultation && (
        <NotificationOfTreatmentModal
          isOpen={isNotificationOfTreatmentModalOpen}
          onClose={() => setIsNotificationOfTreatmentModalOpen(false)}
          onConfirm={() => {
            setIsNotificationOfTreatmentModalOpen(false);
            ffV4PrescriptionAssistantCancelPrescribedMedication ? onReviewScript() : onCreate();
          }}
          confirmText="Sign and Submit"
        >
          <NotificationOfTreatmentPDFViewer
            isPdfLoading={isPdfLoading}
            pdfError={pdfError}
            pdfString={pdfString}
            isForCancellation={false}
          />
        </NotificationOfTreatmentModal>
      )}
      {isUserAuthorisedForConsultation && <WaNotificationBanner />}
      {showConsultation ? (
        <>
          <ConsultationDetails
            consultation={showConsultation}
            onClose={handleClose}
            patientId={props?.patientId}
            patientPhoneNumber={props?.patient?.phone}
          />
          {!isDoc && renderPaymentDetails()}
          {(!isDoc || showConsultation?.Nurse?.id) && (
            <Box component="section">
              <SectionHeader headerText={showConsultation.doctor_id ? 'Treatment Plan' : 'Patient Assessment'} />
              <SectionBody>
                {!showConsultation.doctor_id && (
                  <>
                    <Box mt={0.5} width="100%">
                      <CheckBoxField
                        id="patient-details-confirmation"
                        label="Patient Details"
                        placeholder="-"
                        disabled={!patientActive}
                        labelPadding="11px 7px 7px 0px"
                        icon={
                          <Box
                            width="17px"
                            height="17px"
                            ml={0.5}
                            mt={0.5}
                            border={1}
                            borderRadius="2px"
                            borderColor="#c4c4c4"
                          />
                        }
                        labelFontWeight={900}
                        checked={props.fields.detailsConfirmed}
                        InputClasses="custom-adress-field"
                        onChange={props.handleChange('detailsConfirmed')}
                        customGrid={[3, 9]}
                        margin={0}
                        checkBoxSuffix={
                          <Box p={1} mt={1} ml={2.75} fontSize={12} fontStyle="italic">
                            Confirmed
                          </Box>
                        }
                      />
                    </Box>
                    <Box mt={0.5} width="100%" height="40px">
                      <CheckBoxField
                        id="medical-summary-confirmation"
                        label="Medical Summary"
                        placeholder="-"
                        disabled={!patientActive}
                        labelPadding="11px 7px 7px 0px"
                        labelFontWeight={900}
                        checked={props.fields.medicalSummary}
                        icon={
                          <Box
                            width="17px"
                            height="17px"
                            ml={0.5}
                            mt={0.5}
                            border={1}
                            borderRadius="2px"
                            borderColor="#c4c4c4"
                          />
                        }
                        InputClasses="custom-adress-field"
                        onChange={props.handleChange('medicalSummary')}
                        customGrid={[3, 9]}
                        margin={0}
                        checkBoxSuffix={
                          <Box p={1} mt={1} ml={2.75} fontSize={12} fontStyle="italic">
                            Verified
                          </Box>
                        }
                      />
                    </Box>
                    <Box mt={0.5} width="100%" height="40px">
                      <CheckBoxField
                        id="payment-confirmation"
                        label="Payment"
                        placeholder="-"
                        labelPadding="11px 7px 7px 0px"
                        labelFontWeight={900}
                        disabled={!patientActive}
                        checked={props.fields.PaymentVerified || showConsultation.payment_status === 'PAID'}
                        icon={
                          <Box
                            width="17px"
                            height="17px"
                            ml={0.5}
                            mt={0.5}
                            border={1}
                            borderRadius="2px"
                            borderColor="#c4c4c4"
                          />
                        }
                        InputClasses="custom-adress-field"
                        onChange={props.handleChange('PaymentVerified')}
                        customGrid={[3, 9]}
                        margin={0}
                        checkBoxSuffix={
                          <Box>
                            <Box
                              style={{ display: 'inline-block' }}
                              p={1}
                              mt={1}
                              ml={2.75}
                              fontSize={12}
                              fontStyle="italic"
                            >
                              Verified
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                    <Box mt={1}>
                      <DisplayField
                        id="risk-screening"
                        label="Risk Assessment"
                        fieldText={
                          <Box fontSize={12} ml={2}>
                            Comments
                          </Box>
                        }
                        margin={0}
                        labelPadding="0px"
                        fieldPadding="0px"
                      />
                    </Box>
                    <Box mt={0.5} width="100%">
                      <CheckBoxField
                        id="medical-Psychosis-Hx"
                        label="Psychosis History"
                        placeholder="-"
                        labelPadding="11px 7px 7px 0px"
                        labelFontWeight={500}
                        disabled={!patientActive}
                        checked={props.fields.PsychosisHx}
                        icon={
                          <Box
                            width="17px"
                            height="17px"
                            ml={0.5}
                            mt={0.5}
                            border={1}
                            borderRadius="2px"
                            borderColor="#c4c4c4"
                          />
                        }
                        InputClasses="custom-adress-field"
                        onChange={props.handleChange('PsychosisHx')}
                        customGrid={[3, 9]}
                        margin={0}
                        checkBoxSuffix={
                          <Box ml={4} p={0.25} width="100%">
                            <CheckBoxSuffixField
                              id="psycosis-field-suffix"
                              type="text"
                              placeholder="-"
                              disabled={!props.fields.PsychosisHx}
                              value={props.fields.psycosis}
                              onChange={props.handleChange('psycosis')}
                            />
                          </Box>
                        }
                      />
                    </Box>
                    <Box mt={0.25} width="100%">
                      <CheckBoxField
                        id="drug-drug"
                        label="Drug-Drug Interaction"
                        placeholder="-"
                        labelPadding="11px 7px 7px 0px"
                        labelFontWeight={500}
                        checked={props.fields.DrugDrug}
                        disabled={!patientActive}
                        icon={
                          <Box
                            width="17px"
                            height="17px"
                            ml={0.5}
                            mt={0.5}
                            border={1}
                            borderRadius="2px"
                            borderColor="#c4c4c4"
                          />
                        }
                        InputClasses="custom-adress-field"
                        onChange={props.handleChange('DrugDrug')}
                        customGrid={[3, 9]}
                        margin={0}
                        checkBoxSuffix={
                          <Box ml={4} p={0.25} width="100%">
                            <CheckBoxSuffixField
                              id="DrugDrugComment-field-suffix"
                              type="text"
                              placeholder="-"
                              disabled={!props.fields.DrugDrug}
                              value={props.fields.DrugDrugComment}
                              onChange={props.handleChange('DrugDrugComment')}
                            />
                          </Box>
                        }
                      />
                    </Box>
                    <Box mt={0.25} width="100%">
                      <CheckBoxField
                        id="falls"
                        label="Falls Risk"
                        placeholder="-"
                        labelPadding="11px 7px 7px 0px"
                        disabled={!patientActive}
                        labelFontWeight={500}
                        checked={props.fields.falls}
                        icon={
                          <Box
                            width="17px"
                            height="17px"
                            ml={0.5}
                            mt={0.5}
                            border={1}
                            borderRadius="2px"
                            borderColor="#c4c4c4"
                          />
                        }
                        InputClasses="custom-adress-field"
                        onChange={props.handleChange('falls')}
                        customGrid={[3, 9]}
                        margin={0}
                        checkBoxSuffix={
                          <Box ml={4} p={0.25} width="100%">
                            <CheckBoxSuffixField
                              id="fallsComment-field-suffix"
                              type="text"
                              placeholder="-"
                              disabled={!props.fields.falls}
                              value={props.fields.fallsComment}
                              onChange={props.handleChange('fallsComment')}
                            />
                          </Box>
                        }
                      />
                    </Box>
                    <Box mt={0.25} width="100%">
                      <CheckBoxField
                        id="substance_abuse"
                        label="Substance Abuse"
                        placeholder="-"
                        labelPadding="11px 7px 7px 0px"
                        labelFontWeight={500}
                        checked={props.fields.SubstanceAbuse}
                        disabled={!patientActive}
                        InputClasses="custom-adress-field"
                        onChange={props.handleChange('SubstanceAbuse')}
                        icon={
                          <Box
                            width="17px"
                            height="17px"
                            ml={0.5}
                            mt={0.5}
                            border={1}
                            borderRadius="2px"
                            borderColor="#c4c4c4"
                          />
                        }
                        customGrid={[3, 9]}
                        margin={0}
                        checkBoxSuffix={
                          <Box ml={4} p={0.25} width="100%">
                            <CheckBoxSuffixField
                              id="SubstanceAbuseComment-field-suffix"
                              type="text"
                              placeholder="-"
                              disabled={!props.fields.SubstanceAbuse}
                              value={props.fields.SubstanceAbuseComment}
                              onChange={props.handleChange('SubstanceAbuseComment')}
                            />
                          </Box>
                        }
                      />
                    </Box>
                    <Box mt={0.25} width="100%">
                      <CheckBoxField
                        id="recently_incarcerated"
                        label="Recently Incarcerated"
                        placeholder="-"
                        labelPadding="11px 7px 7px 0px"
                        labelFontWeight={500}
                        disabled={!patientActive}
                        checked={props.fields.RecentlyIncarcerated}
                        InputClasses="custom-adress-field"
                        onChange={props.handleChange('RecentlyIncarcerated')}
                        icon={
                          <Box
                            width="17px"
                            height="17px"
                            ml={0.5}
                            mt={0.5}
                            border={1}
                            borderRadius="2px"
                            borderColor="#c4c4c4"
                          />
                        }
                        customGrid={[3, 9]}
                        margin={0}
                        checkBoxSuffix={
                          <Box ml={4} p={0.25} width="100%">
                            <CheckBoxSuffixField
                              id="RecentlyIncarceratedComment-field-suffix"
                              type="text"
                              placeholder="-"
                              disabled={!props.fields.RecentlyIncarcerated}
                              value={props.fields.RecentlyIncarceratedComment}
                              onChange={props.handleChange('RecentlyIncarceratedComment')}
                            />
                          </Box>
                        }
                      />
                    </Box>
                    <Box mt={0.25} width="100%">
                      <CheckBoxField
                        id="si_tosh"
                        label="SI/TOSH"
                        placeholder="-"
                        labelPadding="11px 7px 7px 0px"
                        labelFontWeight={500}
                        checked={props.fields.SiTosh}
                        InputClasses="custom-adress-field"
                        disabled={!patientActive}
                        onChange={props.handleChange('SiTosh')}
                        icon={
                          <Box
                            width="17px"
                            height="17px"
                            ml={0.5}
                            mt={0.5}
                            border={1}
                            borderRadius="2px"
                            borderColor="#c4c4c4"
                          />
                        }
                        customGrid={[3, 9]}
                        margin={0}
                        checkBoxSuffix={
                          <Box ml={4} p={0.25} width="100%">
                            <CheckBoxSuffixField
                              id="SiToshComment-field-suffix"
                              type="text"
                              placeholder="-"
                              disabled={!props.fields.SiTosh}
                              value={props.fields.SiToshComment}
                              onChange={props.handleChange('SiToshComment')}
                            />
                          </Box>
                        }
                      />
                    </Box>
                    <Box mt={0.25} width="100%">
                      <CheckBoxField
                        id="Lactating"
                        label="Pregnant OR Lactating"
                        placeholder="-"
                        labelPadding="11px 7px 7px 0px"
                        labelFontWeight={500}
                        disabled={!patientActive}
                        checked={props.fields.Lactating}
                        InputClasses="custom-adress-field"
                        onChange={props.handleChange('Lactating')}
                        icon={
                          <Box
                            width="17px"
                            height="17px"
                            ml={0.5}
                            mt={0.5}
                            border={1}
                            borderRadius="2px"
                            borderColor="#c4c4c4"
                          />
                        }
                        customGrid={[3, 9]}
                        margin={0}
                        checkBoxSuffix={
                          <Box ml={4} p={0.25} width="100%">
                            <CheckBoxSuffixField
                              id="LactatingComment-field-suffix"
                              type="text"
                              placeholder="-"
                              disabled={!props.fields.Lactating}
                              value={props.fields.LactatingComment}
                              onChange={props.handleChange('LactatingComment')}
                            />
                          </Box>
                        }
                      />
                    </Box>
                    <Box mt={2} width="100%">
                      <StyledSelectField
                        id="treatment-goal"
                        label="Treatment Goal"
                        disabled={!patientActive}
                        options={[
                          {
                            name: 'Patient wants to add cannabis to current regime',
                            value: 'Patient wants to add cannabis to current regime'
                          },
                          {
                            name: 'Patient wants to/has stopped using other medication',
                            value: 'Patient wants to/has stopped using other medication'
                          },
                          {
                            name: 'Patient wants to reduce dosages of their current medication',
                            value: 'Patient wants to reduce dosages of their current medication'
                          }
                        ]}
                        labelPadding="7px 5px 5px 0px"
                        value={props.fields.treatmentGoal}
                        onChange={props.handleChange('treatmentGoal')}
                        varient="outlined"
                        margin={0}
                      />
                    </Box>
                    <Box mt={1} width="100%">
                      <CheckBoxField
                        id="mc-process"
                        label="MC Process"
                        placeholder="-"
                        labelPadding="10px 7px 7px 0px"
                        disabled={!patientActive}
                        icon={
                          <Box
                            width="17px"
                            height="17px"
                            ml={0.5}
                            border={1}
                            mr={0}
                            borderRadius="2px"
                            borderColor="#c4c4c4"
                          />
                        }
                        labelFontWeight={900}
                        checked={props.fields.MCprocess}
                        InputClasses="custom-adress-field"
                        onChange={props.handleChange('MCprocess')}
                        customGrid={[3, 9]}
                        margin={0}
                        checkBoxSuffix={
                          <Box p={1} mt={0.5} ml={3} fontSize={12} fontStyle="italic">
                            Explained
                          </Box>
                        }
                      />
                    </Box>
                  </>
                )}
              </SectionBody>
            </Box>
          )}
          <Stack spacing={2}>
            <SectionHeader headerText="Consultation Notes" />
            <InputField
              id="notes"
              type="text"
              placeholder="Notes:"
              customGrid={[12, 12]}
              display="block"
              multiline
              labelPadding="5px 0px 2px 0px"
              disabled={!patientActive}
              margin={0}
              InputClasses="textarea-h-100-px"
              value={showConsultation.notes}
              onChange={(e: { target: { value: any } }) => props.handleDocNotes(e.target.value)}
            />
            {isDoc && renderFollowUpConsultationSelect()}
            <SingleConsultationNoteHistory consultationId={showConsultation.id} />
          </Stack>
          {isDoc && renderPaymentDetails()}
          {isUserAuthorisedForConsultation && (
            <Stack mt={3}>
              <SectionHeader
                data-testid="prescription"
                headerText={createScriptStep === PrescriptionPadStep.Create ? 'Prescriptions' : 'Confirm Prescription'}
              />

              {props.doctor && isDoc && (
                <SectionBody paddingX={0} paddingTop={4}>
                  <PrescriptionPadWrapper
                    doctorId={props.doctor?.id}
                    patientId={props?.patientId}
                    prescriberNumber={props.doctor?.prescriber_number}
                    doctorPatientDetails={{
                      doctor: props.doctor?.DoctorUser,
                      patient: props.patient
                    }}
                    //@ts-expect-error: TS2322 - need to fix this type error
                    isConcessionCardHolder={props.patient.hasValidConcessionCard}
                    showDeviceSuitabilityModal={showSuitableDeviceModal}
                    deviceSuitabilityModalOnConfirm={() => {
                      setShowSuitableDeviceModal(false);
                      ffV4PrescriptionAssistantCancelPrescribedMedication ? onReviewScript() : onCreate();
                    }}
                    deviceSuitabilityModalOnCancel={() => setShowSuitableDeviceModal(false)}
                  />
                  {/* @ts-expect-error TS2741 - need to fix this type error */}
                  <FullScreenLoader open={isPostingPrescription} loadingMessage="Submitting prescription..." />
                </SectionBody>
              )}
              {!isDoc && renderFollowUpConsultationSelect()}
            </Stack>
          )}
          {renderCompleteConsultationSection()}
        </>
      ) : (
        <Box>
          {selectedPatientNote ? (
            <ViewPatientNote
              patientId={props.patientId}
              patientActive={patientActive}
              selectedPatientNote={selectedPatientNote}
              refetchNotes={props.refetchNotes}
              //@ts-expect-error: TS2322 - need to fix this type error
              setSelectedPatientNote={setSelectedPatientNote}
              refetch={props.refresh}
            />
          ) : (
            <Box height="fit-content">
              <SectionHeader headerText="Consultation Overview" />
              <Box paddingY={6} mb={6} data-testid="consultation-table">
                {overviewTabHeaderSection}
                <ConsultationTabOverviewTable
                  consultations={consultationsOverviewTableData}
                  isLoading={props.isLoadingConsultations}
                  searchFilterText={searchFilterText}
                  handlePaginationChange={props.handlePaginationChange}
                  responseCursors={props.responseCursors}
                />
              </Box>
              <>
                <SectionHeader headerText="Notes" />
                <Box paddingY={6}>
                  <PatientNotes
                    notes={patientNoteData}
                    handleNotesPaginationChange={props.handleNotesPaginationChange}
                    responseNotesCursors={props.responseNotesCursors}
                    //@ts-expect-error: TS2322 - need to fix this type error
                    onViewNote={handleAddOrViewPatientNote}
                    //@ts-expect-error: TS2322 - need to fix this type error
                    onAddNewNote={handleAddOrViewPatientNote}
                    isAddNoteDisabled={loading || !patientActive}
                  />
                </Box>
              </>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ConsultationTab;
