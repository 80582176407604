import { Alert } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled, useTheme } from '@mui/material/styles';
import React, { createContext, useEffect, useRef, useState } from 'react';

import { Country, Currency, PaymentType } from '@/hooks/graphql/generated/graphql';
import { useRegisterPayment } from '@/hooks/graphql/mutations';
import type { CalendarData } from '@/hooks/rest/types';
import useGoogleTagManager from '@/hooks/useGoogleTagManager';
import { brazeChangeUser, brazeTrackEvent } from '@/services/braze.service';
import type { PaymentError } from '@/types/payments.types';
import { GoogleAnalyticsEventId, GoogleAnalyticsEventName } from '@/types/tracking.types';

import settings from '../../../data/constants';
import useTillPaymentScript from '../../../hooks/useTillPaymentScript';

import type { Payload } from './PaymentForm';
import PaymentForm from './PaymentForm';
import type { TillCardError } from './TillCardErrors';
import { isTillErrorMatch, possibleTillCardErrors } from './TillCardErrors';

declare const PaymentJs: any;

type Props = {
  data: CalendarData;
};

type InitialisingContextType = {
  initialising: boolean;
  setInitialising: React.Dispatch<React.SetStateAction<boolean>>;
};

declare global {
  interface Window {
    Cerberus: any;
  }
}

const FormContainer = styled('div')({
  position: 'relative'
});

const LoaderBlock = styled('div')({
  backgroundColor: '#000',
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: 1,
  opacity: '0.3',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

let tillPayment: any;

export const InitialisingContext = createContext<InitialisingContextType>({
  initialising: false,
  setInitialising: () => {}
});

export default function CreditCardPayment({ data }: Props) {
  const tillPaymentScriptStatus = useTillPaymentScript();
  const [isLoading, setIsLoading] = useState(false);
  const [initialising, setInitialising] = useState(true);
  const theme = useTheme();

  const [creditCardError, setCreditCardError] = useState<PaymentError>(undefined);
  const [paymentError, setPaymentError] = useState<PaymentError>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { registerPaymentExecuteMutation } = useRegisterPayment();

  const { sendGoogleAnalyticsEvent } = useGoogleTagManager();

  const HostedInputStyle = {
    border: '0px',
    borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
    background: `rgba(0, 0, 0, 0.06)`,
    height: '92%',
    fontWeight: 200,
    padding: '25px 12px 8px 12px',
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.875rem',
    lineHeight: '1.5rem'
  };

  // This ref is used to ensure GTM tracking is only fired once on load. It's been
  // hoisted to this level to avoid it resetting upon a re-render of subcomponents.
  const isPaymentTrackingTriggered = useRef<boolean>(false);

  useEffect(() => {
    if (tillPaymentScriptStatus === 'ready') {
      tillPayment = new PaymentJs();

      tillPayment.init(settings.tillSdkKey, 'cardNumber', 'cvv', (payment: any) => {
        let numberFocused = false;
        let cvvFocused = false;

        const focussedInputStyle = {
          ...HostedInputStyle,
          outline: 'none'
        };
        // Set initial style
        payment.setNumberStyle(HostedInputStyle);
        payment.setCvvStyle(HostedInputStyle);

        payment.setNumberPlaceholder('Card Number');
        payment.setCvvPlaceholder('CVV');
        // Focus events
        payment.numberOn('focus', () => {
          numberFocused = true;
          payment.setNumberStyle(focussedInputStyle);
        });
        payment.cvvOn('focus', () => {
          cvvFocused = true;
          payment.setCvvStyle(focussedInputStyle);
        });

        // Blur events
        payment.numberOn('blur', () => {
          numberFocused = false;
          payment.setNumberStyle(HostedInputStyle);
        });
        payment.cvvOn('blur', () => {
          cvvFocused = false;
          payment.setCvvStyle(HostedInputStyle);
        });

        // Hover events
        payment.numberOn('mouseover', () => {
          // Don't override style if element is already focused
          if (!numberFocused) {
            payment.setNumberStyle(focussedInputStyle);
          }
        });
        payment.numberOn('mouseout', () => {
          // Don't override style if element is already focused
          if (!numberFocused) {
            payment.setNumberStyle(HostedInputStyle);
          }
        });
        payment.cvvOn('mouseover', () => {
          // Don't override style if element is already focused
          if (!cvvFocused) {
            payment.setCvvStyle(focussedInputStyle);
          }
        });
        payment.cvvOn('mouseout', () => {
          // Don't override style if element is already focused
          if (!cvvFocused) {
            payment.setCvvStyle(HostedInputStyle);
          }
        });
        setInitialising(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tillPaymentScriptStatus]);

  const trackingData = JSON.parse(localStorage.getItem('tracking_consultation_summary') || '{}');

  const sendBrazeEvent = (eventName: string) => {
    try {
      if (!data?.email) {
        return;
      }
      brazeChangeUser(data.email);
      brazeTrackEvent(eventName, data ?? {});
    } catch (error) {
      console.error(`Error sending event to Braze.`, error);
    }
  };

  async function handleSubmit(payload: Payload) {
    const tillData = {
      card_holder: payload.cardHolder,
      month: payload.expiry!.toFormat('MM'),
      year: payload.expiry!.toFormat('yy')
    };

    setIsLoading(true);

    try {
      tillPayment.tokenize(
        tillData, //additional data, MUST include card_holder (or first_name & last_name), month and year
        async (token: string, cardData: { full_name: string }) => {
          try {
            const result = await registerPaymentExecuteMutation({
              registerPaymentInput: {
                transactionToken: token,
                merchantTransactionId: data.inviteeUuid || '',
                merchantTransactionDescription: 'alternaleaf',
                email: data.email || '',
                nameOnCard: cardData.full_name,
                currency: Currency.Aud,
                country: Country.Au,
                paymentType: PaymentType.Card
              }
            });

            const registerPaymentResult = result.data?.registerPayment;
            if (registerPaymentResult?.success) {
              sendBrazeEvent('consult-payment-success');

              if (registerPaymentResult?.redirectUrl) {
                window.location.replace(registerPaymentResult.redirectUrl);
              }
            } else {
              console.log('PAYMENT FAILURE');
              console.dir(registerPaymentResult);
              setPaymentError(true);
              setIsLoading(false);
              const errors: string[] = [];

              if (registerPaymentResult?.errors && registerPaymentResult?.errors.length > 0) {
                errors.push(...registerPaymentResult.errors.map((rawError) => rawError.errorMessage));
              } else {
                errors.push('Unknown register payment error');
              }
              sendBrazeEvent('consult-payment-failure');

              sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.PAYMENT_DETAILS_ERROR, {
                id: GoogleAnalyticsEventId.PAYMENT_DETAILS_ERROR,
                status: 'payment_error',
                error_code: 'TILL_PAYMENT_FAILURE',
                error_message: 'Till payment failure. Tracking fired from Payment page',
                ...trackingData
              });
            }
          } catch (e) {
            console.error(e);
            setPaymentError(true);
            setIsLoading(false);
            const errors: string[] = [];
            if (typeof e === 'string') {
              errors.push(e);
            } else if (e instanceof Error) {
              errors.push(e.message);
            } else {
              errors.push('Caught unknown Exception');
            }
            sendBrazeEvent('consult-payment-failure');

            sendGoogleAnalyticsEvent(GoogleAnalyticsEventName.PAYMENT_DETAILS_ERROR, {
              id: GoogleAnalyticsEventId.PAYMENT_DETAILS_ERROR,
              status: 'payment_error',
              error_code: 'TILL_UNKNOWN_ERROR',
              error_message: 'Till unknown error. Tracking fired from Payment page',
              ...trackingData
            });
          }
        },
        // The following handles errors with Till Payment Gateway
        (errors: TillCardError[]) => {
          //error callback function
          setCreditCardError(true);
          setErrorMessages(
            errors.map((error: TillCardError) => {
              if (isTillErrorMatch(possibleTillCardErrors.cardNumberInvalid, error)) {
                return 'Invalid card details';
              }
              if (isTillErrorMatch(possibleTillCardErrors.cvvInvalid, error)) {
                return 'Invalid Security Code';
              }
              if (isTillErrorMatch(possibleTillCardErrors.cvvEmpty, error)) {
                return 'Security Code must not be empty';
              }
              return error.message;
            })
          );
          setIsLoading(false);
          return false;
        }
      );
    } catch {
      setPaymentError(true);
    }
  }

  const Notice = () => {
    return (
      <>
        {/* Errors with the credit card that should be resolved by the users */}
        {creditCardError && !paymentError && (
          <Alert variant="outlined" severity="error">
            There was an issue processing the card.{' '}
            {errorMessages.map((errorMessage, key) => (
              <li key={key}>{errorMessage}</li>
            ))}
            Please double check your card details and retry.
          </Alert>
        )}

        {/* Issues with our payment gateway */}
        {paymentError && (
          <Alert variant="outlined" severity="error">
            There was an issue processing the card and you have not been charged. Please refresh page and try again.
          </Alert>
        )}
      </>
    );
  };

  return (
    <InitialisingContext.Provider value={{ initialising, setInitialising }}>
      <FormContainer>
        {isLoading && (
          <LoaderBlock>
            <CircularProgress color="info" size="6rem" />
          </LoaderBlock>
        )}
        <Notice />
        <PaymentForm
          data={data}
          onSubmit={handleSubmit}
          setCreditCardError={setCreditCardError}
          setErrorMessages={setErrorMessages}
          isPaymentTrackingTriggered={isPaymentTrackingTriggered}
        />
      </FormContainer>
    </InitialisingContext.Provider>
  );
}
