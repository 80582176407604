import { IhiReasonDialog } from '@/components/tables/IhiReviewLogsTable/IhiReasonDialog';
import { useTableUtils } from '@/components/tables/hooks/useTableUtils';
import { useGetIhiReviewLog } from '@/hooks/patients/useGetIhiReviewLog';
import { StatusChip } from '@/shared-ui';
import type { ConfirmDialogProps, PaginationModel } from '@montugroup/design-system';
import { ConfirmDialog, PaginationVariant, SearchTextField, Table } from '@montugroup/design-system';
import type { IHIReviewLogDto } from '@montugroup/pms-api-contracts';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Link, Stack, TextField, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DateTime } from 'luxon';
import type { ShowFnOutput } from 'mui-modal-provider';
import { useModal } from 'mui-modal-provider';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

export enum IhiReviewLogsTableColumnHeaders {
  Id = 'ID',
  IhiNumber = 'IHI Number',
  IhiError = 'Error',
  IhiAssignedDate = 'Assigned Date',
  ResolvedReason = 'Resolved Reason',
  PatientId = 'Patient ID',
  ResolverId = 'Resolver ID',
  Resolution = 'Resolution'
}

type IhiReviewLogsTableProps = {
  pageSize?: number;
  filters?: {
    patientId?: number;
    isResolved?: boolean;
    resolverId?: number | null;
  };
};
export type ResponseCursors = {
  next: string | null;
  prev: string | null;
};
export const IhiReviewLogsTable = ({ pageSize = 50 }: IhiReviewLogsTableProps) => {
  const [cursors, setCursors] = useState<{
    next: string | null;
    prev: string | null;
  } | null>(null);

  const [searchParams, setSearchParams] = useState<{
    patientId: string;
    resolverId: string;
    isResolved: boolean | null;
  }>({
    patientId: '',
    resolverId: '',
    isResolved: null
  });

  const { createColumnDefinition } = useTableUtils();
  const { showModal } = useModal();

  const columns = [
    createColumnDefinition('resolution', IhiReviewLogsTableColumnHeaders.Resolution),
    createColumnDefinition('patient', IhiReviewLogsTableColumnHeaders.PatientId),
    createColumnDefinition('ihi_error', IhiReviewLogsTableColumnHeaders.IhiError),
    createColumnDefinition('ihi_number', IhiReviewLogsTableColumnHeaders.IhiNumber),
    createColumnDefinition('ihi_assigned_date', IhiReviewLogsTableColumnHeaders.IhiAssignedDate),
    createColumnDefinition('resolver_id', IhiReviewLogsTableColumnHeaders.ResolverId),
    createColumnDefinition('resolved_reason', IhiReviewLogsTableColumnHeaders.ResolvedReason)
  ];

  const pageParams = {
    size: pageSize,
    after: cursors?.next ?? undefined,
    before: cursors?.prev ?? undefined
  };

  const patientId = searchParams.patientId?.trim();
  const resolverId = searchParams.resolverId?.trim();

  const filterParams = {
    ...(searchParams.isResolved ? { isResolved: true } : {}),
    ...(patientId?.length ? { patientId: Number(patientId) } : {}),
    ...(resolverId?.length ? { resolverId: Number(resolverId) } : {})
  };

  /*
    Not needed in this phase but may be used in the future.
    Keeping this here to let others know it's an option
   */
  // const sortParams = {
  //   field: null,
  //   direction: null
  // };

  const { data, isLoading, isError } = useGetIhiReviewLog(pageParams, filterParams);

  const responseData = data?.body.data ?? [];
  const [responseCursors, setResponseCursors] = useState<ResponseCursors>();

  useEffect(() => {
    const cursor = data?.body?.meta?.page?.cursor;
    if (cursor) {
      setResponseCursors(cursor);
    }
  }, [data]);
  const formatCellAssignedDate = (reviewLog: IHIReviewLogDto) => {
    return reviewLog.ihi_assigned_date ? (
      <Typography>{DateTime.fromISO(reviewLog.ihi_assigned_date).toFormat('MM/dd/yy - h:mma')}</Typography>
    ) : (
      '-'
    );
  };

  const formatCellResolution = (reviewLog: IHIReviewLogDto) =>
    reviewLog.resolution_by ? (
      <StatusChip label="Yes" color="success" showIcon={false} />
    ) : (
      <StatusChip label="No" color="error" showIcon={false} />
    );

  const formatCellResolverId = (reviewLog: IHIReviewLogDto) =>
    reviewLog.resolution_by ? (
      <Typography>
        {reviewLog.resolution_by.first_name} {reviewLog.resolution_by.last_name} ({reviewLog.resolution_by.id})
      </Typography>
    ) : (
      '-'
    );

  const formatResolvedReason = (reviewLog: IHIReviewLogDto) =>
    reviewLog?.resolved_reason ? (
      <Button variant="outlined" onClick={() => handleViewReasonDialog(reviewLog)}>
        View
      </Button>
    ) : (
      <Button variant="contained" endIcon={<AddIcon />} onClick={() => handleAddReasonDialog(reviewLog)}>
        Add reason
      </Button>
    );

  const formatPatientId = (reviewLog: IHIReviewLogDto) => {
    const patientId = reviewLog?.patient?.id;
    return (
      <Typography>
        <Link href={`/patients/${patientId}`} target="_blank" color={'secondary'}>
          {patientId}
        </Link>
      </Typography>
    );
  };

  const formattedTableData = responseData.map((item: IHIReviewLogDto) => ({
    resolution: formatCellResolution(item),
    patient: formatPatientId(item),
    ihi_error: <Typography>{item.ihi_error}</Typography>,
    ihi_number: <Typography>{item.ihi_number}</Typography>,
    ihi_assigned_date: formatCellAssignedDate(item),
    resolver_id: formatCellResolverId(item),
    resolved_reason: formatResolvedReason(item)
  }));

  const handlePaginationChange = ({ page: isNewPage }: PaginationModel) => {
    const cursorKey = isNewPage > 0 ? 'next' : 'prev';

    if (responseCursors?.[cursorKey]) {
      if (cursorKey === 'next') {
        setCursors({ prev: null, next: responseCursors[cursorKey] });
      } else {
        setCursors({ prev: responseCursors[cursorKey], next: null });
      }
    }
  };

  const handleSearchChange = (field: 'patientId' | 'resolverId', value: string) => {
    setCursors({
      next: null,
      prev: null
    });
    setSearchParams((prev) => ({ ...prev, [field]: value }));
  };

  const handleCheckboxShowOnlyResolved = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchParams((prev) => ({ ...prev, isResolved: event.target.checked }));
  };

  const handleModalClose = (modal: ShowFnOutput<ConfirmDialogProps>) => {
    modal.hide();
  };

  const handleAddReasonDialog = (reviewLog: IHIReviewLogDto) => {
    const patientIdText = `Patient ID ${reviewLog?.patient?.id}`;
    const modal = showModal(ConfirmDialog, {
      id: 'addReasonDialog',
      title: '', // Title needed custom styling, so leaving this blank and adding Title to children prop
      children: (
        <>
          <Stack marginBottom={2} flexDirection={'row'} justifyContent={'space-between'}>
            <Typography variant={'h5'} fontWeight={'bold'} color={'primary'}>
              Add Resolved Reason - {patientIdText}
            </Typography>
            <Box sx={{ cursor: 'pointer' }}>
              <CloseIcon onClick={() => handleModalClose(modal)} color={'disabled'} />
            </Box>
          </Stack>
          <IhiReasonDialog reviewLog={reviewLog} handleModalClose={() => handleModalClose(modal)} />
        </>
      ),
      actions: (
        <Box marginBottom={3} marginRight={4}>
          <Button variant="contained" color="secondary" type="submit" form="resolution-reason-form">
            Save
          </Button>
          <Button variant="text" color="secondary" onClick={() => handleModalClose(modal)}>
            Cancel
          </Button>
        </Box>
      )
    });
  };

  const handleViewReasonDialog = (reviewLog: IHIReviewLogDto) => {
    const patientIdText = `Patient ID ${reviewLog?.patient?.id}`;
    const resolvedByText = `${reviewLog.resolution_by?.first_name} ${reviewLog.resolution_by?.last_name}`;
    const modal = showModal(ConfirmDialog, {
      id: 'viewReasonDialog',
      title: '', // Title needed custom styling, so leaving this blank and adding Title to children prop
      children: (
        <Box width={'590px'} marginRight={3}>
          <Stack marginBottom={5} flexDirection={'row'} justifyContent={'space-between'}>
            <Typography variant={'h5'} fontWeight={'bold'} color={'primary'}>
              Resolved Reason - {patientIdText}
            </Typography>
            <Box sx={{ cursor: 'pointer' }}>
              <CloseIcon onClick={() => handleModalClose(modal)} color={'disabled'} />
            </Box>
          </Stack>
          <TextField multiline fullWidth minRows={10} value={reviewLog.resolved_reason} disabled />
        </Box>
      ),
      actions: (
        <Box marginBottom={3} marginRight={4} flexDirection={'row'}>
          <TextField disabled label="Resolved by:" value={resolvedByText} />
        </Box>
      )
    });
  };

  return (
    <Box>
      {!isError && (
        <>
          <Stack direction={'row'} alignItems={'center'}>
            <Box marginRight={5}>
              <SearchTextField
                handleChange={(e) => handleSearchChange('patientId', e.target.value)}
                handleClear={() => handleSearchChange('patientId', '')}
                labelText="Search by Patient ID"
                enableClear={true}
              />
            </Box>
            <Box marginRight={5}>
              <SearchTextField
                handleChange={(e) => handleSearchChange('resolverId', e.target.value)}
                handleClear={() => handleSearchChange('resolverId', '')}
                labelText="Search by Resolver ID"
                enableClear={true}
              />
            </Box>
            <FormControlLabel
              control={
                <Checkbox checked={searchParams.isResolved ?? false} onChange={handleCheckboxShowOnlyResolved} />
              }
              label="Show only resolved"
            />
          </Stack>
          <Table
            data={formattedTableData}
            columns={columns}
            isLoading={isLoading}
            pageSize={50}
            showPagination
            noDataFoundMessage={'No matching ihi review logs found'}
            onPaginationModelChange={handlePaginationChange}
            paginationVariant={PaginationVariant.UNKNOWN_PAGE_SIZE}
            hasPrevPage={Boolean(responseCursors?.prev)}
            hasNextPage={Boolean(responseCursors?.next)}
          />
        </>
      )}
      {isError && <Alert severity="error">There has been an error loading the Ihi Review Logs table</Alert>}
    </Box>
  );
};
