import type { AxiosError, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { useCallback, useEffect, useReducer, useRef } from 'react';

type Action<IData, IError> =
  | { type: 'requestStart' }
  | { type: 'requestSuccess'; data: DataType<IData> }
  | { type: 'requestError'; error: ErrorType<IError> }
  | { type: 'setData'; data: DataType<IData> };

type ErrorType<T> = AxiosError<T> | Error | string | null;

type DataType<T> = T | null;

type State<IData, IError> = {
  loading: boolean;
  data: DataType<IData>;
  error: ErrorType<IError>;
};

type AsyncReturnResult<IData, IError> = { data: DataType<IData>; error: ErrorType<IError> };

function reducer<IData, IError>(_state: State<IData, IError>, action: Action<IData, IError>): State<IData, IError> {
  switch (action.type) {
    case 'requestStart':
      return {
        data: null,
        loading: true,
        error: null
      };
    case 'requestError':
      return {
        data: null,
        loading: false,
        error: action.error
      };
    case 'requestSuccess':
    case 'setData':
      return {
        data: action.data,
        loading: false,
        error: null
      };
  }
}

const defaultState = {
  data: null,
  loading: false,
  error: null
};

type UseRequestProps = {
  config: Pick<AxiosRequestConfig, 'url' | 'method'>;
};

type ExecuteConfig = Omit<AxiosRequestConfig, 'url' | 'method'>;

// TODO: Consider using a dependency here such as:
// - https://www.npmjs.com/package/@tanstack/react-query
// - https://www.npmjs.com/package/axios-hooks
/**
 * @param root0
 * @param root0.config
 * @deprecated - use @tanstack/react-query pattern instead
 */
export function useRequest<IData, IError = unknown>({ config }: UseRequestProps) {
  const abortControllerRef = useRef<AbortController>();
  const [state, dispatch] = useReducer<React.Reducer<State<IData, IError>, Action<IData, IError>>>(
    reducer,
    defaultState
  );

  const execute = useCallback(
    async (executeConfig?: ExecuteConfig): Promise<AsyncReturnResult<IData, IError>> => {
      try {
        dispatch({ type: 'requestStart' });
        // Assumes Authorization token is set with axios.defaults.headers.common
        const response = await axios.request<IData>({
          ...{ url: config.url, method: config.method },
          ...{
            signal: abortControllerRef?.current?.signal
          },
          ...executeConfig
        });
        dispatch({ type: 'requestSuccess', data: response.data });
        return { data: response.data, error: null };
      } catch (e) {
        console.error('Caught error in useRequest execution', { error: e, url: config.url });
        const knownError = axios.isAxiosError(e) || e instanceof Error ? e : String(e) || 'Unknown request error';
        if (!axios.isCancel(e)) {
          // Only update state / re-render if the request was not cancelled
          dispatch({ type: 'requestError', error: knownError });
        }
        return { data: null, error: knownError };
      }
    },
    [config.method, config.url]
  );

  // https://tanstack.com/query/v4/docs/react/guides/updates-from-mutation-responses
  const setData = useCallback((data: DataType<IData>) => dispatch({ type: 'setData', data }), [dispatch]);

  useEffect(() => {
    abortControllerRef.current = new AbortController();
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  return { ...state, execute, setData };
}

export default useRequest;
