import { requestHeaders } from '@/api';
import settings from '@/data/constants';
import { Logger } from '@/utils/logger';

import type { consultationContract } from '@montugroup/pms-api-contracts';
import type { z } from 'zod';

export type CreateInstantConsultationParams = z.infer<
  typeof consultationContract.createInstantConsultationContract.body
>;
export type CreateInstantConsultationResponse = z.infer<
  (typeof consultationContract.createInstantConsultationContract.responses)['201']
>;

const logger = new Logger('createInstantConsultation.ts');

export const createInstantConsultation = async ({ patient_id }: CreateInstantConsultationParams) => {
  const createInstantConsultationUrl = `${settings.baseXApiUrl}/api/pms/v1/consultations/instant`;

  try {
    const createInstantConsultationResponse = await fetch(createInstantConsultationUrl, {
      method: 'POST',
      headers: requestHeaders(),
      body: JSON.stringify({ patient_id })
    });

    if (!createInstantConsultationResponse.ok) {
      throw new Error(`Error: ${createInstantConsultationResponse.status}`);
    }

    const json = await createInstantConsultationResponse.json();
    const result = json as CreateInstantConsultationResponse;

    return result;
  } catch (error) {
    logger.error('Error creating instant consultation', error);
    throw new Error(`Failed to create instant consultation: ${error instanceof Error ? error.message : error}`);
  }
};
