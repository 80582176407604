import CloseIcon from '@mui/icons-material/Close';
import type { DialogProps } from '@mui/material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

import theme from '@/theme';
import type { ConcessionCardFormProps } from '..';
import { ConcessionCardForm } from '..';
import type useConcessionCardForm from '../../../hooks/concession/useConcessionCardForm';

type ConcessionSubmit = ConcessionCardFormProps['onSubmit'];

export type ConcessionDialogProps = {
  cardFormProps?: Parameters<typeof useConcessionCardForm>[0];
  open: DialogProps['open'];
  alert?: React.ReactNode;
  onClose: DialogProps['onClose'];
  onSubmitConcession: ConcessionSubmit;
  onCancel: () => void;
};

export const ConcessionDialog = ({
  open,
  onClose,
  onSubmitConcession,
  onCancel,
  alert,
  cardFormProps
}: ConcessionDialogProps) => {
  const handleSubmitCard: ConcessionCardFormProps['onSubmit'] = async (data) => {
    return onSubmitConcession(data);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box display="flex" marginTop={theme.spacing(10)}>
          <Typography variant="h5" component="h2" color={theme.palette.primary.main}>
            Add Concession Card
          </Typography>
        </Box>
        <IconButton onClick={(e) => onClose?.(e, 'backdropClick')} sx={{ alignSelf: 'start' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {alert}
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(5)
        }}
      >
        <Typography variant="body1" sx={{ marginBottom: theme.spacing(6) }}>
          Please select from the dropdown if this patient is eligible for any of the following concessions.
        </Typography>
        <Box>
          <ConcessionCardForm onSubmit={handleSubmitCard} cardFormProps={cardFormProps} onCancel={onCancel} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConcessionDialog;
