import CalendarPopupButton from './CalendarPopupButton';

export type CalendarCancelPopupButtonProps = {
  cancelBookingUrl: string | null;
  onClose?: () => void;
  isButton?: boolean;
};

const CalendarCancelPopupButton = ({
  cancelBookingUrl,
  onClose,
  isButton,
  ...rest
}: CalendarCancelPopupButtonProps) => {
  return cancelBookingUrl ? (
    <CalendarPopupButton
      isButton={isButton}
      link={cancelBookingUrl}
      onBookingCancelSuccessful={onClose}
      {...rest}
      data-testid="cancel-popup-trigger"
    >
      Cancel
    </CalendarPopupButton>
  ) : null;
};

export default CalendarCancelPopupButton;
