import { useAppStore } from '@/state-management';
import { PaginationLabelVariant, Table } from '@montugroup/design-system';
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useFilteredPrescribedProducts } from '@/hooks/filteredPrescribedProducts';
import type { PrescribedMedicationSchema } from '@montugroup/prescription-contracts';
import { MedicationPrescriptionStatus } from '@montugroup/prescription-contracts';
import { useSortedPrescribedProducts } from '../../../../hooks/sortedPrescribedProducts/useSortedPrescribedProducts';
import { AddAdditionalMedicationButton } from '../AddAdditionalMedicationButton/AddAdditionalMedicationButton';
import {
  EMPTY_ALL_ACTIVE_MESSAGE,
  EMPTY_ALL_MESSAGE,
  formatTableColumns,
  ROWS_PER_PAGE_MULTIPLE
} from './PrescribedMedicationsTabsTable.config';
import type { PrescribedMedicationsTabsTableProps, TabKey } from './PrescribedMedicationsTabsTable.types';
import {
  getActiveProducts,
  getItemIdsToAction,
  getPageSizeForActiveProducts,
  getPaginationMultipleOptions
} from './PrescribedMedicationsTabsTable.utils';

/**
 * Used to display the prescriptions table
 *
 * @param headings - column definition as per https://mui.com/x/react-data-grid/column-definition/
 * @param products - row definition as per https://mui.com/x/react-data-grid/row-definition/
 * @param pageSize - no. of items per page
 * @param pageSizeOptions - page size intervals
 * @param onClick - handle reissue button click
 */

const PrescribedMedicationsTabsTable = ({
  headings,
  products,
  onReissueClick,
  onViewDossierClick,
  onClickCancel,
  onAdditionalMedicationClick,
  isAddAdditionalMedicationDisabled,
  pageSize = ROWS_PER_PAGE_MULTIPLE,
  prescribedProductIds = [],
  tabs = [
    { label: 'All Active', key: 'all-active' },
    { label: 'All', key: 'all' }
  ],
  onTabChange,
  initialTabIndex = 0
}: PrescribedMedicationsTabsTableProps) => {
  const [tabIndex, setTabIndex] = useState(initialTabIndex);
  const {
    prescriptionPad: { medicationPadItems },
    prescriptionHistory: { itemsToCancel, itemsToReissue }
  } = useAppStore.use.prescriptionAssistant();
  const updatePrescriptionHistoryItemsToAction = useAppStore.use.updatePrescriptionHistoryItemsToAction();
  const updateMedicationPadItems = useAppStore.use.updateMedicationPadItems();

  const selectedTabKey: TabKey = tabs[tabIndex].key;

  const { data: filteredProducts } = useFilteredPrescribedProducts(products, selectedTabKey);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    if (onTabChange && tabs[newValue] !== undefined) {
      onTabChange(tabs[newValue]);
    }
  };

  const cellOptions = {
    onReissueClick,
    onViewDossierClick,
    onClickCancel,
    prescribedProductIds,
    itemsToReissue,
    itemsToCancel
  };
  const formattedColumns = useMemo(
    () => formatTableColumns(headings, cellOptions),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onReissueClick]
  );

  const itemProductIds = useMemo(() => {
    const reissueProductIds = itemsToReissue.map((item) => Number(item.medicationPadItem?.productId));
    const cancelProductIds = itemsToCancel.map((item) => Number(item.productId));
    const activeProducts = getActiveProducts(filteredProducts);
    const activeProductIds = activeProducts.map((product: PrescribedMedicationSchema) => Number(product.id));
    return {
      reissueProductIds,
      cancelProductIds,
      activeProductIds
    };
  }, [itemsToReissue, itemsToCancel, filteredProducts]);

  useEffect(() => {
    const itemIdsToAction = getItemIdsToAction({
      itemsToReissueProductIds: itemProductIds.reissueProductIds,
      itemsToCancelProductIds: itemProductIds.cancelProductIds,
      activeProductsIds: itemProductIds.activeProductIds
    });
    updatePrescriptionHistoryItemsToAction(itemIdsToAction);
  }, []);

  const sortedProducts = useSortedPrescribedProducts(filteredProducts);
  const itemsPerPageMultipleOptions = useMemo(
    () => getPaginationMultipleOptions(sortedProducts.length),
    [sortedProducts]
  );
  const activeProducts = sortedProducts.filter((item) => item.status === MedicationPrescriptionStatus.Active);

  if (selectedTabKey === 'all-active' && activeProducts.length > 0) {
    pageSize = getPageSizeForActiveProducts(activeProducts.length);
  }

  // display empty medication item if no prescribed medications found
  useEffect(() => {
    if (Boolean(sortedProducts.length) || Boolean(medicationPadItems?.length)) {
      return;
    }

    updateMedicationPadItems([
      {
        medicationPadItemId: uuidv4()
      }
    ]);

    if (activeProducts.length === 0) {
      setTabIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedProducts, medicationPadItems]);

  // if no products found, display add medication button
  if (!products.length) {
    return (
      <Stack spacing={6} py={2}>
        <AddAdditionalMedicationButton
          onClick={onAdditionalMedicationClick}
          isDisabled={isAddAdditionalMedicationDisabled}
        />
      </Stack>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body1" color="textSecondary" marginBottom={4}>
        To create a new prescription, first select your desired{' '}
        <Box component="span" fontWeight="bold">
          action on all active prescriptions
        </Box>{' '}
        for the patient
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="all-active-or-all-medication-tabs"
        >
          {tabs.map((tab) => (
            <Tab key={uuidv4()} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      <Table
        FooterLeftElement={
          <AddAdditionalMedicationButton
            onClick={onAdditionalMedicationClick}
            isDisabled={isAddAdditionalMedicationDisabled}
          />
        }
        columns={formattedColumns}
        elevation={0}
        data={sortedProducts}
        noDataFoundMessage={selectedTabKey === 'all-active' ? EMPTY_ALL_ACTIVE_MESSAGE : EMPTY_ALL_MESSAGE}
        pageSize={pageSize}
        pageSizeOptions={itemsPerPageMultipleOptions}
        showPagination
        hasRowBackgroundColor={false}
        paginationLabelVariant={PaginationLabelVariant.PAGE}
        compact
      />
    </Box>
  );
};

export default PrescribedMedicationsTabsTable;
