import { Logger } from '@/utils/logger';
import LoadingButton from '@mui/lab/LoadingButton';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

interface EnhancedToolbarProps {
  numSelected: number;
  onClick: () => Promise<void>;
}

const EnhancedToolbar = ({ numSelected, onClick }: EnhancedToolbarProps) => {
  const logger = new Logger('EnhancedToolbar');
  const [doingWork, setDoingWork] = useState(false);

  const handleClick = async () => {
    try {
      setDoingWork(true);
      await onClick();
    } catch (error) {
      logger.error('DSR EnhancedToolbar click handler error', error);
    } finally {
      setDoingWork(false);
    }
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}
    >
      {numSelected > 0 || doingWork ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle2">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="subtitle2" color="primary" id="tableTitle">
          Use the checkboxes to select scripts for approval
        </Typography>
      )}
      {(numSelected > 0 || doingWork) && (
        <Tooltip title="Approve Selected">
          <LoadingButton
            variant="contained"
            color="success"
            sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
            onClick={handleClick}
            disabled={doingWork}
            loading={doingWork}
          >
            Approve Selected
          </LoadingButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export { EnhancedToolbar };
