import { GetPatientDetailsSchema } from '@montugroup/pms-api-contracts';

export const schema = GetPatientDetailsSchema.shape.medicalInfo
  .unwrap()
  .shape.attributes.pick({
    alcoholPerWeek: true,
    smoking: true,
    allergies: true,
    cardioDisease: true,
    comments: true,
    symptoms: true,
    currentMedications: true,
    conditions: true,
    medications: true
  })
  .partial();
