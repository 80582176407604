import type { CreateInstantConsultationParams } from '@/api/consultations/createInstantConsultation';
import { createInstantConsultation } from '@/api/consultations/createInstantConsultation';
import { QueryKey } from '@/data/query';
import type { Query } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useCreateInstantConsultation() {
  const queryClient = useQueryClient();

  const createInstantConsultationMutation = useMutation({
    mutationFn: async (params: CreateInstantConsultationParams) => {
      return await createInstantConsultation(params);
    },
    onSuccess: () => {
      const nonStaticQueries = (query: Query) => {
        // Will target any Get Consultation query
        return query.queryKey.includes(QueryKey.Consultations);
      };

      queryClient.invalidateQueries({ predicate: nonStaticQueries }, { cancelRefetch: true });
    },
    retry: false
  });

  return {
    mutate: createInstantConsultationMutation.mutate,
    mutateAsync: createInstantConsultationMutation.mutateAsync,
    isPending: createInstantConsultationMutation.isPending,
    isError: createInstantConsultationMutation.isError,
    isSuccess: createInstantConsultationMutation.isSuccess,
    data: createInstantConsultationMutation.data
  };
}
