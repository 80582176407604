import type { RescriptProduct } from '@/api/prescriptions/putPrescriptionsBatchReplace';
import type { ScriptProduct } from '@/types';

const scriptProductToRescriptProduct = (product: ScriptProduct): RescriptProduct => {
  return {
    productId: product.id,
    repeats: product.repeats,
    quantity: product.quantity,
    interval: product.interval,
    dosage: product.dosage,
    name: product.name,
    isConcession: product.is_concession,
    otherProduct: product.other_product,
    isPatientRescriptReq: !!product.patient_rescript_request_date
  };
};

export { scriptProductToRescriptProduct };
