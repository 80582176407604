import { zodResolver } from '@hookform/resolvers/zod';
import { Box, styled } from '@mui/material';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, RadioButtonGroup, TextFieldElement, TextareaAutosizeElement } from 'react-hook-form-mui';
import type { z } from 'zod';

import type { PatientDetailsData } from '@/hooks/patients/useGetPatientDetails';
import { EditModeContext, withEditModeContextProvider } from '../../EditModeContextProvider';
import EditableSections from '../../EditableSections';
import { schema } from './MedicalSummary.schema';

const flexOptions = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
};

const StyledBox = styled(Box)({
  width: '100%',
  marginTop: 10,
  display: 'flex',
  flexDirection: 'column'
});

const StyledRadioBox = styled(Box)({
  '& .MuiFormControl-root': {
    ...flexOptions,
    alignItems: 'center',

    '.MuiFormGroup-root': {
      flexDirection: 'row',
      flexWrap: 'nowrap'
    }
  }
});

const StyledBoxSplit = styled(StyledBox)(flexOptions as never);

type FormFields = z.infer<typeof schema>;

type MedicalSummaryProps = {
  editable?: boolean;
  defaultValues?: PatientDetailsData;
  onSubmit: (data: FormFields) => void;
};

function MedicalSummary({ editable = true, defaultValues, onSubmit }: MedicalSummaryProps) {
  const { isEditMode } = useContext(EditModeContext);
  const defaultMedicalInfoValues = defaultValues?.medicalInfo?.attributes;
  const formContext = useForm<FormFields>({
    values: {
      ...defaultMedicalInfoValues,
      allergies: defaultMedicalInfoValues?.allergies ?? 'No',
      cardioDisease: defaultMedicalInfoValues?.cardioDisease ?? 'No'
    },
    resolver: zodResolver(schema)
  });
  return (
    <EditableSections title="Medical Summary" editable={editable} formContext={formContext} actions={{ onSubmit }}>
      <FormContainer formContext={formContext}>
        <StyledBox>
          <TextareaAutosizeElement
            label="Presenting Complaint"
            name="conditions"
            disabled={!isEditMode}
            resizeStyle="vertical"
          />
        </StyledBox>
        <StyledBox>
          <TextareaAutosizeElement label="Symptoms" name="symptoms" disabled={!isEditMode} resizeStyle="vertical" />
        </StyledBox>
        <StyledBox>
          <TextareaAutosizeElement
            label="Trialled Medication"
            name="medications"
            disabled={!isEditMode}
            resizeStyle="vertical"
          />
        </StyledBox>
        <StyledBox>
          <TextareaAutosizeElement
            label="Current Medication List"
            name="currentMedications"
            disabled={!isEditMode}
            resizeStyle="vertical"
          />
        </StyledBox>
        <StyledRadioBox>
          <RadioButtonGroup
            name="allergies"
            label="Allergies"
            options={[
              { id: 'Yes', label: 'Yes' },
              { id: 'No', label: 'No' }
            ]}
            disabled={!isEditMode}
          />
        </StyledRadioBox>
        <StyledRadioBox>
          <RadioButtonGroup
            name="cardioDisease"
            label="Cardiovascular Disease"
            options={[
              { id: 'Yes', label: 'Yes' },
              { id: 'No', label: 'No' }
            ]}
            disabled={!isEditMode}
          />
        </StyledRadioBox>
        <StyledBox>
          <TextareaAutosizeElement
            label="Other Medical History"
            name="comments"
            disabled={!isEditMode}
            resizeStyle="vertical"
          />
        </StyledBox>
        <StyledBoxSplit>
          <TextFieldElement label="ETHO/Week" name="alcoholPerWeek" type="number" disabled={!isEditMode} />
          <TextFieldElement label="Smoking Hx" name="smoking" disabled={!isEditMode} />
        </StyledBoxSplit>
      </FormContainer>
    </EditableSections>
  );
}

export default withEditModeContextProvider(MedicalSummary);
