import type { ChangeEventHandler } from 'react';
import { useRef, useState } from 'react';

import { getConsultationType } from '../../assets/js/util';
import { isAdmin, isDoctor, isNurse, isSuperAdmin } from '../../data/service/authService';
import useConcessionConsultationPrice from '../concession/useConcessionConsultationPrice';
import usePostPaymentsChargeForConsult from '../rest/usePostPaymentsChargeForConsult';

import { Logger } from '@/utils/logger';
import { datadogRum } from '@datadog/browser-rum';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import type { ConsultPaymentMethod } from './useConsult';
import useConsultationPrice from './useConsultationPrice';

const logger = new Logger('PMS:useChargeConsult');

// Locally type the javascript util
type GetConsultationTypeReturn = '-' | 'Initial - Doctor' | 'Initial - Nurse' | 'Follow-Up Doctor' | 'Follow-Up Nurse';

const consultDiscountMin = 0;
const consultDiscountMax = 99;

// Don't allow a practitioner to submit a negative discount or a discount over 100%
function sanitizeDiscountRate(consultDiscountRate: string) {
  const parsed = parseFloat(consultDiscountRate) || 0;
  if (parsed > consultDiscountMin && parsed <= consultDiscountMax) {
    return String(parsed);
  }
  return '0';
}

// We want to only show the charge button for initial nurse appointments and follow up doctor appointments. All other
// appointments should be free.
function checkCanUserChargeConsult(userRoles: UserRoles, consultAndClinicianType: GetConsultationTypeReturn) {
  if (consultAndClinicianType === 'Initial - Nurse') {
    return userRoles.isAdmin || userRoles.isSuperAdmin || userRoles.isNurse || false;
  }
  if (consultAndClinicianType === 'Follow-Up Doctor') {
    return userRoles.isAdmin || userRoles.isSuperAdmin || userRoles.isDoctor || userRoles.isNurse || false;
  }
  return false;
}

type UserRoles = {
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isNurse: boolean;
  isDoctor: boolean;
};

// Mark everything as optional for now since this hook is consumed in a js page that optionally displays a specific
// consult.
export type UseChargeConsultProps = Partial<{
  consultationId: number;
  consultationType: 'Initial' | 'Follow-up';
  consultationPaymentMethod: ConsultPaymentMethod;
  doctorId: string | number;
  patientEmail: string;
}>;

// We allow clinicians to charge for consultations in a few different ways, hence consolidate the business logic
// here. They may choose to charge the original rate defined in harness feature flag, charge a concession rate or even
// provide a manually set discount rate. Additionally, depending on the type and state of a consult, different
// clinicians may be able to perform different actions.
export function useChargeConsult({
  consultationId,
  consultationType,
  consultationPaymentMethod,
  doctorId,
  patientEmail
}: UseChargeConsultProps) {
  const {
    consultationPrice: initialConsultationPrice,
    consultationChargePrice: initialConsultationChargePrice,
    formattedConsultationPrice: formattedInitialConsultationPrice,
    followUpConsultationPrice: followUpConsultationPrice,
    followUpConsultationChargePrice: followUpConsultationChargePrice,
    formattedFollowUpConsultationPrice: formattedFollowUpConsultationPrice
  } = useConsultationPrice();

  const {
    consultationPrice: concessionInitialConsultationPrice,
    formattedConsultationPrice: formattedConcessionInitialConsulationPrice,
    followUpConsultationPrice: concessionFollowUpConsultationPrice,
    formattedFollowUpConsultationPrice: formattedConcessionFollowUpConsultationPrice,
    discountRate: concessionConsultationDiscountRate
  } = useConcessionConsultationPrice();

  // Allow clinicians to manually provide whatever discount percent they want
  const [consultDiscountRate, setConsultDiscountRate] = useState('0');
  const [isComplimentary, setIsComplimentary] = useState(false);

  // Use state with a lazy initializer here to avoid hitting localStorage on every render
  const [userRoles] = useState<UserRoles>(() => {
    return {
      isAdmin: isAdmin(),
      isSuperAdmin: isSuperAdmin(),
      isNurse: isNurse(),
      isDoctor: isDoctor()
    };
  });

  const ldClient = useLDClient();

  const consultAndClinicianType = getConsultationType({
    consult_type: consultationType,
    doctor_id: doctorId
  }) as GetConsultationTypeReturn;
  const isFollowUpConsult = consultationType === 'Follow-up';

  const canUserChargeConsult = checkCanUserChargeConsult(userRoles, consultAndClinicianType);

  // Derive current consultation type prices
  const [
    consultationPrice,
    consultationChargePrice,
    formattedConsultationPrice,
    concessionConsultationPrice,
    formattedConcessionConsulationPrice
  ] = isFollowUpConsult
    ? [
        followUpConsultationPrice,
        followUpConsultationChargePrice,
        formattedFollowUpConsultationPrice,
        concessionFollowUpConsultationPrice,
        formattedConcessionFollowUpConsultationPrice
      ]
    : [
        initialConsultationPrice,
        initialConsultationChargePrice,
        formattedInitialConsultationPrice,
        concessionInitialConsultationPrice,
        formattedConcessionInitialConsulationPrice
      ];

  const discountInputProps = { min: consultDiscountMin, max: consultDiscountMax };

  // No need to be reactive as this is dependent on a click handler and axios loading state
  const lastHandle = useRef<null | 'consult' | 'concession'>(null);
  const { doPost: doPostChargeForConsult, loading: loadingCharge } = usePostPaymentsChargeForConsult();

  const isLoadingConsult = loadingCharge && lastHandle.current === 'consult';
  const isLoadingConcession = loadingCharge && lastHandle.current === 'concession';

  const handleChangeConsultDiscountRate: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    const newValue = e.target.value;
    const sanitizedValue = sanitizeDiscountRate(newValue);

    logger.info('handleChangeConsultDiscountRate', { newValue, sanitizedValue });

    // Prevents values outside the sanitizeDiscountRate range from being inputable in the discount % input
    if (!newValue || parseInt(newValue) === parseInt(sanitizedValue)) {
      setConsultDiscountRate(sanitizedValue);
    }
  };

  const doChargeConsult = async () => {
    if (!consultationId || !patientEmail) {
      logger.error('doChargeConsult', { consultationId, hasPatientEmail: !!patientEmail });
      return { error: new Error('Cannot charge consult with invalid props') };
    }
    lastHandle.current = 'consult';
    const result = await doPostChargeForConsult({
      consultationId,
      emailId: patientEmail,
      amount: consultationChargePrice,
      discountRate: isComplimentary ? '100' : consultDiscountRate,
      ...(isComplimentary && { discountType: 'COMPLIMENTARY' }),
      paymentMethod: consultationPaymentMethod
    });
    logger.info('doChargeConsult', {
      consultationId,
      amount: consultationChargePrice,
      discountRate: isComplimentary ? '100' : consultDiscountRate,
      ...(isComplimentary && { discountType: 'COMPLIMENTARY' }),
      paymentMethod: consultationPaymentMethod,
      ...result.data
    });
    ldClient?.track(
      'consultation-amount',
      {
        discountRate: isComplimentary ? '100' : consultDiscountRate,
        paymentMethod: consultationPaymentMethod,
        discountType: isComplimentary ? 'COMPLIMENTARY' : 'NONE',
        consultAndClinicianType
      },
      consultationChargePrice
    );
    datadogRum.addAction('consultation-amount', {
      discountRate: isComplimentary ? '100' : consultDiscountRate,
      paymentMethod: consultationPaymentMethod,
      discountType: isComplimentary ? 'COMPLIMENTARY' : 'NONE',
      amount: consultationChargePrice,
      consultAndClinicianType
    });
    return result;
  };

  const doChargeConcession = async () => {
    if (!consultationId || !patientEmail) {
      logger.error('doChargeConcession', { consultationId, hasPatientEmail: !!patientEmail });
      return { error: new Error('Cannot charge concession consult with invalid props') };
    }
    lastHandle.current = 'concession';
    ldClient?.track(
      'consultation-amount',
      {
        discountRate: concessionConsultationDiscountRate,
        paymentMethod: consultationPaymentMethod,
        discountType: 'CONCESSION',
        consultAndClinicianType
      },
      consultationChargePrice
    );
    datadogRum.addAction('consultation-amount', {
      discountRate: concessionConsultationDiscountRate,
      paymentMethod: consultationPaymentMethod,
      discountType: 'CONCESSION',
      amount: consultationChargePrice,
      consultAndClinicianType
    });
    const result = await doPostChargeForConsult({
      consultationId,
      emailId: patientEmail,
      amount: consultationChargePrice,
      discountRate: String(concessionConsultationDiscountRate),
      discountType: 'CONCESSION',
      paymentMethod: consultationPaymentMethod
    });
    logger.info('doChargeConsult', {
      consultationId,
      amount: consultationChargePrice,
      discountRate: String(concessionConsultationDiscountRate),
      discountType: 'CONCESSION',
      paymentMethod: consultationPaymentMethod,
      ...result.data
    });

    return result;
  };

  return {
    consultationPrice,
    consultationChargePrice,
    formattedConsultationPrice,
    concessionConsultationPrice,
    formattedConcessionConsulationPrice,
    consultDiscountRate,
    handleChangeConsultDiscountRate,
    concessionConsultationDiscountRate,
    discountInputProps,
    loadingChargeConsult: isLoadingConsult,
    loadingChargeConcession: isLoadingConcession,
    doChargeConsult,
    doChargeConcession,
    canUserChargeConsult,
    consultAndClinicianType,
    isComplimentary,
    setIsComplimentary
  };
}

export default useChargeConsult;
