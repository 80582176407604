import { patientNotesClient } from '@/api/patients/patientNotesClient';
import { QueryKey } from '@/data/query';

interface PatientNotesPageParams {
  size: number;
  after?: string;
  before?: string;
}
const queryClient = patientNotesClient();

export const useGetPatientNotes = (pageParams: PatientNotesPageParams, patientId: number | undefined) => {
  const queryResult = queryClient.findPatientNotes.useQuery(
    [QueryKey.GetPatientNotes, pageParams, patientId],
    {
      params: { id: patientId as number },
      query: { page: pageParams }
    },
    {
      queryKey: [QueryKey.GetPatientNotes, pageParams, patientId],
      enabled: Boolean(patientId)
    }
  );
  return {
    data: queryResult.data?.body ?? null,
    isError: queryResult.isError,
    isLoading: queryResult.isLoading,
    refetch: queryResult.refetch
  };
};
