import type { StateCreator } from 'zustand';

import type { FilteredProduct } from '@/api';
import type {
  CancelMedicationItemType,
  MedicationPadItemType
} from '@/components/PatientPageV2/components/PrescriptionPad';
import { initialPrescriptionHistoryState, initialPrescriptionPadState, initialWAHealthState } from '../state';
import type { PrescriptionAssistantSliceType } from '../types';
import { createReissuedMedication } from '../utils';
import {
  determineReissueOrCancelWhenChangingProducts,
  getUpdateMedicationPadItems
} from '../utils/updateMedicationPadItems.utils';

// Create a mutable object to hold only the necessary reissue status properties
const reissueStatusStore: {
  current: { productId?: number; isOutOfStock?: boolean; isDiscontinued?: boolean; isActive?: boolean } | null;
} = { current: null };

export const prescriptionAssistantSlice: StateCreator<
  PrescriptionAssistantSliceType,
  [['zustand/immer', unknown], ['zustand/devtools', unknown]]
> = (set, get) => ({
  // state
  prescriptionAssistant: {
    prescriptionHistory: initialPrescriptionHistoryState,
    prescriptionPad: initialPrescriptionPadState,
    waHealth: initialWAHealthState
  },
  // computed state
  isValidPrescriptionPad: () => {
    const state = get();
    const itemsToCancel = state?.prescriptionAssistant?.prescriptionHistory?.itemsToCancel || [];
    const medicationPadItems = state?.prescriptionAssistant?.prescriptionPad?.medicationPadItems || [];
    const isMedicationPadItemFilterInprogress = medicationPadItems.some((item) => !!item.filters);

    // check if there are items to cancel & no other medications in the pad
    // check if there are any empty medications (without filters)
    if (Boolean(itemsToCancel.length) && !isMedicationPadItemFilterInprogress) {
      return true;
    }

    const hasMedicationsWithErrorMessage = get()?.prescriptionAssistant?.prescriptionPad?.medicationPadItems?.some(
      (item) => !!item?.errorMessage
    );

    const hasOutOfServiceMedication = medicationPadItems.some(({ reissueStatus, medicationPadItem }) => {
      reissueStatusStore.current = reissueStatus
        ? {
            productId: reissueStatus.productId,
            isOutOfStock: reissueStatus.isOutOfStock,
            isDiscontinued: reissueStatus.isDiscontinued,
            isActive: reissueStatus.isActive
          }
        : null;

      return (
        reissueStatusStore.current?.productId === medicationPadItem?.productId &&
        (reissueStatusStore.current?.isOutOfStock ||
          reissueStatusStore.current?.isDiscontinued ||
          !reissueStatusStore.current?.isActive)
      );
    });

    return (
      // check if there are medications in the precription pad
      Boolean(get()?.prescriptionAssistant?.prescriptionPad?.medicationPadItems.length) &&
      // check if all the medications in the prescription pad have selected product
      Boolean(
        get()?.prescriptionAssistant?.prescriptionPad?.medicationPadItems?.every((item) => !!item.medicationPadItem)
      ) &&
      // Check that no medications in the prescription pad have an error message
      Boolean(!hasMedicationsWithErrorMessage) &&
      // Check that no reissued medications are out of service
      Boolean(!hasOutOfServiceMedication)
    );
  },
  // prescription pad actions
  updateMedicationPadItems: (medicationPadItems) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionPad.medicationPadItems = medicationPadItems;
      },
      false,
      'prescriptionPad/updateMedicationPadItems'
    ),
  updateMedicationPadItemById: (
    updatedMedicationPadItem,
    { ffPatPrescriptionDosageMinLengthValidation, ffV4PrescriptionAssistantCancelPrescribedMedication }
  ) => {
    const {
      prescriptionAssistant,
      updatePrescriptionHistoryItemsToReissue,
      moveItemToCancelFromReissue,
      updatePrescriptionHistoryItemsToCancel,
      updateMedicationPadItems
    } = get();
    // Update medication pad items
    const { updatedItems, previousProductId } = getUpdateMedicationPadItems(
      prescriptionAssistant.prescriptionPad.medicationPadItems,
      updatedMedicationPadItem,
      ffPatPrescriptionDosageMinLengthValidation
    );
    set(
      (state) => {
        state.prescriptionAssistant.prescriptionPad.medicationPadItems = updatedItems;
      },
      false,
      'prescriptionPad/updateMedicationPadItemsById'
    );
    // Determine reissue or cancel logic in case products have changed
    const result = determineReissueOrCancelWhenChangingProducts(
      updatedMedicationPadItem,
      prescriptionAssistant.prescriptionHistory.itemsToReissue,
      prescriptionAssistant.prescriptionHistory.itemsToCancel,
      previousProductId,
      ffV4PrescriptionAssistantCancelPrescribedMedication
    );

    // Handle action based on determined result
    switch (result.type) {
      case 'UPDATE_REISSUE':
        updatePrescriptionHistoryItemsToReissue(result.updatedItemsToReissue);
        break;
      case 'MOVE_TO_CANCEL':
        moveItemToCancelFromReissue(result.itemToCancel);
        break;
      case 'MOVE_BACK_TO_REISSUE':
        updatePrescriptionHistoryItemsToCancel(result.updatedCanceledItems);
        updatePrescriptionHistoryItemsToReissue(result.updatedReissuedItems);
        updateMedicationPadItems(
          prescriptionAssistant.prescriptionPad.medicationPadItems.map((item) => {
            if (item.medicationPadItemId === result.updatedMedicationPadItem.medicationPadItemId) {
              return result.updatedMedicationPadItem;
            }
            return item;
          })
        );
        break;
    }
  },
  addReissuedMedicationToPrescriptionPad: (
    availableMedications: FilteredProduct[],
    reissuedMedication: MedicationPadItemType,
    ffPatPrescriptionDosageMinLengthValidation: boolean = false,
    ffV4PrescriptionAssistantCancelPrescribedMedication: boolean = false
  ) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        const existingMedicationPadItems = state.prescriptionAssistant.prescriptionPad.medicationPadItems.filter(
          (item) => !!item?.medicationPadItem
        );
        const newReissuedMedication = createReissuedMedication(
          availableMedications,
          reissuedMedication,
          ffPatPrescriptionDosageMinLengthValidation,
          ffV4PrescriptionAssistantCancelPrescribedMedication
        );

        if (!newReissuedMedication) {
          return;
        }

        const updatedPadMedications = [newReissuedMedication, ...existingMedicationPadItems];
        state.prescriptionAssistant.prescriptionPad.medicationPadItems = updatedPadMedications;
      },
      false,
      'prescriptionPad/addReissuedMedication'
    ),
  updateDevicePadItems: (devicePadItems) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionPad.devicePadItems = devicePadItems;
      },
      false,
      'prescriptionPad/updateDevicePadItems'
    ),
  updatePrescriptionPadReviewAction: (action) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        const updatedActions = state.prescriptionAssistant.prescriptionPad.actions.map((actionItem) => {
          if (actionItem.id === action.id) {
            return action;
          }

          return actionItem;
        });
        state.prescriptionAssistant.prescriptionPad.actions = updatedActions;
      },
      false,
      'prescriptionPad/updatePrescriptionPadReviewAction'
    ),
  updatePrescriptionPadStep: (step) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionPad.step = step;
      },
      false,
      'prescriptionPad/updatePrescriptionPadStep'
    ),
  resetPrescriptionPad: () =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionPad = initialPrescriptionPadState;
      },
      false,
      'prescriptionPad/reset'
    ),
  // prescription history actions
  updatePrescriptionHistoryItemsToReissue: (itemsToReissue) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionHistory.itemsToReissue = itemsToReissue;
      },
      false,
      'prescriptionHistory/updatePrescriptionHistoryItemsToReissue'
    ),
  updatePrescriptionHistoryItemsToCancel: (itemsToCancel) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionHistory.itemsToCancel = itemsToCancel;
      },
      false,
      'prescriptionHistory/updatePrescriptionHistoryItemsToCancel'
    ),
  updatePrescriptionHistoryItemsToAction: (itemsToAction) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionHistory.itemsToAction = itemsToAction;
      },
      false,
      'prescriptionHistory/updatePrescriptionHistoryItemsToAction'
    ),

  resetPrescriptionHistory: () =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionHistory = initialPrescriptionHistoryState;
      },
      false,
      'prescriptionHistory/reset'
    ),

  moveItemToCancelFromReissue: (itemToCancel: CancelMedicationItemType) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        if (!itemToCancel.productId || !itemToCancel.displayName) {
          return;
        }
        // If the item is in the list of items to reissue, then we want to cancel it
        state.prescriptionAssistant.prescriptionHistory.itemsToReissue
          .filter((itemToReissue) => itemToReissue.medicationPadItem?.productId === itemToCancel.productId)
          // This should happen at most 1 time
          .forEach((_) => {
            state.prescriptionAssistant.prescriptionHistory.itemsToCancel = [
              ...state.prescriptionAssistant.prescriptionHistory.itemsToCancel.filter(
                (item) => item.productId !== itemToCancel.productId
              ),
              itemToCancel
            ];
          });

        // If the item is in the list of items to reissue, then we want to remove it from that list
        state.prescriptionAssistant.prescriptionHistory.itemsToReissue =
          state.prescriptionAssistant.prescriptionHistory.itemsToReissue.filter(
            (item) => item.medicationPadItem?.productId !== itemToCancel.productId
          );

        // If the canceled reissued item is listed in one of the prescription pad items, remove it
        state.prescriptionAssistant.prescriptionPad.medicationPadItems =
          get().prescriptionAssistant.prescriptionPad.medicationPadItems.map((item) => {
            if (item.reissueStatus?.productId === itemToCancel?.productId) {
              return {
                ...item,
                reissueStatus: undefined
              };
            }
            return item;
          });
      },
      false,
      'prescriptionHistory/moveItemToCancelFromReissue'
    ),
  setIsWaPatient: (isWa: boolean) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.waHealth.isWaPatient = isWa;
      },
      false,
      'waHealth/setIsWaPatient'
    )
});
