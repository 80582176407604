import { patientConcessionCardClient } from '@/api/patients/patientConcessionCardClient';
import { QueryKey } from '@/data/query';

const queryClient = patientConcessionCardClient();

export const useGetPatientConcessionCard = (patientId: number | undefined) => {
  const queryResult = queryClient.getPatientConcessionCard.useQuery(
    [QueryKey.GetPatientConcessionCard, patientId],
    {
      params: { id: patientId as number }
    },
    {
      queryKey: [QueryKey.GetPatientConcessionCard, patientId],
      enabled: Boolean(patientId)
    }
  );
  return {
    data: queryResult.data?.body?.data?.attributes ?? null,
    isError: queryResult.isError,
    isLoading: queryResult.isLoading
  };
};
