import { NoticeList } from '@/components/Rescripting/Rescripting.styled';
import { Box, Typography } from '@mui/material';

const DiscontinuedContent = () => {
  return (
    <Box pt={4} pb={8} paddingX={2}>
      <Typography variant="body1">
        Please review the following prescriptions, which contain a discontinued medication. In most cases, a
        substitution suggestion has been provided, based on comparable pharmacological features. <br />
        <br /> You are able to change the suggested replacement. Approving the replacement prescription will trigger the
        following actions:
      </Typography>
      <Typography component="div">
        <NoticeList>
          <li>The original prescription, inclusive of all medications, will be cancelled.</li>
          <li>
            A new prescription will be generated, the replacement medication superceding the discontinued medicine, with
            repeats adjusted to account for supplies already used.
          </li>
          <li>
            Any non-out of stock medications from the original prescription will be brought forward onto the replacement
            prescription, with repeats adjusted to account for supplies already used and ensuring all medication
            dispense intervals remain aligned.
          </li>
        </NoticeList>
      </Typography>
    </Box>
  );
};

export { DiscontinuedContent };
