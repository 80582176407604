import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import type {
  FilteredProduct,
  FilteredProductByFormulation,
  FilteredProductsPayloadType,
  ProductFilteredResponse
} from '@/api';
import { fetchFilteredProductsByDoctor } from '@/api';
// import { getFilteredProducts } from '@/components/PatientPageV2/components/CreatePrescriptionScript';
import { QueryKey } from '@/data/query';

/**
 * Flattened filtered products into a single
 * @param formulationProducts
 * @param savedFilteredProducts
 */
export const getFilteredProducts = (
  formulationProducts: FilteredProductByFormulation,
  savedFilteredProducts: FilteredProduct[] = []
): FilteredProduct[] => {
  Object.values(formulationProducts).map((parentNode) => {
    if (!Array.isArray(parentNode)) {
      getFilteredProducts(parentNode, savedFilteredProducts);
      return;
    }

    savedFilteredProducts.push(...parentNode);
    return;
  });

  return savedFilteredProducts;
};

export const useGetFilteredProducts = (payload: FilteredProductsPayloadType) => {
  const { data, ...queryResult } = useQuery({
    queryKey: [QueryKey.FilteredProducts, payload],
    queryFn: () => fetchFilteredProductsByDoctor(payload),
    select: (response: ProductFilteredResponse) => response.data
  });

  const allProducts = useMemo(() => {
    if (!data) {
      return [];
    }
    return getFilteredProducts(data.products);
  }, [data]);

  return {
    data,
    allProducts,
    ...queryResult
  };
};
