import { FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import type { ReissueStatusType } from '../../PrescriptionPad';
import { PrescriptionItemAlertType } from '../PrescriptionItemEditor.types';

type AlertTypeProps = {
  reissueStatus?: Partial<ReissueStatusType>;
  errorMessage: { title: string; description: string } | null;
  hasActiveCancelAlert: boolean;
};

export const useAlertType = ({
  reissueStatus,
  errorMessage,
  hasActiveCancelAlert
}: AlertTypeProps): PrescriptionItemAlertType | null => {
  const { flags } = useFeatureFlags();
  const ffV4PrescriptionAssistantCancelPrescribedMedication =
    flags[FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION];

  const availabilityAlertType: PrescriptionItemAlertType | null = !reissueStatus
    ? null
    : (ffV4PrescriptionAssistantCancelPrescribedMedication
        ? ([
            [reissueStatus.isActive === false, PrescriptionItemAlertType.INACTIVE],
            [reissueStatus.isDiscontinued, PrescriptionItemAlertType.DISCONTINUED],
            [reissueStatus.isOutOfStock, PrescriptionItemAlertType.OUT_OF_STOCK]
          ] as [boolean | undefined, PrescriptionItemAlertType][])
        : ([
            [reissueStatus.isOutOfStock, PrescriptionItemAlertType.OUT_OF_STOCK],
            [reissueStatus.isDiscontinued, PrescriptionItemAlertType.DISCONTINUED],
            [reissueStatus.isActive === false, PrescriptionItemAlertType.INACTIVE]
          ] as [boolean | undefined, PrescriptionItemAlertType][])
      ).find(([condition]) => Boolean(condition))?.[1] ?? null;

  if (errorMessage) {
    return PrescriptionItemAlertType.ERROR;
  }

  if (hasActiveCancelAlert) {
    return PrescriptionItemAlertType.CANCEL;
  }

  if (availabilityAlertType) {
    return availabilityAlertType;
  }

  if (reissueStatus?.isReissued) {
    return PrescriptionItemAlertType.REISSUE;
  }

  return null;
};
