// keys to indentify react queries
export enum QueryKey {
  Auth = 'auth',
  FilteredProducts = 'filteredProducts',
  PrescribedProducts = 'prescribedProducts',
  CancelPrescribedProducts = 'cancelPrescribedProducts',
  FilteredProductsByDevice = 'filteredProductsByDevice',
  ProductSurvey = 'productSurvey',
  CreatePrescription = 'createPrescription',
  Consultations = 'consultations',
  ConsultationBookingIssues = 'ConsultationBookingIssues',
  ConsultationNotes = 'consultationNotes',
  EwayTransactions = 'ewayTransactions',
  NurseConsultations = 'nurseConsultations',
  DoctorConsultations = 'doctorConsultations',
  GetDoctorPatientAndConsultCount = 'getDoctorPatientAndConsultCount',
  GetPrescriptions = 'getPrescriptions',
  GetConsultationStatus = 'getConsultationStatus',
  GetGenerativeScriptingPrescriptions = 'getPrescriptionsWithGenerativeScripting',
  GetOOSPrescriptions = 'getPrescriptionsWithOOSProducts',
  GetRescriptAlternatives = 'getRescriptAlternatives',
  PutPrescriptionBatchReplace = 'putPrescriptionBatchReplace',
  GetNurses = 'getNurses',
  GetDoctors = 'getDoctors',
  GetNurseFlexCalendar = 'getNurseFlexCalendar',
  ResetNurseFlexCalendar = 'resetNurseFlexCalendar',
  GetPatient = 'getPatient',
  GetPatientDetails = 'getPatientDetails',
  GetPatientAssessments = 'getPatientAssessments',
  GetPatientConsultations = 'getPatientConsultations',
  GetPatientNotes = 'getPatientNotes',
  RoundRobinDoctorsInitial = 'getRoundRobinDoctorsInitial',
  RoundRobinDoctorsOverflow = 'getRoundRobinDoctorsOverflow',
  PrescribePrescription = 'prescribePrescription',
  GetMedicalConditions = 'getMedicalConditions',
  GetIhiReviewLogs = 'getIhiReviewLogs',
  ResendConsentForm = 'resentConsentForm',
  PostPreviewNotificationForm = 'postPreviewNotificationForm',
  GetPharmacies = 'getPharmacies',
  GetAustralianStates = 'getAustralianStates',
  GetPatientDocuments = 'getPatientDocuments',
  GetConsultationTelecomDetails = 'getConsultationTelecomDetails',
  GetPatientConcessionCard = 'getPatientConcessionCard'
}
