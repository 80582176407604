import { useCallback, useEffect, useState } from 'react';

import { UserRole } from '@/types';
import { Logger } from '@/utils/logger';

export interface FeatureTutorialState {
  shouldShowTutorialForFeature: boolean | null;
  setShouldShowTutorialForFeature: (shouldShowTutorial: boolean) => void;
}

export interface UseFeatureTutorialStateProps {
  userId?: number;
  roleId?: number;
  featureFlag?: string;
}

const logger = new Logger('useFeatureTutorialState.ts');

const generateHasWatchedFeatureTutorialKey = (userId: number, featureFlag: string) =>
  `hasWatchedFeatureTutorial:${featureFlag}:UserId:${userId}`;

/**
 * Currently there is no caller which provides the
 * `featureFlag` argument; it will always return early.
 * @param root0
 * @param root0.userId
 * @param root0.roleId
 * @param root0.featureFlag
 */
export const useFeatureTutorialState = ({
  userId,
  roleId,
  featureFlag
}: UseFeatureTutorialStateProps): FeatureTutorialState => {
  const [hasWatchedTutorial, setHasWatchedTutorial] = useState<boolean | null>(null);

  useEffect(() => {
    if (!userId || roleId !== UserRole.Doctor || !featureFlag) {
      return;
    }

    const hasWatchedFeatureTutorialKey = generateHasWatchedFeatureTutorialKey(userId, featureFlag);
    const hasWatched = localStorage.getItem(hasWatchedFeatureTutorialKey) === 'true';
    logger.info('Check if user has watched tutorial', {
      userId,
      roleId,
      featureFlag,
      hasWatched
    });
    setHasWatchedTutorial(hasWatched);
  }, [userId, roleId, featureFlag]);

  const setShouldShowTutorialForFeature = useCallback(
    (shouldShowTutorial: boolean) => {
      if (!userId) {
        logger.error('Setting tutorial state for user invoked without userId');
        return;
      }

      if (!featureFlag) {
        logger.error('Setting tutorial state for user invoked without featureFlag key');
        return;
      }

      const hasWatchedFeatureTutorialKey = generateHasWatchedFeatureTutorialKey(userId, featureFlag);
      logger.info('Setting tutorial state for user', {
        userId,
        featureFlag,
        shouldShowTutorial
      });

      if (!shouldShowTutorial) {
        localStorage.setItem(hasWatchedFeatureTutorialKey, 'true');
      } else {
        localStorage.removeItem(hasWatchedFeatureTutorialKey);
      }
    },
    [userId, featureFlag]
  );

  return {
    shouldShowTutorialForFeature: hasWatchedTutorial === null ? null : !hasWatchedTutorial,
    setShouldShowTutorialForFeature
  };
};
