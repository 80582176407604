import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';

import styles from './styles';

const useStyles = makeStyles(styles);
/**
 * @deprecated use react-hook-form-mui instead
 */
const InputField = (props) => {
  const classes = useStyles();
  const value = props.value ? props.value : '';
  let show = props.setRadioButton ? (props.checked ? true : false) : true;
  return (
    <Box
      component={Grid}
      width="100%"
      display={props.display ? props.display : 'flex'}
      m={props.margin !== undefined ? props.margin : 1}
      p={isNaN(props.padding) ? 0 : props.padding}
    >
      {(props.isSwitch || props.label !== '') && (
        <Grid xs={props.customGrid ? props.customGrid[0] : 4} item={true}>
          <Box
            component="div"
            textAlign={props.textAlign ? props.textAlign : 'left'}
            p={props.labelPadding ? props.labelPadding : '10px'}
            className={`${props.disabled && classes.disabledLabel} ${props.labelClasses ? props.labelClasses : ''}`}
            fontWeight={props.labelFontWeight ? props.labelFontWeight : 600}
            style={{ display: 'flex' }}
          >
            <label className={`${props.isSwitch ? classes.verticalCenterAlign : ''}`} htmlFor={props.id}>
              {props.label}
            </label>
            {props.isSwitch && (
              <RadioGroup
                row
                aria-label="home delivery"
                name="homeDelivery"
                value={props.checked}
                style={{ marginLeft: 'auto' }}
                disabled={props.disabled}
                onChange={async (e) => {
                  props.setRadioButton(!props.checked);
                }}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio color="primary" size="small" />}
                  checked={props.checked}
                  disabled={props.disabled}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio color="primary" size="small" />}
                  checked={!props.checked}
                  disabled={props.disabled}
                  label="No"
                />
              </RadioGroup>
            )}
          </Box>
        </Grid>
      )}
      {!props.onlyRadioButton && (
        <Grid xs={props.customGrid ? props.customGrid[1] : 8} item={true}>
          <FormControl className={classes.TextField}>
            <Box p={props.inputPadding !== undefined ? props.inputPadding : 0}>
              {show && (
                <TextField
                  classes={{ root: `${classes.input} ${props.InputClasses ? props.InputClasses : ''}` }}
                  variant="outlined"
                  id={props.id}
                  onChange={props.onChange}
                  type={props.type}
                  value={value}
                  multiline={props.multiline}
                  rows={props.rows}
                  placeholder={props.placeholder}
                  error={props.error}
                  helperText={props.helperText}
                  margin="none"
                  disabled={props.disabled}
                  style={props.style}
                  autoComplete="off"
                  inputProps={{
                    readOnly: props.IsReadOnly ? props.IsReadOnly : false,
                    max: props.max,
                    min: props.min,
                    'data-testid': props.id
                  }}
                />
              )}
            </Box>
          </FormControl>
        </Grid>
      )}
    </Box>
  );
};

export default InputField;
